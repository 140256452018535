import * as types from "./Types";
import { productApi } from "@services";

export const getProducts = (payload) => {
  return {
    type: types.GET_PRODUCTS,
    payload: productApi.getProduct(payload),
  };
};

export const addToCartProduct = (data) => {
  return {
    type: types.ADD_CART_PRODUCT,
    payload: productApi.addToCartProduct(data),
  };
};

export const getCartList = () => {
  return {
    type: types.GET_CART_LIST,
    payload: productApi.getCartList(),
  };
};

export const removeCartProduct = (id) => {
  return {
    type: types.REMOVE_CART_PRODUCT,
    payload: productApi.removeCartProduct(id),
  };
};

export const clearAllCartProduct = () => {
  return {
    type: types.CLEAR_ALL_CART_PRODUCT,
    payload: productApi.clearAllCartProduct(),
  };
};

export const getProductDetail = (product_id, data) => {
  return {
    type: types.GET_PRODUCT_DETAIL,
    payload: productApi.getProductDetail(product_id, data),
  };
};

export const getProductFilter = () => {
  return {
    type: types.GET_PRODUCT_FILTER,
    payload: productApi.getProductFilter(),
  };
};

export const getWishList = () => {
  return {
    type: types.GET_WISH_LIST,
    payload: productApi.getWishList(),
  };
};

export const addAndRemoveWishList = (product_id) => {
  return {
    type: types.ADD_WISH_LIST,
    payload: productApi.addAndRemoveWishList(product_id),
  };
};

export const addWishListToCart = (data) => {
  return {
    type: types.ADD_WISHLIST_TO_CART,
    payload: productApi.addWishListToCart(data),
  };
};

export const getAllOrderDetails = (payload) => {
  return {
    type: types.GET_ALL_ORDER_DETAILS,
    payload: productApi.getAllOrderDetails(payload ? payload : ""),
  };
};

export const addOrderDetail = (data) => {
  return {
    type: types.ADD_ORDER_DETAILS,
    payload: productApi.addOrderDetail(data),
  };
};

export const getOrderDetail = (id) => {
  return {
    type: types.GET_ORDER_DETAIL,
    payload: productApi.getOrderDetail(id),
  };
};

export const getOffersVouchers = () => {
  return {
    type: types.GET_OFFER_VOUCHERS,
    payload: productApi.getOffersVouchers(),
  };
};

export const getAvailableOffers = (offer_id) => {
  return {
    type: types.GET_AVAILABLE_OFFERS,
    payload: productApi.getAvailableOffer(offer_id)
  };
};

export const getVouchers = () => {
  return {
    type: types.GET_VOUCHERS,
    payload: productApi.getVouchers(),
  };
};

export const getOffer = (id) => {
  return {
    type: types.GET_OFFER,
    payload: productApi.getOffer(id),
  };
};

export const getProductRating = (id) => {
  return {
    type: types.GET_REVIEW_RATING,
    payload: productApi.getProductRating(id),
  };
};

export const addReviewRating = (data) => {
  return {
    type: types.ADD_REVIEW_RATING,
    payload: productApi.addReviewRating(data),
  };
};

export const notification = (product_id) => {
  return {
    type: types.NOTIFICATION,
    payload: productApi.notification(product_id),
  };
};

export const getNotifyProduct = () => {
  return {
    type: types.GET_NOTIFY_PRODUCT,
    payload: productApi.getNotifyProduct(),
  }
}

export const updateOrders = (id, data) => {
  return {
    type: types.UPDATE_ORDERS,
    payload: productApi.updateOrder(id, data),
  }
}

export const checkCartAvailProducts = () => {
  return {
    type: types.CHECK_CART_AVAIL_STOCK,
    payload: productApi.checkCartAvailProduct()
  }
}

export const productsDeliveryFee = () => {
  return {
    type: types.PRODUCT_DELIVERY_FEE,
    payload: productApi.getDeliveryFee()
  }
}

export const clearVoucher = () => {
  return{
    type: types.CLEAR_VOUCHERS,
  }
}

export const setVoucher = (voucher) => {
  return{
    type: types.SET_VOUCHER,
    payload: voucher
  }
}