import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import { Basic } from "@components/Text";
import { animated } from "@react-spring/web";
import ProfileRoutes from "./ProfileRoutes";
import ImageUpload from "./Widgets/ImageUpload";
import logOut from "@assets/images/logoutIcon.png";
import { useNavigate } from "react-router-dom";
import { remove } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress, userProfile, getInvoiceDetail, getOffersVouchers, getVouchers, getNotifyProduct, getAllOrderDetails, getFaq, logout } from "@store";

const Profile = (props) => {
  const { openProfile, profileclassname, closeModal } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token)
  const voucherAmount = useSelector((state) => state.products.voucherAmount)
  const userData = useSelector((state) => state.user.userData)

  useEffect(() => {
    if (token) {
      Promise.all([
        dispatch(userProfile()),
        dispatch(getUserAddress()),
        dispatch(getInvoiceDetail()),
        dispatch(getOffersVouchers()),
        dispatch(getVouchers()),
        dispatch(getNotifyProduct()),
        dispatch(getAllOrderDetails()),
        dispatch(getFaq(userData.id))
      ])
    }
  }, []);

  const handleLogOut = () => {
    dispatch(logout()).then((res) => {
      remove("businessToken");
      window.location.reload();
      navigate('/')
    })
  };

  const username = userData?.first_name && userData?.last_name ? `${userData?.first_name}  ${userData?.last_name}` : userData?.first_name || userData?.last_name;

  const hidden = !openProfile ? "hidden" : "flex";

  return (
    <animated.div
      onClick={closeModal}
      className={`${hidden} ${profileclassname} fixed top-0 left-0 right-0 bottom-0`}
    >
      <ProfileContainer >
        <div className="flex-1 relative top-0 h-full overflow-y-auto scrollbar-none">
          <UserValue onClick={(e) => e.stopPropagation()}>
            <ImageUpload />
            <Basic className={"pl-5 capitalize"} style={{ fontWeight: 700 }}>
              {username}
            </Basic>
          </UserValue>
          <Vouchers onClick={(e) => e.stopPropagation()}>
            <Basic style={{ fontWeight: 600 }}>{"Voucher Balance"}</Basic>
            <Basic style={{ fontWeight: 600 }}>
              {"Credit "}
              {voucherAmount}
              {" CHF"}
            </Basic>
          </Vouchers>
          {ProfileRoutes?.map((value, idx) => {
            return (
              <div key={idx} className="bg-white">
                <div onClick={(e) => e.stopPropagation()} className=" py-3">
                  <Basic style={{ fontWeight: 700, padding: "0 20px" }}>
                    {value.title}
                  </Basic>
                </div>
                <OptionContent>
                  {value?.options?.map((option, idx) => {
                    return (
                      <OptionList
                        key={idx}
                        onClick={() => {
                          navigate(`${option.to}`, { state: userData });
                        }}
                      >
                        <img src={option.icon} alt="" className="w-5 h-5" />
                        <Basic className={"pl-3"} onClick={(e) => e.stopPropagation()}>{option.text}</Basic>
                      </OptionList>
                    );
                  })}
                </OptionContent>
              </div>
            );
          })}
          <LogOutButton onClick={handleLogOut}>
            <img src={logOut} alt="" className="w-6 h-6" />
            <Basic className={"pl-3"}>{"Logout"}</Basic>
          </LogOutButton>
        </div>
      </ProfileContainer>
    </animated.div>
  );
};

const ProfileContainer = tw.div`
flex-col
bg-white
shadow-cartBtn
w-[280px]
xl:fixed
xl:top-28
xl:h-auto
right-0
bottom-0
peer-transition
ease-out
delay-150
duration-200
`;

const UserValue = tw.div`
flex
items-center
border-b-[0.6px]
border-color206
bg-white
p-5
`;

const Vouchers = tw.div`
flex
items-center
justify-between
border-b-[5px]
border-color239
bg-white
p-5
`;

const OptionContent = tw.div`
border-y-[5px]
border-color239
`;

const OptionList = tw.button`
border-b-[0.6px]
border-color206
flex
items-center
px-5 py-3
last:border-b-0
w-full
`;

const LogOutButton = tw.button`
flex
flex-row
items-center
p-5
w-full
`;

export default Profile;
