import { useRef, useEffect } from "react";

const GoogleMapAutoComplete = ({ placeholder, handleAddress, value, onChangeHandler, name, className }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    // componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    // types: ["establishment"]
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      handleAddress(place);
    });
  }, []);

  return (
    <div className="py-2">
      <input ref={inputRef}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChangeHandler}
        className={`${className} mt-[0.3pc] w-full h-9 px-2 text-[12px] flex focus:outline-none opacity-90 border drop-shadow-md`}
      />
    </div>
  );
};
export default GoogleMapAutoComplete;