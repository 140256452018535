import React, { useState } from 'react';
import tw from 'tailwind-styled-components';
import theme from '@theme';
import { Input, Button, CheckBox, CustomCalendar } from '@components';
import { userApi, getTokens } from "@services";
import { toast } from "react-toastify";
import {
  isValidEmail,
  isValidNumber,
  isValidPostcode,
  isEmpty,
  isValidName,
  isValidateSpecialChar,
  isValidUrl,
  isValidUserId,
  isValidNo
} from "@utils"
import { en } from "@i18n"
import switzerlandLogo from "@assets/images/flag-switzerland.png";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import checkIcon from "@assets/images/openedGray.png";
import greenCheckIcon from "@assets/images/opened.png";
import { OtpModal } from '../../../containers/Auth/widgets';
import { useTranslation } from 'react-i18next';
import {
  userProfile,
  getCartList,
  getWishList,
  getProducts,
  getNotifyProduct,
  setToken,
  verifyOtp,
  uploadDeviceToken
} from "@store"
import { useDispatch } from 'react-redux';
import moment from 'moment';

const ToggleModalHeader = () => {
  const [state, setState] = useState({
    toggleButton: false,
    email: '',
    phone_num: '',
    code: '',
    password: '',
    referralCode: '',
    newsletterChecked: false,
    fname: '',
    lname: '',
    registrationEmail: '',
    registrationPhoneNum: '',
    registrationDob: '',
    registrationPassword: '',
    registrationConfirmPassword: '',
    company: "",
    url: "",
    uid: "",
    street: "",
    number: "",
    postCode: "",
    city: "",
    country: "",
    signInWithMobile: false,
    successOtp: false,
    showPassword: false,
    showConfirmPassword: false,
    isLoading: false,
    verifyEmail: false,
    verifyMobileNum: false,
    isMobileVerificationModal: false,
    isEmailVerificationModal: false,
    isMobileModalValue: "",
    isEmailModalValue: "",
  })
  const {
    toggleButton,
    email,
    phone_num,
    code,
    password,
    referralCode,
    newsletterChecked,
    fname,
    lname,
    registrationEmail,
    registrationPhoneNum,
    registrationDob,
    registrationPassword,
    registrationConfirmPassword,
    company,
    url,
    uid,
    street,
    number,
    postCode,
    city,
    country,
    signInWithMobile,
    successOtp,
    showPassword,
    showConfirmPassword,
    isLoading,
    verifyEmail,
    verifyMobileNum,
    isMobileVerificationModal,
    isEmailVerificationModal,
    isMobileModalValue,
    isEmailModalValue } = state;

  const onChangeHandler = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleChange = (value) => {
    setState((prev) => ({ ...prev, registrationDob: moment(new Date(value)).format("YYYY-MM-DD") }));
  };

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const dispatchItems = () => {
    Promise.all([
      dispatch(userProfile()),
      dispatch(getCartList()),
      dispatch(getWishList()),
      dispatch(getProducts()),
      dispatch(getNotifyProduct())
    ])
  }

  const getPushNotification = () => {
    getTokens((isTokenFound, token) => {
      if (isTokenFound) {
        let data = {
          type: 'web',
          token: token,
          status: 'active'
        };
        dispatch(uploadDeviceToken(data))
      }
    })
  }

  const onChangeHandlerLogin = (event) => {
    isNaN(event.target.value) || event?.target?.value?.length === 0
      ? setState((prev) => ({
        ...prev,
        signInWithMobile: false,
        email: event?.target?.value,
      }))
      : setState((prev) => ({
        ...prev,
        signInWithMobile: true,
        phone_num: event?.target?.value,
      }));
  };


  const toggleHandler = () => {
    setState((prev) => ({ ...prev, toggleButton: false }))
  }

  const toggleInput = () => {
    setState((prev) => ({ ...prev, toggleButton: true }))
  }

  const getOtpValue = (data) => {
    userApi.getOtp(data).then((res) => {
      if (res && res?.data && res?.status === 200) {
        toast.success(res?.data?.message);
        setState((prev) => ({ ...prev, successOtp: true }));
      }
    });
  };

  const handleClickShowPassword = () => {
    setState((prev) => ({ ...prev, showPassword: !showPassword }));
  };

  const handleClickShowConfirmPassword = () => {
    setState((prev) => ({ ...prev, showConfirmPassword: !showConfirmPassword }))
  }

  // Login
  const loginWithEmail = () => {
    if (isEmpty(email) || isEmpty(password)) {
      toast.error(en.errors.empty_credential);
    } else if (email && !isValidEmail(email?.trim())) {
      toast.error(en.errors.emailValidate);
    } else if (password?.trim().length < 8) {
      toast.error(en.errors.minimumPasswordValue);
    } else {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = {
        email: email,
        password: password,
        role: "business_user",
      };
      userApi.login(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          dispatchItems()
          getPushNotification();
          dispatch(setToken(res?.data?.data?.accessToken))
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.success(en.success.success);
        } else {
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.error(res?.response?.data?.message);
        }
      });
    }
  };

  const loginWithPhoneNumber = () => {
    if (isEmpty(phone_num) || isEmpty(code)) {
      toast.error(en.errors.empty_credential);
    } else {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = {
        phone: phone_num,
        otp: code,
        role: "business_user",
      };
      userApi.authLogin(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          dispatchItems()
          getPushNotification();
          dispatch(setToken(res?.data?.data?.accessToken))
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.success(en.success.success);
        } else {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isLoading: false }));
        }
      });
    }
  };

  const sendOtpOnMobileNumber = () => {
    if (phone_num && !isValidNumber(phone_num?.trim())) {
      toast.error(en.errors.validPhoneNum);
    } else if (phone_num?.trim().length < 10) {
      toast.error(en.errors.minimum_phoneNum);
    } else if (code && !isValidPostcode(code?.trim())) {
      toast.error(en.errors.validCode);
    } else {
      const data = {
        phone: phone_num,
        role: "business_user",
      };
      getOtpValue(data);
    }
  };

  // Registration
  const getMobileNumberOtpModalValue = (value) => {
    setState({ ...state, isMobileModalValue: value });
  };

  const getEmailOtpModalValue = (value) => {
    setState({ ...state, isEmailModalValue: value });
  };

  const verifyEmailOtp = () => {
    let data = {
      email: registrationEmail,
      role: "business_user",
      otp: isEmailModalValue
    }
    dispatch(verifyOtp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setState((prev) => ({
          ...prev,
          isEmailVerificationModal: false,
          verifyEmail: true,
          isEmailModalValue: null,
        }));
      } else {
        toast.error(en.errors.minimumOtpValue);
      }
    })
  }

  const verifyMobileOtp = () => {
    let data = {
      phone: registrationPhoneNum,
      role: "business_user",
      otp: isMobileModalValue
    }
    dispatch(verifyOtp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setState((prev) => ({
          ...prev,
          isMobileVerificationModal: false,
          verifyMobileNum: true,
          isMobileModalValue: null,
        }));
      } else {
        toast.error(en.errors.minimumOtpValue);
      }
    })
  }

  const onclickEmailOtpModal = () => {
    if (isEmailModalValue?.trim().length === 0) {
      toast.error(en.errors.otpValidate);
    } else if (isEmailModalValue?.trim().length < 4) {
      toast.error(en.errors.minimumOtpValue);
    } else {
      verifyEmailOtp();
    }
  };

  const onclickMobileOtpModal = () => {
    if (isMobileModalValue?.trim().length === 0) {
      toast.error(en.errors.otpValidate);
    } else if (isMobileModalValue?.trim().length < 4) {
      toast.error(en.errors.minimumOtpValue);
    } else {
      verifyMobileOtp();
    }
  };

  const toggleEmailVerificationModal = () => {
    if (registrationEmail && !isValidEmail(registrationEmail?.trim())) {
      toast.error(en.errors.emailValidate);
    } else {
      let item = {
        email: registrationEmail,
        role: "business_user",
      };
      userApi.verifyUser(item).then((res) => {
        if (res?.status === 200) {
          let data = {
            email: registrationEmail,
            role: "business_user",
          };
          getOtpValue(data);
          setState((prev) => ({
            ...prev,
            isEmailVerificationModal: true,
          }));
        } else if (res?.response?.status !== 200) {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isEmailVerificationModal: false }));
        }
      });
    }
  };

  const toggleMobileVerificationModal = () => {
    if (registrationPhoneNum && !isValidNumber(registrationPhoneNum?.trim())) {
      toast.error(en.errors.phoneNumValidate);
    } else {
      let item = {
        phone: registrationPhoneNum,
        role: "business_user",
      };
      userApi.verifyUser(item).then((res) => {
        if (res?.status === 200) {
          let data = {
            phone: registrationPhoneNum,
            role: "business_user",
          };
          getOtpValue(data);
          setState((prev) => ({
            ...prev,
            isMobileVerificationModal: true,
          }));
        } else if (res?.response?.status !== 200) {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isEmailVerificationModal: false }));
        }
      });
    }
  };

  const onChangeEmail = (event) => {
    setState((prev) => ({ ...prev, registrationEmail: event.target.value }));
    if (registrationEmail) {
      setState((prev) => ({ ...prev, verifyEmail: false }));
    }
  };

  const onChangeMobileNum = (event) => {
    setState((prev) => ({ ...prev, registrationPhoneNum: event.target.value }));
    if (registrationPhoneNum) {
      setState((prev) => ({ ...prev, verifyMobileNum: false }));
    }
  };

  let checkvalue =
    (fname?.trim().length === 0 && en.errors.empty_credential) ||
    (lname?.trim().length === 0 && en.errors.lastName) ||
    (registrationPhoneNum?.trim().length === 0 && en.errors.empty_mobileNum) ||
    (registrationEmail?.trim().length === 0 && en.errors.email) ||
    (registrationPassword?.trim().length === 0 && en.errors.password) ||
    (registrationConfirmPassword?.trim().length === 0 && en.errors.confirmPassword) ||
    (registrationDob?.trim().length === 0 && en.errors.dob) ||
    (company?.trim().length === 0 && en.errors.company) ||
    (uid?.trim().length === 0 && en.errors.uid) ||
    (street?.trim().length === 0 && en.errors.street) ||
    (number?.trim().length === 0 && en.errors.number) ||
    (postCode?.trim().length === 0 && en.errors.postCode) ||
    (city?.trim().length === 0 && en.errors.city) ||
    (country?.trim().length === 0 && en.errors.country);

  let checkAllInputs =
    fname?.trim().length === 0 ||
    lname?.trim().length === 0 ||
    registrationPassword?.trim().length === 0 ||
    registrationConfirmPassword?.trim().length === 0;

  const createAccount = () => {
    if (checkvalue) {
      toast.error(checkvalue);
    } else if (!verifyEmail) {
      toast.error(en.errors.verifyEmail);
    } else if (!verifyMobileNum) {
      toast.error(en.errors.verifyMobileNum);
    } else if (
      (fname && !isValidName(fname?.trim())) ||
      (lname && !isValidName(lname?.trim()))
    ) {
      toast.error(en.errors.nameValidate);
    } else if (registrationPassword?.trim().length < 8) {
      toast.error(en.errors.minimumPasswordValue);
    } else if (registrationPassword !== registrationConfirmPassword) {
      toast.error(en.errors.confirmPasswordValidate);
    } else if (company && isValidateSpecialChar(company?.trim())) {
      toast.error(en.errors.validCompanyName);
    } else if (url && !isValidUrl(url?.trim())) {
      toast.error(en.errors.validUrl);
    } else if (uid && !isValidUserId(uid?.trim())) {
      toast.error(en.errors.validUid);
    } else if (street && isValidateSpecialChar(street?.trim())) {
      toast.error(en.errors.validStreet);
    } else if (number && !isValidNo(number?.trim())) {
      toast.error(en.errors.validNumber);
    } else if (postCode && !isValidPostcode(postCode?.trim())) {
      toast.error(en.errors.validPostCode);
    } else if (city && isValidateSpecialChar(city?.trim())) {
      toast.error(en.errors.validCity);
    } else if (country && isValidateSpecialChar(country?.trim())) {
      toast.error(en.errors.validCountry);
    } else {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = {
        role: "business_user",
        email: registrationEmail,
        phone: registrationPhoneNum,
        password: registrationPassword,
        first_name: fname,
        last_name: lname,
        dob: registrationDob,
        referral_code: referralCode,
        name: fname + lname,
        company: company,
        web_url: url,
        vat_number: uid,
        format_add: street,
        building_num: number,
        zip_code: postCode,
        city: city,
        country: country
      };
      userApi.signUp(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          const data = {
            email: registrationEmail,
            password: registrationPassword,
            role: "business_user",
          };
          userApi.login(data).then((res) => {
            if (res && res?.data && res?.status === 200) {
              dispatchItems();
              getPushNotification();
              dispatch(setToken(res?.data?.data?.accessToken))
              setState((prev) => ({ ...prev, isLoading: false }));
              toast.success(en.success.success);
            }
          });
        } else {
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.error(res?.response?.data?.message);
        }
      });
    }
  };

  const handleChecked = (e) => {
    setState((prev) => ({ ...prev, newsletterChecked: e.target.value }))
  }

  let isValidateEmail = email === "" ? true : isValidEmail(email);
  let isValidateMobileNumber =
    phone_num === "" ? true : isValidNumber(phone_num);

  return (
    <div className="flex flex-row w-full mx-auto px-10 ">
      <div className='w-full'>
        <ToggleButton
          active={"/"}
          onClick={toggleHandler}
          className="py-2 font-raleway text-sm md:text-lg"
          style={{
            width: "50%",
            fontWeight: !toggleButton ? '600' : '300',
            borderBottomWidth: !toggleButton ? 2 : 1,
            borderBottomColor: !toggleButton ? theme.colors.black : theme.colors.gray[0],
          }}
        >
          {t('validationModal.signIn')}
        </ToggleButton>
        <ToggleButton
          onClick={toggleInput}
          className="py-2 font-raleway text-sm md:text-lg"
          style={{
            width: "50%",
            fontWeight: toggleButton ? '600' : '300',
            borderBottomWidth: toggleButton ? 2 : 1,
            borderBottomColor: toggleButton ? theme.colors.black : theme.colors.gray[0],
          }}
        >
          {t('validationModal.registration')}
        </ToggleButton>
        <div className='my-3'>
          {!toggleButton ?
            <div>
              <Input
                name={signInWithMobile ? "phone_num" : "email"}
                value={signInWithMobile ? phone_num : email}
                type={'text'}
                title={t('validationModal.emailOrMobileNumber')}
                onChange={onChangeHandlerLogin}
                placeholder={signInWithMobile ? "+41 79 774 53 76" : "kunde@mapl.ch"}
                required
                verifybuttontitle={
                  signInWithMobile
                    ? phone_num && successOtp
                      ? t('validationModal.sent')
                      : phone_num
                        ? t('validationModal.sendOtp')
                        : null
                    : null
                }
                verifybuttonclassname={`${phone_num && successOtp
                  ? "text-black"
                  : phone_num
                    ? "text-blue underline"
                    : null
                  }`}
                verifybuttonclick={phone_num && sendOtpOnMobileNumber}
                verifybuttondisabled={phone_num.length < 13 ? true : false}
                inputicon={signInWithMobile && switzerlandLogo}
                maxLength={signInWithMobile ? 13 : ""}
                errortext={
                  signInWithMobile && !isValidateMobileNumber ? "Please enter valid mobile number" : null || !signInWithMobile && !isValidateEmail ? "Please enter valid email" : null}
              />
              <Input
                name={signInWithMobile ? "code" : "password"}
                value={signInWithMobile ? code : password}
                type={
                  signInWithMobile ? null : showPassword ? "text" : "password"
                }
                title={signInWithMobile ? t('validationModal.enterCode') : t('validationModal.password')}
                placeholder={signInWithMobile ? "Code" : t('validationModal.password')}
                onChange={onChangeHandler}
                maxLength={signInWithMobile ? 4 : ""}
                hideandshoweyeicon={
                  signInWithMobile ? null : showPassword ? (
                    <EyeIcon className="w-3 h-3" />
                  ) : (
                    <EyeOffIcon className="w-3 h-3" />
                  )
                }
                hideandshowpasswordonclick={handleClickShowPassword}
                required
              />
              <Button
                title={!isLoading && t('validationModal.sign')}
                titleStyle={{ color: theme.colors.white }}
                onClick={signInWithMobile ? loginWithPhoneNumber : loginWithEmail}
                className={"self-center bg-black w-full mt-4"}
                loading={loginWithEmail ? isLoading : null}
              />
            </div>
            :
            <div>
              <div className="grid lg:gap-4 lg:grid-cols-2 ">
                <Input
                  name="fname"
                  value={fname}
                  type="text"
                  title={t('validationModal.firstName')}
                  placeholder={"Max"}
                  onChange={onChangeHandler}
                />
                <Input
                  name="lname"
                  value={lname}
                  type="text"
                  title={t('validationModal.lastName')}
                  placeholder={"Muster"}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="grid lg:gap-4 lg:grid-cols-2">
                <Input
                  name="registrationEmail"
                  value={registrationEmail}
                  type="email"
                  title={t('validationModal.email')}
                  onChange={onChangeEmail}
                  headericon={
                    registrationEmail && verifyEmail ? greenCheckIcon : registrationEmail ? checkIcon : null
                  }
                  verifybuttontitle={
                    registrationEmail && verifyEmail ? "Verified" : registrationEmail ? "Verify" : null
                  }
                  verifybuttonclassname={`${registrationEmail && verifyEmail
                    ? "text-black"
                    : registrationEmail
                      ? "text-blue underline"
                      : null
                    }`}
                  verifybuttonclick={registrationEmail && toggleEmailVerificationModal}
                  placeholder={"kunde@mapl.ch"}
                  required
                  bottomlabel={
                    registrationEmail && verifyEmail
                      ? null
                      : registrationEmail
                        ? "Didn’t receive the Code? "
                        : null
                  }
                  verifybuttondisabled={verifyEmail ? true : false}
                  bottomclick={toggleEmailVerificationModal}
                />
                <Input
                  name="registrationPhoneNum"
                  value={registrationPhoneNum}
                  type="tel"
                  title={t('validationModal.mobileNumber')}
                  headericon={
                    registrationPhoneNum && verifyMobileNum
                      ? greenCheckIcon
                      : registrationPhoneNum
                        ? checkIcon
                        : null
                  }
                  verifybuttontitle={
                    registrationPhoneNum && verifyMobileNum
                      ? "Verified"
                      : registrationPhoneNum
                        ? "Verify"
                        : null
                  }
                  verifybuttonclassname={`${registrationPhoneNum && verifyMobileNum
                    ? "text-black"
                    : registrationPhoneNum
                      ? "text-blue underline"
                      : null
                    }`}
                  verifybuttonclick={
                    registrationPhoneNum && toggleMobileVerificationModal
                  }
                  placeholder={"+41 79 774 53 76"}
                  maxLength={13}
                  inputicon={switzerlandLogo}
                  onChange={onChangeMobileNum}
                  bottomlabel={
                    registrationPhoneNum && verifyMobileNum
                      ? null
                      : registrationPhoneNum
                        ? "Didn’t receive the Code? "
                        : null
                  }
                  verifybuttondisabled={verifyMobileNum ? true : false}
                  bottomclick={toggleMobileVerificationModal}
                />
              </div>
              <Input
                name="registrationPassword"
                value={registrationPassword}
                title={t('validationModal.password')}
                placeholder={t('validationModal.password')}
                onChange={onChangeHandler}
                required
                type={showPassword ? "text" : "password"}
                hideandshoweyeicon={
                  showPassword ? (
                    <EyeIcon className="w-3 h-3" />
                  ) : (
                    <EyeOffIcon className="w-3 h-3" />
                  )
                }
                hideandshowpasswordonclick={handleClickShowPassword}
              />
              <Input
                name="registrationConfirmPassword"
                value={registrationConfirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                title={t('validationModal.confirmPassword')}
                placeholder={t('validationModal.confirmPassword')}
                onChange={onChangeHandler}
                hideandshoweyeicon={
                  showConfirmPassword ? (
                    <EyeIcon className="w-3 h-3" />
                  ) : (
                    <EyeOffIcon className="w-3 h-3" />
                  )
                }
                hideandshowpasswordonclick={handleClickShowConfirmPassword}
              />
              <Input
                name="referralCode"
                value={referralCode}
                type="text"
                title={t('validationModal.referralCode')}
                placeholder={t('validationModal.referralCode')}
                onChange={onChangeHandler}
              />
              <div className="grid lg:gap-4 lg:grid-cols-2">
                <CustomCalendar
                  title={t('validationModal.dateofBirth')}
                  value={registrationDob}
                  name="registrationDob"
                  disableFuture={true}
                  onChange={handleChange}
                />
                <Input
                  name="company"
                  // value={company}
                  type="text"
                  title={"Company"}
                  placeholder={"Muster GmbH"}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="grid lg:gap-4 lg:grid-cols-2 ">
                <Input
                  name="url"
                  value={url}
                  type="url"
                  title={"Web URL (optional)"}
                  placeholder={"https://google.com"}
                  onChange={onChangeHandler}
                />
                <Input
                  name="uid"
                  value={uid}
                  type="text"
                  title={"UID / VAT number"}
                  placeholder={"CH-123.456.789"}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="grid lg:gap-4 lg:grid-cols-2 ">
                <Input
                  name="street"
                  value={street}
                  type="text"
                  title={"Street"}
                  placeholder={"Soodstrasse"}
                  onChange={onChangeHandler}
                />
                <Input
                  name="number"
                  value={number}
                  type="text"
                  title={"No."}
                  placeholder={"52"}
                  maxLength={2}
                  onChange={onChangeHandler}
                />
              </div>
              <div className="grid lg:gap-4 lg:grid-cols-3 ">
                <Input
                  name="postCode"
                  value={postCode}
                  type="text"
                  title={"Postcode"}
                  placeholder={"8134"}
                  maxLength={4}
                  onChange={onChangeHandler}
                />
                <Input
                  name="city"
                  value={city}
                  type="text"
                  title={"City"}
                  placeholder={"Adliswil"}
                  onChange={onChangeHandler}
                />
                <Input
                  name="country"
                  value={country}
                  type="text"
                  title={"Country"}
                  placeholder={"Switzerland"}
                  onChange={onChangeHandler}
                />
              </div>
              <CheckBox title={t('validationModal.subscribeNewsletter')} value={newsletterChecked} onChange={(e) => handleChecked(e)} defaultChecked={true} />
              <Button
                title={!isLoading ? t('validationModal.createAccount') : null}
                titleStyle={{
                  color:
                    registrationPhoneNum && checkAllInputs
                      ? theme.colors.black
                      : registrationPhoneNum
                        ? theme.colors.white
                        : theme.colors.white,
                }}
                onClick={createAccount}
                loading={isLoading}
                className={`${registrationPhoneNum && checkAllInputs
                  ? "self-center bg-b2bButton mt-[20px] w-full"
                  : registrationPhoneNum
                    ? "self-center bg-black mt-[20px] w-full"
                    : "self-center bg-black mt-[20px] w-full"
                  }`}
              />
            </div>}
          <OtpModal
            value={isMobileModalValue}
            onChange={getMobileNumberOtpModalValue}
            isOpenModal={isMobileVerificationModal}
            title={"Mobile number Verification"}
            heading={"Please enter 4 digit code"}
            buttonTitle={"Verify"}
            closeModal={() =>
              isMobileVerificationModal &&
              setState((prev) => ({
                ...prev,
                isMobileVerificationModal: false,
                isMobileModalValue: "",
              }))
            }
            onClick={onclickMobileOtpModal}
          />
          <OtpModal
            value={isEmailModalValue}
            onChange={getEmailOtpModalValue}
            isOpenModal={isEmailVerificationModal}
            title={"Email Verification"}
            heading={"Please enter 4 digit code"}
            buttonTitle={"Verify"}
            closeModal={() =>
              isEmailVerificationModal &&
              setState((prev) => ({
                ...prev,
                isEmailVerificationModal: false,
                isEmailModalValue: "",
              }))
            }
            onClick={onclickEmailOtpModal}
          />
        </div>
      </div>
    </div>
  )
}

const ToggleButton = tw.button`
transition-[5s]
lg:text-[18px]
lg:tracking-[0.17px]
tracking-[0.13px]
uppercase
`;

export default ToggleModalHeader;