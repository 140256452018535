import React from "react";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";

function ExtraValues(props) {
  const { extraInfo } = props;

  const { t } = useTranslation()

  return (
    <div>
      <HeadingText>{t("extraInfo.extraInformation")}</HeadingText>
      {extraInfo && extraInfo?.map((values, index) => {
        return (
          <ExtraContent key={index}>
            <p className="mr-2 text-color155 w-[35%]">{values.extraInfoTitle}</p>
            <p className="w-full">{values.extraInfoValue}</p>
          </ExtraContent>
        );
      })}
    </div>
  );
}

const HeadingText = tw.span`
text-xl 
font-raleway 
font-bold
py-3
`;

const ExtraContent = tw.div`
border-b 
border-b-color172 
flex 
items-center
w-full
py-3
`;

export default ExtraValues;
