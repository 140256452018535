import * as types from "./Types";

const initialState = {
  recommendationProduct: [],
  newReleaseProduct: [],
  trendingProduct: [],
  salesProduct: [],
  offerPopup: [],
  popularSearchItem: [],
  storeSchedule: [],
  userNotification: [],
  homeBanner: {
    top: null,
    left: null,
    right: null,
    trending: [],
    trendingFour: null,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RECOMMENDATION_PRODUCT: {
      return {
        ...state,
        recommendationProduct: action?.payload?.data?.data,
      };
    }
    case types.GET_NEWRELEASE_PRODUCT: {
      return {
        ...state,
        newReleaseProduct: action?.payload?.data?.data,
      };
    }
    case types.GET_TRENDING_PRODUCT: {
      return {
        ...state,
        trendingProduct: action?.payload?.data?.data,
      };
    }
    case types.GET_SALES_PRODUCT: {
      return {
        ...state,
        salesProduct: action?.payload?.data?.data,
      };
    }
    case types.GET_OFFER_POPUP: {
      return {
        ...state,
        offerPopup: action?.payload?.data?.data,
      };
    }
    case types.POPULAR_SEARCH: {
      return {
        ...state,
        popularSearchItem: action?.payload?.data?.data,
      };
    }
    case types.STORE_SCHEDULE: {
      return {
        ...state,
        storeSchedule: action?.payload?.data?.data,
      };
    }
    case types.USER_NOTIFICATION: {
      return {
        ...state,
        userNotification: action?.payload?.data?.data,
      }
    }
    case types.GET_HOME_BANNER: {
      let banners = { ...state?.homeBanner }
      action?.payload?.data?.data?.map((item) => {
        if (item?.position == 'trendingOne' || item?.position == 'trendingTwo' || item?.position == 'trendingThree') {
          banners.trending = [...banners?.trending, item?.image]
        } else {
          banners[item?.position] = item?.image
        }
      })
      return {
        ...state,
        homeBanner: banners
      }
    }
    default:
      return state;
  }
};

export default reducer;