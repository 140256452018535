import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { PrimaryText, Button, Input } from "@components";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import theme from "@theme";
import OtpModal from "./OtpModal";
import ConfirmPasswordModal from "./ConfirmPasswordModal";
import { en } from "@i18n";
import { toast } from "react-toastify";
import { isValidEmail } from "@utils";
import { userApi } from "@services";
import { useDispatch } from "react-redux";
import { verifyOtp } from "@store";

const ForgotPasswordModal = (props) => {
  const { isOpenModal, closeModal } = props;

  const [state, setState] = useState({
    isOpenOtpModal: false,
    isOpenConfirmPassword: false,
    email: "",
    isEmailModalValue: "",
    newPassword: "",
    confirmPassword: "",
  });
  const {
    isOpenOtpModal,
    isOpenConfirmPassword,
    email,
    isEmailModalValue,
    newPassword,
    confirmPassword,
  } = state;

  const hidden = !isOpenModal ? "hidden" : "flex";

  const modalStyle = useSpring({
    scale: isOpenModal ? 1 : 0,
    opacity: isOpenModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOpenModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const CrossButton = () => {
    return (
      <div className="flex justify-end">
        <button onClick={closeModal} className="m-3">
          <XIcon className="w-[20px]" />
        </button>
      </div>
    );
  };

  const dispatch = useDispatch();

  const onChangeHandler = (event) => {
    setState({ ...state, email: event.target.value });
  };

  const getEmailOtpModalValue = (value) => {
    setState({ ...state, isEmailModalValue: value });
  };

  const verifyEmailOtp = () => {
    let data = {
      email: email,
      role: "business_user",
      otp: isEmailModalValue,
    };
    dispatch(verifyOtp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setState((prev) => ({
          ...prev,
          isOpenConfirmPassword: true,
          isEmailModalValue: isEmailModalValue,
        }));
      } else {
        toast.error(en.errors.minimumOtpValue);
      }
    });
  };

  const onclickResetPassword = () => {
    if (email?.trim().length === 0) {
      toast.error(en.errors.email);
    } else if (email && !isValidEmail(email?.trim())) {
      toast.error(en.errors.emailValidate);
    } else {
      let data = {
        email: email,
        role: "business_user",
      };
      userApi
        .forgetPassword(data)
        .then((res) => {
          if (res?.response?.status == 404) {
            toast.error(res?.response?.data?.message);
          } else {
            toast.success(res?.data?.message);
            setState((prev) => ({
              ...prev,
              isOpenOtpModal: true,
            }));
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const verifyConfirmPassword = () => {
    if (isEmailModalValue?.trim().length === 0) {
      toast.error(en.errors.otpValidate);
    } else if (isEmailModalValue?.trim().length < 4) {
      toast.error(en.errors.minimumOtpValue);
    } else {
      verifyEmailOtp();
    }
  };

  const onChangeHandlerNewPassword = (event) => {
    setState({ ...state, newPassword: event.target.value });
  };

  const onChangeHandlerConfirmPassword = (event) => {
    setState({ ...state, confirmPassword: event.target.value });
  };

  const onclickSavePassword = () => {
    if (
      newPassword?.trim().length === 0 ||
      confirmPassword?.trim().length === 0
    ) {
      toast.error(en.errors.password);
    } else if (newPassword?.trim().length < 8) {
      toast.error(en.errors.minimumPasswordValue);
    } else if (newPassword !== confirmPassword) {
      toast.error(en.errors.confirmPasswordValidate);
    } else {
      const data = {
        email: email,
        password: newPassword,
        role: "business_user",
        otp: isEmailModalValue
      };
      userApi
        .resetPassword(data)
        .then((res) => {
          toast.success(res?.data?.message);
          window.location.reload();
        })
        .catch((err) => {
          return err;
        });
    }
  };

  return (
    <animated.div
      style={modalBackground}
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center bg-white items-center z-[1000]`}
    >
      <animated.div style={modalStyle}>
        <Container>
          <CrossButton />
          <Content>
            <PrimaryText
              className={"text-[22px] tracking-wider font-bold text-center"}
            >
              {"Forgot your Password?"}
            </PrimaryText>
            <PrimaryText
              className={
                "sm:text-[10px] lg:text-[12px] font-semibold tracking-wider my-3 text-center"
              }
            >
              {`Confirm your email and we'll send the instructions.`}
            </PrimaryText>
            <Input
              placeholder={"swissan@mail.com"}
              type={"text"}
              value={email}
              onChange={onChangeHandler}
            />
            <Button
              title={"Reset Password"}
              titleStyle={{
                color: theme.colors.white,
                flex: 1,
                alignSelf: "center",
              }}
              className="bg-black w-[100%] sm:w-[70%] sm:mr-[20px] my-6 justify-center  font-semibold flex rounded-md"
              onClick={onclickResetPassword}
            />
          </Content>
        </Container>
      </animated.div>
      <OtpModal
        isOpenModal={isOpenOtpModal}
        value={isEmailModalValue}
        onChange={getEmailOtpModalValue}
        title={"Email Verification"}
        heading={"Please enter 4 digit code"}
        buttonTitle={"Verify"}
        onClick={verifyConfirmPassword}
        closeModal={() =>
          isOpenOtpModal &&
          setState((prev) => ({
            ...prev,
            isOpenOtpModal: false,
            isEmailModalValue: "",
          }))
        }
      />
      <ConfirmPasswordModal
        isOpenModal={isOpenConfirmPassword}
        newPasswordValue={newPassword}
        confirmPasswordValue={confirmPassword}
        onChangeNewPassword={onChangeHandlerNewPassword}
        onChangeConfirmPassword={onChangeHandlerConfirmPassword}
        onClick={onclickSavePassword}
        closeModal={() =>
          isOpenConfirmPassword &&
          setState((prev) => ({ ...prev, isOpenConfirmPassword: false }))
        }
      />
    </animated.div>
  );
};

const Container = tw.div`
bg-white
mx-auto
w-[90%]
h-[75%]
lg:w-[480px]
md:w-[440px]
sm:w-[400px]
lg:h-[300px]
md:h-[290px]
sm:h-[280px]
rounded-sm
`;

const Content = tw.div`
px-10 
relative 
h-full
mx-auto
flex-col
flex
`;

export default ForgotPasswordModal;