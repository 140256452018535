import * as types from "./Types";
import { userApi } from "@services";

export const setToken = (token) => {
  return {
    type: types.TOKEN,
    payload: token
  };
};

export const login = (data) => {
  return {
    type: types.Login,
    payload: userApi.login(data),
  };
};

export const socialUserSignup = (data) => {
  return {
    type: types.SOCIAL_USER_SIGNUP,
    payload: userApi.socialUserSignup(data),
  };
};

export const registerUser = (data) => {
  return {
    type: types.REGISTER_USER,
    payload: userApi.signUp(data),
  };
};

export const verifyUser = (data) => {
  return {
    type: types.VERIFY_USER,
    payload: userApi.verifyUser(data),
  };
};

export const verifyOtp = (data) => {
  return {
    type: types.VERIFY_OTP,
    payload: userApi.verifyOtp(data)
  };
};

export const userProfile = () => {
  return {
    type: types.USER_PROFILE,
    payload: userApi.userProfile(),
  };
};

export const updateUserProfile = (data) => {
  return {
    type: types.UPDATE_USER_PROFILE,
    payload: userApi.updateUserProfile(data),
  };
};

export const changeUserPassword = (data) => {
  return {
    type: types.CHANGE_USER_PASSWORD,
    payload: userApi.changeUserPassword(data),
  };
};

export const getUserAddress = () => {
  return {
    type: types.GET_USER_ADDRESS,
    payload: userApi.getUserAddress(),
  };
};

export const addUserAddress = (data) => {
  return {
    type: types.GET_USER_ADDRESS,
    payload: userApi.addUserAddress(data),
  };
};

export const addSuggestLocation = (data) => {
  return {
    type: types.ADD_SUGGEST_LOCATION,
    payload: userApi.addSuggestLocation(data)
  };
};

export const editUserAddress = (id, data) => {
  return {
    type: types.GET_USER_ADDRESS,
    payload: userApi.getUserAddress(id, data),
  };
};

export const deleteUserAddress = (id) => {
  return {
    type: types.GET_USER_ADDRESS,
    payload: userApi.deleteUserAddress(id),
  };
};

export const addInvoiceDetail = (data) => {
  return {
    type: types.ADD_INVOICE_DETAIL,
    payload: userApi.addInvoiceDetail(data),
  };
};

export const getInvoiceDetail = () => {
  return {
    type: types.GET_INVOICE_DETAIL,
    payload: userApi.getInvoiceDetail(),
  };
};

export const createPaymentsTrasactionId = (data) => {
  return {
    type: types.CREATE_PAYMENT_TRANSACTION_ID,
    payload: userApi.createPaymentTransactionId(data),
  };
};

export const uploadDeviceToken = (data) => {
  return {
    type: types.UPLOAD_DEVICE_TOKEN,
    payload: userApi.uploadDeviceToken(data)
  };
};

export const logout = (data) => {
  return {
    type: types.LOGOUT,
    payload: userApi.logout(data)
  };
};