import React, { lazy } from "react";

const Login = lazy(() => import("@containers/Auth/Login"));
const BusinessRegistration = lazy(() =>
  import("@containers/Auth/BusinessRegistration")
);
// const ConfirmBusinessRegistration = lazy(() =>
//   import("@containers/Auth/ConfirmBusinessRegistration")
// );
const Products = lazy(() => import("@containers/Products"));
const ProductDetail = lazy(() => import("@containers/ProductDetail"));
const UserAccount = lazy(() => import("@containers/UserAccount"));
const Wishlist = lazy(() => import("@containers/Wishlist"));
const Cart = lazy(() => import("@containers/Cart"));
const Checkout = lazy(() => import("@containers/Checkout"));
const PrivacyPolicy = lazy(() => import("@containers/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("@containers/TermsAndConditions"));
const Return = lazy(() => import("@containers/Return"));
const ShippingMethod = lazy(() => import("@containers/ShippingMethod"));
const Impressum = lazy(() => import("@containers/Impressum"));
const OrderPlaced = lazy(() => import("@containers/OrderPlaced"));
const OrderDeclined = lazy(() => import("@containers/OrderDeclined"));
const OfferDetail = lazy(() => import("@containers/OfferDetail"));
const OrderError = lazy(() => import("@containers/OrderError"));
const ViewMore = lazy(() => import("@containers/ViewMore"));
const Home = lazy(() => import("@containers/Home"))

const userAuthenticated = [
  {
    exact: true,
    isPublic: false,
    path: "/dashboard",
    name: "dashboard",
    element: <Home />
  },
  {
    exact: true,
    isPublic: false,
    path: "/",
    name: "dashboard",
    element: <Home />
  },
  {
    exact: true,
    isPublic: false,
    path: "/products/:id",
    name: "products",
    element: <Products />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/product/:id",
    name: "product",
    element: <ProductDetail />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/return",
    name: "Return",
    element: <Return />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/shipping-method",
    name: "ShippingMethod",
    element: <ShippingMethod />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    element: <TermsAndConditions />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/impressum",
    name: "Impressum",
    element: <Impressum />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/offer-detail",
    name: "offerDetail",
    element: <OfferDetail />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/view-more",
    name: "viewMore",
    element: <ViewMore />
  },
  {
    exact: true,
    isPublic: false,
    path: "/profile/*",
    name: "profile",
    element: <UserAccount />,
  },
  { exact: true, isPublic: false, path: "/wishlist", name: "wishlist", element: <Wishlist /> },
  { exact: true, isPublic: false, path: "/cart", name: "cart", element: <Cart /> },
  { exact: true, isPublic: false, path: "/checkout", name: "checkout", element: <Checkout /> },
  {
    exact: true,
    isPublic: false,
    path: "/order-placed/:orderId",
    name: "orderPlaced",
    element: <OrderPlaced />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/order-declined/:orderId",
    name: "orderDeclined",
    element: <OrderDeclined />,
  },
  {
    exact: true,
    isPublic: false,
    path: "/order-error/:orderId",
    name: "orderError",
    element: <OrderError />,
  },
];

const userUnAuthenticated = [
  { exact: true, isPublic: true, path: "/", name: "Login", element: <Login /> },
  { exact: true, isPublic: true, path: "*", name: "Login", element: <Login /> },
  {
    exact: true,
    isPublic: true,
    path: "/business-registration",
    name: "business-registration",
    element: <BusinessRegistration />,
  },
  // {
  //   exact: true,
  //   path: "/confirm-business-registration",
  //   name: "Confirm-business-registration",
  //   element: <ConfirmBusinessRegistration />,
  // },
];

export { userAuthenticated, userUnAuthenticated };