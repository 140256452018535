import applePay from "@assets/images/footer-payment-block/applePay.png";
import mcardPay from "@assets/images/footer-payment-block/mcardPay.png";
import paypalPay from "@assets/images/footer-payment-block/paypalPay.png";
import postfinancePay from "@assets/images/footer-payment-block/post-financePay.png";
import twintPay from "@assets/images/footer-payment-block/twintPay.png";
import visaPay from "@assets/images/footer-payment-block/visaPay.png";

const paymentImg = [
    { img: visaPay },
    { img: mcardPay },
    { img: paypalPay },
    { img: postfinancePay },
    { img: twintPay },
    { img: applePay },
];

export default paymentImg;





