import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import maplLogo from "@assets/images/mapl1.png";
import { UserData, SearchPopup } from "./widgets";
import { Basic } from "@components";
import { useDispatch } from "react-redux";
import { getProducts } from "@store";
import { useTranslation } from "react-i18next";
import { MdClear, MdOutlineWatchLater, MdLocationOn } from "react-icons/md";
import { scrollToTopWindow } from "@utils";

const SecondaryNavbar = () => {
  const [state, setState] = useState({
    searchValue: "",
    openFilter: false,
    openSearch: false,
    openIcon: false,
  });
  const { searchValue, openFilter, openSearch, openIcon } = state;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearchIcon = () => {
    setState((prev) => ({ ...prev, openSearch: true }));
  };

  const handlePage = () => {
    navigate("/dashboard");
  };

  const handleOpen = (e) => {
    e.target.value.length !== 0
      ? setState((prev) => ({ ...prev, openFilter: false }))
      : setState((prev) => ({ ...prev, openFilter: true }));
    setState((prev) => ({ ...prev, openSearch: true }));
  };

  const onSearchSubmit = (e) => {
    if (e.charCode == 13 && e.target.value.length != 0) {
      let payload = `search=${e.target.value}`;
      dispatch(getProducts(payload));
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    let payload = `popularSearch=${e.target.value}`;
    dispatch(getProducts(payload));
    e.target.value.length !== 0
      ? setState((prev) => ({ ...prev, openFilter: false }))
      : setState((prev) => ({ ...prev, openFilter: true }));
  }

  const handleClose = () => {
    if (searchValue) {
      setState((prev) => ({ ...prev, openSearch: false, searchValue: "" }));
    } else {
      setState((prev) => ({ ...prev, openSearch: false }));
    }
  };

  const { t } = useTranslation();

  const gotoAddNewAddress = () => {
    scrollToTopWindow();
    navigate("/profile/add-new-address");
  };

  return (
    <>
      <HeaderContainer>
        <HeaderContent>
          <LogoContent>
            <LogoImg src={maplLogo} alt="" onClick={handlePage} />
            <LocationBtn onClick={gotoAddNewAddress}>
              <MdLocationOn size={20} color={'red'} />
              <Basic className="xl:text-[12px] text-[11px] xl:flex lg:flex hidden font-semibold">
                {t("header.choose_location")}
              </Basic>
              <div className="md:flex mx-1 bg-white h-full rounded-sm p-1 xs:hidden">
                <MdOutlineWatchLater className="self-center" size={15} color={'black'} />
                <h1 className="text-black font-bold self-center">09 min</h1>
              </div>
            </LocationBtn>
          </LogoContent>
          <SearchContent>
            <SearchInput $icon={openIcon}>
              <div className="p-3">
                <HiOutlineSearch
                  size={16}
                  onClick={handleSearchIcon}
                  className={"xl:text-lg lg:text-base cursor-pointer"}
                />
              </div>
              <InputBox>
                <CustomInput
                  value={searchValue}
                  name={"searchValue"}
                  placeholder={t("header.product_search")}
                  onChange={handleChange}
                  onKeyPress={onSearchSubmit}
                  onFocus={handleOpen}
                  autoComplete="off"
                  id={"searchBox"}
                />
              </InputBox>
              {openSearch && (
                <div className="ml-10 p-3 cursor-pointer">
                  <MdClear
                    onClick={handleClose}
                    size={16}
                    className={"xl:text-lg lg:text-base"}
                  />
                </div>
              )}
            </SearchInput>
            <SearchModal
              $openSearch={openSearch}
              onClick={handleClose}
              onBlur={(e) => e.stopPropagation()}
              className="cursor-pointer"
            >
              <SearchPopup openFilter={openFilter} openSearch={openSearch} closeModal={handleClose} closePopularSearchModal={handleClose} />
            </SearchModal>
            <UserContent>
              <UserData />
            </UserContent>
          </SearchContent>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = tw.div`
w-full 
p-4
bg-white
border-b
border-color235
`;

const HeaderContent = tw.div`
flex 
items-center
self-center
w-full 
h-full 
`;

const LogoContent = tw.div`
4xl:w-1/5
2xl:w-3/12
xl:w-5/12
lg:w-6/12
md:w-7/12
sm:w-2/5
w-1/2
cursor-pointer
flex
items-center
`;

const SearchContent = tw.div`
4xl:w-4/5
2xl:w-9/12
xl:w-9/12
lg:w-6/12
md:w-6/12
sm:w-3/5 
w-1/2
md:justify-end
sm:justify-end
justify-end
flex 
items-center 
`;

const LogoImg = tw.img`
2xl:w-[44%]
xl:max-w-[42%] 
lg:max-w-[28%]
md:max-w-[40%]
sm:max-w-[65%]
max-w-[60%]
4xl:mr-4
xl:mr-4
lg:mr-8
sm:mr-8
mr-4
`;

const LocationBtn = tw.button`
bg-black
text-white
flex
items-center
py-1
px-1
text-[10px] 
h-9
lg:text-[12px] 
md:text-[11px]
rounded
`;

const SearchModal = tw.div`
${(p) =>
    p.$openSearch
      ? `absolute 
    w-full 
    h-screen 
    top-[100%] 
    right-0 
    bottom-0 
    left-0 
    z-10 
    bg-searchBg`
      : ` `}
`;

const SearchInput = tw.div`
${(p) =>
    p.$icon ? "lg:w-[60vw] w-full rounded-none" : "lg:w-10 w-10 rounded-full"}
2xl:w-[65vw]
xl:w-[60vw]
xl:h-9
2xl:rounded-none
xl:rounded-none
lg:h-10 
h-10
border
border-color64
relative
duration-150
overflow-hidden
flex 
items-center
`;

const InputBox = tw.div`
relative
w-full
h-8
flex
items-center
justify-center
`;

const CustomInput = tw.input`
absolute
top-0
w-full
h-full
outline-none
`;

const UserContent = tw.div`
xl:w-full
md:w-auto
h-full 
relative
xl:block
lg:hidden
hidden
`;

export default SecondaryNavbar;