import React from "react";
import { PrimaryText } from "@components";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";

const CustomCalendar = ({ calendarWrapper, title, titleStyle, maxDate, minDate, value, onChange, disableFuture }) => {

  return (
    <div className={`relative`}>
      {title && (
        <PrimaryText
          style={{ ...titleStyle }}
          className={"text-[12px] font-sans font-semibold "}
        >
          {title}
        </PrimaryText>
      )}
      <div className={`${calendarWrapper} py-[0.8pc]`}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            className="w-full self-center flex px-2"
            inputFormat="DD/MM/YYYY"
            value={value}
            disableFuture={disableFuture}
            onChange={onChange}
            maxDate={maxDate}
            minDate={minDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default CustomCalendar;