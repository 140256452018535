import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  HeadingText,
  Carousel,
  ToggleSwitch,
  Basic,
  ValidationModal,
} from "@components";
import tw from "tailwind-styled-components";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProducts } from "@store";
import categoryDefault from "@assets/images/categoriesIcon/catergoryDefault.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AiOutlineShopping } from "react-icons/ai"

const ProductContent = (props) => {
  const {
    categoryData,
    subCategoryData,
    onClickCategory,
    categoryName,
    categoryId,
  } = props;

  const [state, setState] = useState({
    active: "",
    allProduct: false,
    productData: [],
    categoryItemName: "",
    filtered: null,
    btnActive: null,
    newProduct: false,
    sales: false,
    trend: false,
    fromSwitzerland: false,
    slideTo: false,
    isValidateModal: false,
  });
  const {
    active,
    allProduct,
    productData,
    categoryItemName,
    newProduct,
    swiper,
    sales,
    trend,
    fromSwitzerland,
    btnActive,
    filtered,
    slideTo,
    isValidateModal,
  } = state;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { newReleaseProduct, salesProduct, recommendationProduct } =
    useSelector((state) => state.home);
  const allProductList = useSelector((state) => state.products.allProductList);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    setState((prev) => ({ ...prev, allProduct: true, slideTo: true }));
    getData(categoryName);
  }, [categoryName]);

  let allProductData = [
    ...(newProduct ? newReleaseProduct : []),
    ...(sales ? salesProduct : []),
    ...(trend ? recommendationProduct : []),
    ...(newProduct || sales || trend ? [] : allProductList),
  ];

  const toggleOption = [
    {
      label: t("new"),
      name: "newProduct",
      checked: newProduct ? newProduct : null,
    },
    { label: t("sale"), name: "sales", checked: sales ? sales : null },
    { label: t("trend"), name: "trend", checked: trend ? trend : null },
    {
      label: "From Switzerland",
      name: "fromSwitzerland",
      checked: fromSwitzerland,
    },
  ];

  const getData = (name) => {
    let payload;
    if (name) {
      payload = `category=${name}`;
    }

    return dispatch(getProducts(payload))
      .then((res) => {
        setState((prev) => ({
          ...prev,
          filtered: null,
        }));
      })
      .catch((err) => {
        return err;
      });
  };

  const handleAllProduct = () => {
    setState((prev) => ({
      ...prev,
      active: "",
      allProduct: true,
    }));
    getData(categoryName);
  };

  const filterAsec = (title) => {
    if (title === "rate") {
      let filterData = allProductData.sort((a, b) =>
        a?.price > b?.price ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else if (title === "weight") {
      let filterData = allProductData.sort((a, b) =>
        a?.weight > b?.weight ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else if (title === "name") {
      let filterData = allProductData.sort((a, b) =>
        a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else {
      toast.error("Wrong Sorting");
    }
  };

  const filterDesc = (title) => {
    if (title === "rate") {
      let filterData = allProductData.sort((a, b) =>
        a?.price < b?.price ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else if (title === "weight") {
      let filterData = allProductData.sort((a, b) =>
        a?.weight < b?.weight ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else if (title === "name") {
      let filterData = allProductData.sort((a, b) =>
        a?.name.toLowerCase() < b?.name.toLowerCase() ? 1 : -1
      );
      setState((prev) => ({ ...prev, filtered: filterData }));
    } else {
      toast.error("Wrong Sorting");
    }
  };

  const sorting = [
    {
      title: "rate",
      name: "CHF",
      handleFilterAsec: filterAsec,
      handleFilterDesc: filterDesc,
    },
    {
      title: "weight",
      name: "CHF/100g",
      handleFilterAsec: filterAsec,
      handleFilterDesc: filterDesc,
    },
    {
      title: "name",
      name: "A-Z",
      handleFilterAsec: filterAsec,
      handleFilterDesc: filterDesc,
    },
  ];

  const handleClick = (e, item) => {
    setState((prev) => ({
      ...prev,
      active: e.target.id,
      categoryItemName: item?.name,
      allProduct: false,
    }));
    getData(item?.name);
  };

  const handleImg = (e) => {
    e.target.src = categoryDefault;
  };

  const changeId = (list) => {
    setState((prev) => ({ ...prev, btnActive: list.id }));
  };

  var date = new Date();
  var todayDate = date.getDate() - 3;

  const onClickToggle = (item) => {
    setState((prev) => ({ ...prev, [item.name]: !item.checked }));
    if (item.name === "sales") {
      const fil = allProductList.filter((filterItem) => {
        return filterItem.offer_id === "";
      });
      setState((prev) => ({ ...prev, productData: fil }));
    } else if (item.name === "trend") {
      const fill = allProductList.filter((filters) => {
        return filters.trending === 1;
      });
      setState((prev) => ({ ...prev, productData: fill }));
    } else if (item.name === "newProduct") {
      const filll = allProductList.filter((filteration) => {
        return filteration.created_at < todayDate;
      });
      setState((prev) => ({ ...prev, productData: filll }));
    } else {
      setState((prev) => ({ ...prev, productData: productData }));
    }
  };

  const onClickProduct = (id) => {
    navigate(`/product/${id}`, { state: id });
  };

  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }


  return (
    <>
      <Container className="mx-2 my-6 relative lg:overflow-visible  overflow-x-auto scrollbar-none lg:p-0 py-4">
        {toggleOption?.map((item, index) => {
          return (
            <ToggleSwitch
              key={index}
              Checked={item.checked}
              label={`${item.label}`}
              onClick={() => onClickToggle(item)}
            />
          );
        })}
      </Container>
      <Carousel
        autoplay={false}
        freeMode={true}
        cssMode={false}
        slidesPerGroup={4}
        slidesPerView={"auto"}
        slidesperview1200={"auto"}
        spacebetween1200={25}
        slidesperview1024={"auto"}
        spacebetween1024={6}
        slidesperview768={"auto"}
        spacebetween768={5}
        slidesperview567={"auto"}
        spacebetween567={5}
        slidesperview480={"auto"}
        spacebetween480={5}
        slidesperview280={"auto"}
        spacebetween280={5}
        navigation={{
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        }}
        carouselclass={"my-5"}
      // slideToSlide={slideTo}
      >
        {categoryData?.map((list, index) => {
          return (
            <SwiperSlide
              className={`flex items-center justify-center max-w-fit cursor-pointer`}
              id={btnActive}
              onClick={() => {
                onClickCategory(list);
                changeId(list);
              }}
              key={index}
            >
              <div
                className={`${btnActive === null
                  ? list.id === categoryId
                    && "border-3 text-black"
                  : btnActive === list.id
                    && "border-2 text-black"
                  } border border-black rounded-md p-3 flex w-max items-center`}
              >
                <Image>
                  {list.icon ? (
                    <img
                      src={list.icon}
                      alt=""
                      onError={(e) => {
                        handleImg(e);
                      }}
                      className={`${btnActive === null
                        ? list.id === categoryId
                          ? "bg-white"
                          : "bg-transparent"
                        : btnActive === list.id
                          ? "bg-white"
                          : "bg-transparent"
                        } img-${index} max-w-full h-auto `}
                    />
                  ) : (
                    <AiOutlineShopping className="max-w-full" />
                  )}
                </Image>
                <Basic className="list-txt pl-2">{list.name}</Basic>
              </div>
            </SwiperSlide>
          );
        })}
        <NxtBtn className="swiper-next">
          <FaChevronRight size={25} />
        </NxtBtn>
        <PrvBtn className="swiper-prev">
          <FaChevronLeft size={25} />
        </PrvBtn>
      </Carousel>
      <OptionContainer>
        <SliderWrapper>
          <SlideList>
            <AllItem onClick={handleAllProduct} $allProduct={allProduct}>
              {"All item"}
            </AllItem>
            {subCategoryData?.map((item, index) => {
              return (
                <List key={index}>
                  <ListElement
                    $active={active === index.toString()}
                    id={index.toString()}
                    onClick={(e) => handleClick(e, item)}
                  >
                    {item.name}
                  </ListElement>
                </List>
              );
            })}
          </SlideList>
        </SliderWrapper>
      </OptionContainer>
      <TitleContainer>
        <HeadingText>{t("products")}</HeadingText>
        <Sorting>
          {sorting?.map((val, idx) => (
            <div
              key={idx}
              className="flex after:border-l-2 last:after:border-0 after:border-color889 px-2"
            >
              <Basic>{val.name}</Basic>
              <TiArrowSortedDown
                size={17}
                onClick={() => val.handleFilterAsec(val.title)}
                className={"cursor-pointer"}
              />
              <TiArrowSortedUp
                size={17}
                onClick={() => val.handleFilterDesc(val.title)}
                className={"cursor-pointer"}
              />
            </div>
          ))}
        </Sorting>
      </TitleContainer>
      <Container
        $col={true}
        className="grid gap-4 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2"
      >
        {/* {allProductData && productData?.map((item, index) => { */}

        {(filtered !== null ? filtered : allProductData)?.map((item, index) => {
          return (
            <Card
              productItem={item}
              key={index}
              id={index}
              className="pb-2 lg:w-auto w-auto"
              weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
              productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
              recommendtag={item?.recommended === 1 && t('recommended')}
              trendtag={item?.trending === 1 && t('trend')}
              newtag={item?.mapl_now === 1 && t('new')}
              outOfStock={item?.in_stock === 0 && t('sale')}
              recommendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-yellow-500'}
              newtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-red-500'}
              trendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-black'}
              salestagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-red-500'}
              onClick={() => onClickProduct(item.id)}
              addable={item?.in_stock === 0 ? false : true}
              showValidateModal={isShowValidModal}
            />
          );
        })}
        <ValidationModal
          isOpenModal={isValidateModal}
          close={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
          closeModal={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
        />
      </Container>
    </>
  );
};

const Image = tw.div`
flex
items-center
w-[22px]
h-[22px]
`;

const OptionContainer = tw.div`
mb-6
relative
`;

const SliderWrapper = tw.div`
flex
overflow-x-auto
overflow-y-hidden
whitespace-nowrap
scrollbar-none
border-b
border-color112
`;

const SlideList = tw.div`
flex
gap-x-4
`;

const AllItem = tw.div`
${(p) =>
    p.$allProduct
      ? `
border-b-2
border-black
font-semibold`
      : ``}
relative
inline-block
cursor-pointer
py-2
`;
const List = tw.div`
relative
inline-block
cursor-pointer
py-2
`;

const ListElement = tw.p`
${(p) =>
    p.$active
      ? `
after:absolute
after:bg-black
after:w-full
after:h-[5px]
after:top-[96%]
after:right-0
after:px-0
after:z-30
after:overflow-visible
font-semibold`
      : ``}
`;

const TitleContainer = tw.div`
flex
lg:flex-row
flex-col
self-center
justify-between
mb-6
`;

const Sorting = tw.div`
flex
items-center
justify-end
lg:p-0
pt-4
`;
const NxtBtn = styled("button")`
   {
    top: 0;
    right: 0;
    position: absolute;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
    padding: 8px 0;
    background: #fff;
    z-index: 1;
    outline: none;
    border: none;
    &:disabled {
      display: none;
    }
  }
`;

const PrvBtn = styled("button")`
   {
    top: 0;
    left: 0;
    position: absolute;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    padding: 8px 0;
    background: #fff;
    z-index: 1;
    outline: none;
    border: none;
    &:disabled {
      display: none;
    }
  }
`;

export default ProductContent;