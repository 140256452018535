import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import { Toaster } from 'react-hot-toast';
import { getTokens, onMessageListener } from '@services';

const PushNotification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [show, setShow] = useState(false);

  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div>
        <p className='text-red-500'><b>{notification?.title}</b></p>
        <p className='text-black'>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  getTokens();

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
  }).catch(err => console.log('failed: ', err));

  return (
    <Toaster />
  )
}

export default PushNotification