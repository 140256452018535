import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { AiFillCamera } from "react-icons/ai";
import { userApi } from "@services";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "@store";
import { FaUserAlt } from "react-icons/fa";

const ImageUpload = () => {
const [profileImage, setProfileImage] = useState("");
const [data, setData] = useState('')

const [state, setState] = useState({
  isShow : true
})
const {isShow} = state;

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userData);

  const handleChange = (e) => {
    const { target } = e;
    const { files } = target;
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onloadstart = () => setProfileImage({ profileImage: files[0] });
      reader.onload = (e) => {
        setData({ data: e.target.result });
        setState((prev) => ({ ...prev, isShow: false }));
      };
    }
    reader.readAsDataURL(files[0]);
  };

  const uploadImage = () => {
    const formData = new FormData();
    formData.append("image", profileImage?.profileImage);
    formData.append("type", "users");
    userApi.uploadFiles(formData).then((res) => {
      if (res?.status === 200 && res?.data?.data?.location) {
        setState((prev) => ({ ...prev, isShow: true }));
        const data = {
          image: res?.data?.data?.location,
          role: 'user'
        };
        dispatch(updateUserProfile(data)).then((res) => {
          setState((prev) => ({ ...prev, isShow: true }));
          return res;
        });
      }
    });
  };

  return (
    <>
      <UserImage>
        <ImageHolder>
          {userData?.image === "image.jpg" || userData?.image === null ? <FaUserAlt size={50} className="p-1" /> : <img
            src={data?.data || userData?.image}
            alt=""
            className="w-full h-full object-cover"
          />}
        </ImageHolder>
        <CustomButton htmlFor="file-input">
          <AiFillCamera size={13} className={"mx-auto mt-1 cursor-pointer"} />
        </CustomButton>
        <CustomInput
          type="file"
          id="file-input"
          accept="image/*"
          onChange={handleChange}
        />
      </UserImage>
      {!isShow ? <button onClick={uploadImage} className="font-sans font-bold mx-2 animate-pulse">Upload</button> : null}
    </>
  );
};

const UserImage = tw.div`
bg-white
rounded-lg
border
py-[3px]
relative
`;

const ImageHolder = tw.div`
w-[52px]
h-[52px]
`;

const CustomInput = tw.input`
hidden
`;

const CustomButton = tw.label`
border
border-color232
rounded-full
bg-white
w-6
h-6
absolute
-right-2
-bottom-2
`;

export default ImageUpload;
