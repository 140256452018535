import * as types from "./Types";

const initialState = {
  allProductList: [],
  getProductFilter: [],
  addToCartProduct: [],
  getCartListItem: [],
  getWishListItem: [],
  orderItemDetail: [],
  orderDetails: [],
  offerVouchers: [],
  voucher: [],
  voucherAmount: [],
  selectedOffer: {},
  selectedVoucher:{},
  selectedofferType: null,
  productRating: [],
  getNotifyProductList: [],
  productDeliveryFee: [],
  productItemDetail: [],
  productItemRating: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRODUCTS: {
      return {
        ...state,
        allProductList: action?.payload?.data?.data,
      };
    }
    case types.GET_PRODUCT_FILTER: {
      return {
        ...state,
        getProductFilter: action?.payload?.data?.data,
      };
    }
    case types.ADD_CART_PRODUCT: {
      return {
        ...state,
        addToCartProduct: action?.payload?.data?.data,
      };
    }
    case types.GET_CART_LIST: {
      return {
        ...state,
        getCartListItem: action?.payload?.data?.data,
      };
    }
    case types.GET_WISH_LIST: {
      return {
        ...state,
        getWishListItem: action?.payload?.data?.data,
      };
    }
    case types.GET_ORDER_DETAIL: {
      return {
        ...state,
        orderItemDetail: action?.payload?.data?.data,
      }
    }
    case types.GET_ALL_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: action?.payload?.data?.data,
      };
    }
    case types.GET_OFFER_VOUCHERS: {
      return {
        ...state,
        offerVouchers: action?.payload?.data?.data,
      };
    }
    case types.GET_VOUCHERS: {
      return {
        ...state,
        voucher: action?.payload?.data?.data,
        voucherAmount: action?.payload?.data?.voucher_amount,
      };
    }
    case types.GET_OFFER: {
      return {
        ...state,
        selectedOffer: action?.payload?.data?.data,
        selectedofferType: 'offer'
      };
    }
    case types.GET_REVIEW_RATING: {
      return {
        ...state,
        productRating: action?.payload?.data?.data,
      }
    }
    case types.GET_NOTIFY_PRODUCT: {
      return {
        ...state,
        getNotifyProductList: action?.payload?.data?.data,
      }
    }
    case types.PRODUCT_DELIVERY_FEE: {
      return {
        ...state,
        productDeliveryFee: action?.payload?.data?.data
      }
    }
    case types.CLEAR_VOUCHERS: {
      return {
        ...state,
        selectedOffer: {},
        selectedVoucher: {},
        selectedofferType: null
      }
    }
    case types.SET_VOUCHER: {
      return {
        ...state,
        selectedVoucher: action?.payload,
        selectedofferType: 'voucher'
      }
    }
    case types.GET_PRODUCT_DETAIL: {
      return {
        ...state,
        productItemDetail: action?.payload?.data?.data,
        productItemRating: action?.payload?.data?.AvgRating
      }
    }
    default:
      return state;
  }
};

export default reducer;
