import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import { Footer } from "@components";
import { useSelector, useDispatch } from "react-redux";
import { getPrivayPolicy } from "@store";

const Impressum = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `[MAPL - Business] - Impressum`;
    dispatch(getPrivayPolicy("B2B-Impressum"))
  }, []); 

  const privacyPolicy = useSelector((state) => state.profile.privacyPolicy);

  const createMarkup = () => {
    return { __html: privacyPolicy[0]?.body };
  };

  return (
    <>
      <Container>
        <UserContent>
          <LoginContainer>
            <div className="flex flex-col self-center">
              <div dangerouslySetInnerHTML={createMarkup()} />
            </div>
          </LoginContainer>
        </UserContent>
      </Container>
      <Footer className="mx-5"/>
    </>
  );
};

const Container = tw.div`
flex
xl:gap-1
items-center
justify-center
relative
mb-3
`;

const UserContent = tw.div`
lg:w-full
w-full
h-full
relative
px-4
xl:py-3
lg:py-7
py-5
self-center
justify-center
`;

const LoginContainer = tw.div`
flex 
flex-col 
`;

export default Impressum;