import React, { useState, useEffect, useRef } from "react";
import tw from "tailwind-styled-components";
import Box from "@mui/material/Box";
import { HiStar } from "react-icons/hi";
import { Button } from "@components";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import reviewStar from "@assets/images/review-star.png";
import { useDispatch, useSelector } from "react-redux";
import { addReviewRating, getProductRating, getProductDetail } from "@store";
import { toast } from "react-toastify";
import productDefaultImage from "@assets/images/default.png"

const RatingPopup = (props) => {
  const {
    precision = 1,
    totalStars,
    filledIcon = HiStar,
    avgRating,
    disabled,
    isOpenModal,
    closeModal,
    close,
    productName,
    productImg,
    productId,
  } = props;

  const [state, setState] = useState({
    activeStar: 0,
    hoverActiveStar: 0,
    isHovered: false,
    isLoading: false,
    rating: 0,
  });
  const { activeStar, hoverActiveStar, isHovered, isLoading } = state;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      activeStar: avgRating === -1 ? 0 : avgRating.toFixed(1),
    }));
  }, [avgRating]);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.token);
  const productRating = useSelector((state) => state.products.productRating);
  const productDetail = useSelector((state) => state.products.productItemDetail);

  const hidden = !isOpenModal ? "hidden" : "flex";
  const modalStyle = useSpring({
    scale: isOpenModal ? 1 : 0,
    opacity: isOpenModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOpenModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const CrossButton = () => {
    return (
      <div className="flex justify-end w-full">
        <button onClick={closeModal} className="m-2">
          <XIcon className="w-[20px] text-black" />
        </button>
      </div>
    );
  };

  const ratingContainerRef = useRef(null);
  const calculateRating = (e) => {
    const { width, left } = ratingContainerRef.current.getBoundingClientRect();
    let percent = (e.clientX - left) / width;
    const numberInStars = percent * totalStars;
    const nearestNumber =
      Math.round((numberInStars + precision / 2) / precision) * precision;
    return Number(
      nearestNumber.toFixed(precision.toString().split(".")[1]?.length || 0)
    );
  };

  const handleClick = (e) => {
    setState((prev) => ({ ...prev, isHovered: false }));
    setState((prev) => ({ ...prev, activeStar: calculateRating(e) }));
  };

  const handleMouseMove = (e) => {
    setState((prev) => ({ ...prev, isHovered: true }));
    setState((prev) => ({ ...prev, hoverActiveStar: calculateRating(e) }));
  };

  const handleMouseLeave = (e) => {
    setState((prev) => ({ ...prev, hoverActiveStar: 0 }));
    setState((prev) => ({ ...prev, isHovered: false }));
  };

  let allRating = 0;
  productRating?.forEach((rating) => {
    allRating += rating?.totalRating;
    return allRating;
  });

  const FilledIcon = filledIcon;
  const rating5 = productRating?.find((item) => item?.rating === 5);
  const rating4 = productRating?.find((item) => item?.rating === 4);
  const rating3 = productRating?.find((item) => item?.rating === 3);
  const rating2 = productRating?.find((item) => item?.rating === 2);
  const rating1 = productRating?.find((item) => item?.rating === 1);

  const calculateRatingProgress = (rating) => {
    if (rating?.totalRating === undefined) {
      return 0;
    } else {
      return Math.round((rating?.totalRating / allRating) * 100);
    }
  };

  const calculateRatingNum = (rating) => {
    if (rating?.totalRating === undefined) {
      return 0;
    } else {
      return rating?.totalRating;
    }
  }

  const progressData = [
    {
      width: calculateRatingProgress(rating5),
      ratingNum: calculateRatingNum(rating5)
    },
    {
      width: calculateRatingProgress(rating4),
      ratingNum: calculateRatingNum(rating4),
    },
    {
      width: calculateRatingProgress(rating3),
      ratingNum: calculateRatingNum(rating3),
    },
    {
      width: calculateRatingProgress(rating2),
      ratingNum: calculateRatingNum(rating2),
    },
    {
      width: calculateRatingProgress(rating1),
      ratingNum: calculateRatingNum(rating1),
    },
  ];

  const handleReview = () => {
    let payload = {
      params: {
        user_id: userData && userData.id ? userData.id : "",
      },
    };
    const data = {
      product_id: productId,
      rating: activeStar,
    };
    setState((prev) => ({ ...prev, isLoading: true }));
    dispatch(addReviewRating(data))
      .then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(getProductRating())
          dispatch(getProductDetail(productId, payload))
          setState((prev) => ({ ...prev, isLoading: false }));
          close();
          toast.error(res?.payload?.data?.message);
        } else if (res?.payload?.response?.status === 422) {
          setState((prev) => ({ ...prev, isLoading: false }));
          close();
          toast.error(res?.payload?.response?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      });
    // }
  };

  const ProgressReview = (props) => {
    const { width, ratingNum, id } = props;
    return (
      <div className={"flex items-center"} id={id}>
        <ProgressBar>
          <Bar style={{ width: `${width}%` }}></Bar>
        </ProgressBar>
        <p className={"font-roboto font-normal text-[12px] ml-2"}>
          {ratingNum}
        </p>
      </div>
    );
  };

  return (
    <animated.div
      style={modalBackground}
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center bg-white items-center z-[1000] rounded-md`}
      onClick={close}
    >
      <animated.div style={modalStyle} className={"bg-white rounded-md"} onClick={(e) => e.stopPropagation()}>
        <Container>
          <CrossButton />
          <ProductContent>
            <ImageContainer>
              <Img src={productImg ? productImg : productDefaultImage} alt="" />
            </ImageContainer>
            <p className="text-center py-5 text-[20px] font-bold w-full h-full">
              {productName}
            </p>
          </ProductContent>
          <p className="text-[45px] text-black font-black font-roboto">
            {activeStar}
          </p>
          <Box
            sx={{
              display: "inline-flex",
              position: "relative",
              cursor: `${disabled ? "diasbled" : "pointer"}`,
              textAlign: "left",
              gap: "2px",
            }}
            disabled={disabled ? true : false}
            onClick={handleClick}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            ref={ratingContainerRef}
          >
            {[...new Array(totalStars)]?.map((arr, index) => {
              const activeState = isHovered ? hoverActiveStar : activeStar;
              const showEmptyIcon =
                activeState === 0 || activeState < index + 1;
              const isActiveRating = activeState !== 1;
              const isRatingWithPrecision = activeState % 1 !== 0;
              const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
              const showRatingWithPrecision =
                isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;
              return (
                <Box
                  position={"relative"}
                  sx={{
                    cursor: "pointer",
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      width: showRatingWithPrecision
                        ? `${(activeState % 1) * 100}%`
                        : "0%",
                      overflow: "hidden",
                      position: "absolute",
                    }}
                  >
                    <FilledIcon size={38} color={"rgb(242, 187, 6)"} />
                  </Box>
                  <Box
                    sx={{
                      color: showEmptyIcon ? "#D3CED2" : "rgb(242, 187, 6)",
                    }}
                  >
                    {showEmptyIcon ? (
                      <FilledIcon size={38} />
                    ) : (
                      <FilledIcon size={38} />
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {productDetail?.review_rating?.length < 1 &&
            <Button
              onClick={handleReview}
              title={!isLoading ? "Submit" : null}
              loading={isLoading}
              // disabled={!token ? true : false}
              className={
                "mt-5 rounded-md bg-black text-white px-40 py-3 disabled:cursor-not-allowed"
              }
            />
          }
          <div className="flex items-center">
            <div className="w-[90px] h-[90px] mx-4 my-3">
              <Img src={reviewStar} alt="" />
            </div>
            <div className="flex flex-col mx-4 my-3">
              {progressData?.map((rating, index) => (
                <ProgressReview
                  width={rating.width}
                  ratingNum={rating.ratingNum}
                  key={index}
                  id={index}
                />
              ))}
            </div>
          </div>
          <TotalRating>
            <p className="text-[21px] font-medium font-roboto text-color12">
              {`${allRating} Ratings`}
            </p>
          </TotalRating>
        </Container>
      </animated.div>
    </animated.div>
  );
};
const Container = tw.div`
flex
flex-col
items-center
lg:w-[30vw]
h-full
rounded-md
bg-white
`;
const ProgressBar = tw.div`
border-0
bg-color225
rounded-[30px]
w-[16vw]
h-2
`;

const Bar = tw.div`
bg-black
h-full
rounded-[30px]
`;

const TotalRating = tw.div`
flex
items-center
justify-center
cursor-pointer
mb-5
`;

const ProductContent = tw.div`
border-b-2 
flex
items-center 
lg:w-[25vw] 
mx-auto pb-4
rounded-md
`;

const ImageContainer = tw.div`
w-20
h-20
bg-color249
rounded-md
p-2
`;

const Img = tw.img`
w-full
h-full
object-contain
`;

export default RatingPopup;
