import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";
import delivery from "@assets/images/highVoltage.png";
import deliveryCost from "@assets/images/womanTipping.png";
import minOrder from "@assets/images/eyes.png";
import opened from "@assets/images/openedd.png";
import swissGlobe from "@assets/images/swissGlobe.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import crossIcon from "@assets/images/crossmark.png"
import { NotificationModal, LanguageModal } from "./widgets";
import notificationBell from "@assets/images/notifiedBell.png"
import notifiedBell from "@assets/images/notificationBell.png"
import { getUserNotification } from "@store";
import { formatedDateTime, weekDaysName } from "@utils";

const PrimaryNavbar = () => {
  const [state, setState] = useState({
    isLanguageModal: false,
    isNotificationModal: false,
    isOpen: false,
  });
  const { isNotificationModal, isLanguageModal, isOpen } = state;

  const dispatch = useDispatch();
  const storeSchedule = useSelector((state) => state.home.storeSchedule)
  const userNotification = useSelector((state) => state.home.userNotification)

  const handleBellIcon = userNotification?.some((item) => item.is_read === 1)

  useEffect(() => {
    handleStoreSchedule();
  }, [storeSchedule])

  const handleStoreSchedule = () => {
    let currentDay = new Date();
    const currentTime = formatedDateTime(currentDay, 'HH:mm:ss')
    currentDay = currentDay.getDay()
    currentDay = weekDaysName[currentDay]
    const format = 'HH:mm:ss'
    const time = moment(currentTime, format)
    let flag = 0;
    storeSchedule?.map((item) => {
      if (item?.day == currentDay && item?.status == 'active') {
        const beforeTime = moment(item.open_time, format)
        const afterTime = moment(item.close_time, format)
        if (time.isBetween(beforeTime, afterTime)) {
          flag = 1
        }
      }
    })
    if (!flag) {
      setState((prev) => ({ ...prev, isOpen: isOpen }))
    } else {
      setState((prev) => ({ ...prev, isOpen: !isOpen }))
    }
  }

  const gotoMaplCustomer = () => {
    window.location.replace("https://web.mapl.ch/");
  };

  const { t } = useTranslation();

  const data = [
    { title: t("header.contact"), link: "#contact" },
    { title: t("header.help"), link: "#help" },
    { title: t("header.about"), link: "#about" },
  ];

  const openLanguageModal = () => {
    setState((prev) => ({ ...prev, isLanguageModal: !isLanguageModal }))
  }

  const openNotificationModal = () => {
    dispatch(getUserNotification())
    setState((prev) => ({ ...prev, isNotificationModal: !isNotificationModal }))
  }

  return (
    <>
      <NavContainer>
          <NavLeft>
            {data?.map((item, index) => {
              return (
                <PrimaryText
                  style={{ fontFamily: "Raleway, sans-serif" }}
                  className="lg:text-[12px] md:text-[11px] text-[10px] pr-6 "
                  key={index}
                >
                  <a href={`${item.link}`}>{item.title}</a>
                </PrimaryText>
              );
            })}
            <div onClick={gotoMaplCustomer}>
              <PrimaryText
                style={{ fontFamily: "Raleway, sans-serif" }}
                className="lg:text-[12px] md:text-[11px] text-[10px] pr-6 cursor-pointer"
              >
                {t("header.private_customer")}
              </PrimaryText>
            </div>
          </NavLeft>
          <div className="flex items-center">
              <img
                className={
                  "max-w-[22px] md:max-w-[16px] lg:flex md:flex"
                }
                src={delivery}
                alt=""
              />
              <Text>We deliver in minutes</Text>
            <div className="flex xs:mx-1 sm:mx-0">
              <img
                className={
                  "max-w-[22px] md:max-w-[16px] lg:flex md:flex"
                }
                src={minOrder}
                alt=""
              />
              <Text className="items-center">No minimum order</Text>
            </div>
            <div className="xs:hidden md:flex">
              <img
                className={
                  "max-w-[22px] md:max-w-[16px] lg:flex"
                }
                src={deliveryCost}
                alt=""
              />
              <Text>Delivery Charges only CHF 3.90</Text>
            </div>
          </div>
          <NavRight>
            <BaiscText className="">
              {"Mon-Thurs: 08-23; Fri: 08-24; Sat: 08-23:45"}
            </BaiscText>
            <WebTime>
                <img
                  src={isOpen === true ? opened : crossIcon}
                  alt=""
                  className="xl:max-w-[18px] lg:max-w-4 sm:max-w-[14px] max-w-[10%] h-auto"
                />
                {isOpen === true ? <Basic>{t("header.now_open")}</Basic> : <Basic>{t("header.now_close")}</Basic>}
            </WebTime>
            {handleBellIcon ?
              <img alt="" src={notificationBell}
                className="w-[16px] cursor-pointer flex xs:hidden md:flex"
                onClick={openNotificationModal}
              /> :
              <img alt="" src={notifiedBell}
                className="w-[16px] cursor-pointer flex xs:hidden md:flex"
                onClick={openNotificationModal}
              />
            } 
            <img
              src={swissGlobe}
              alt=""
              onClick={openLanguageModal}
              className="2xl:max-w-[5%] xl:max-w-[11%] lg:max-w-[10%] md:max-w-[12.45%] sm:max-w-[4%] max-w-[7%] cursor-pointer xs:hidden md:flex"
            />
          </NavRight>
      </NavContainer>
      <LanguageModal
        isOpenModal={isLanguageModal}
        closeModal={() => isLanguageModal && setState((prev) => ({ ...prev, isLanguageModal: false }))}
      />
      <NotificationModal
        isOpenModal={isNotificationModal}
        closeModal={() => isNotificationModal && setState((prev) => ({ ...prev, isNotificationModal: false }))}
      />
    </>
  );
};

const NavContainer = tw.div`
p-2
bg-black
text-white
w-full
flex
items-center
justify-between
`;

const NavLeft = tw.div`
2xl:flex
2xl:min-w-fit
items-center
hidden
`;

const Text = tw.span`
lg:text-[12px]
md:text-[11px]
text-[10px]
pl-1
xl:mr-8
md:mr-3
min-w-max
flex
text-yellowDark
`;

const NavRight = tw.div`
w-full
sm:w-full
md:w-auto
lg:min-w-auto
xs:flex-end
md:flex
items-center
justify-between
`;

const WebTime = tw.div`
flex
items-center
justify-end
`;

const BaiscText = tw.span`
lg:text-[12px]
md:text-[11px]
text-[10px]
xl:ml-[-6%]
pr-1
2xl:flex
hidden
`;

const Basic = tw.span`
lg:text-[12px]
md:text-[11px]
sm:text-[10px]
text-[10px]
pl-2
flex
`;

export default PrimaryNavbar;