import React, { useState, useEffect, useRef } from "react";
import tw from "tailwind-styled-components";
import Box from "@mui/material/Box";
import { HiStar } from "react-icons/hi";
import { Basic } from "@components";
import RatingPopup from "./RatingPopup";
import { useDispatch } from "react-redux";
import { getProductRating } from "@store";

const Ratings = (props) => {
  const {
    totalStars,
    label,
    filledIcon = HiStar,
    valueRating,
    disabled,
    productName,
    productImg,
    productId,
  } = props;

  const [state, setState] = useState({
    activeStar: null,
    openModal: false,
  });
  const { activeStar, openModal } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      activeStar: valueRating === null ? 0 : valueRating?.toFixed(1),
    }));
  }, [valueRating]);

  const handleRating = () => {
    dispatch(getProductRating(productId))
    setState((prev) => ({ ...prev, openModal: true }))
  };

  const ratingContainerRef = useRef(null);
  const FilledIcon = filledIcon;

  return (
    <Container>
      <Box
        sx={{
          display: "inline-flex",
          position: "relative",
          cursor: `${disabled ? "diasbled" : "pointer"}`,
          textAlign: "left",
          gap: "2px",
        }}
        disabled={disabled ? true : false}
        ref={ratingContainerRef}
      >
        {[...new Array(totalStars)]?.map((arr, index) => {
          const activeState = activeStar;
          const showEmptyIcon = activeState === 0 || activeState < index + 1;
          const isActiveRating = activeState !== 1;
          const isRatingWithPrecision = activeState % 1 !== 0;
          const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
          const showRatingWithPrecision =
            isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;
          return (
            <Box
              key={index}
              position={"relative"}
              sx={{
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: showRatingWithPrecision
                    ? `${(activeState % 1) * 100}%`
                    : "0%",
                  overflow: "hidden",
                  position: "absolute",
                }}
              >
                <FilledIcon size={20} />
              </Box>
              <Box
                sx={{
                  color: showEmptyIcon ? "#D3CED2" : "black",
                }}
              >
                {showEmptyIcon ? (
                  <FilledIcon size={20} />
                ) : (
                  <FilledIcon size={20} />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {label && (
        <button onClick={handleRating} className={"ml-2 underline"}>
          <Basic>{label}</Basic>
        </button>
      )}
      <RatingPopup
        isOpenModal={openModal}
        closeModal={() => openModal && setState((prev) => ({ ...prev, openModal: false }))}
        close={() => openModal && setState((prev) => ({ ...prev, openModal: false }))}
        totalStars={totalStars}
        avgRating={valueRating}
        productName={productName}
        productImg={productImg}
        productId={productId}
      />
    </Container>
  );
};

const Container = tw.div`
flex
items-center
`;

export default Ratings;
