import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  PrimaryText,
  AuthContainer,
  AuthHeader,
} from "@components";
import logo from "@assets/images/mapl1.png";
import switzerlandLogo from "@assets/images/flag-switzerland.png";
import theme from "@theme";
import { en } from "@i18n";
import {
  isEmpty,
  isValidEmail,
  isValidNumber,
  isValidPostcode,
} from "@utils";
import { toast } from "react-toastify";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { userApi, getTokens } from "@services";
import { ForgotPasswordModal } from "./widgets";
import { useDispatch } from "react-redux";
import {
  setToken,
  userProfile,
  getCartList,
  getWishList,
  getProducts,
  getNotifyProduct,
  uploadDeviceToken
} from "@store"

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    phone_num: "",
    code: "",
    isLoading: false,
    signInWithMobile: false,
    showPassword: false,
    successOtp: false,
    isEmailVerificationModal: false,
  });
  const {
    email,
    password,
    phone_num,
    code,
    isLoading,
    signInWithMobile,
    showPassword,
    successOtp,
    isEmailVerificationModal,
  } = state;

  useEffect(() => {
    document.title = `[MAPL - Business] - Login`;
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dispatchItems = () => {
    Promise.all([
      dispatch(userProfile()),
      dispatch(getCartList()),
      dispatch(getWishList()),
      dispatch(getProducts()),
      dispatch(getNotifyProduct())
    ])
  }

  const getPushNotification = () => {
    getTokens((isTokenFound, token) => {
      if (isTokenFound) {
        let data = {
          type: 'web',
          token: token,
          status: 'active'
        };
        dispatch(uploadDeviceToken(data))
      }
    })
  }

  const onHandleClick = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onHandleChange = (event) => {
    isNaN(event?.target?.value) || event?.target?.value?.length == 0
      ? setState((prev) => ({
        ...prev,
        signInWithMobile: false,
        email: event?.target?.value,
      }))
      : handleMobileNumber(event?.target?.value)
  };

  const handleMobileNumber = (num) => {
    let newNum = 0
    let countryCode = "+41"
    if (!phone_num?.includes(countryCode) && num != '+') {
      newNum = countryCode + num
      setState((prev) => ({ ...prev, phone_num: newNum, signInWithMobile: true }))
    }
    else {
      setState((prev) => ({ ...prev, phone_num: num, signInWithMobile: true }))
    }
  }

  const loginWithEmail = () => {
    if (isEmpty(email) || isEmpty(password)) {
      toast.error(en.errors.empty_credential);
    } else if (email && !isValidEmail(email?.trim())) {
      toast.error(en.errors.emailValidate);
    } else if (password?.trim().length < 8) {
      toast.error(en.errors.minimumPasswordValue);
    } else {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = {
        email: email,
        password: password,
        role: "business_user",
      };
      userApi.login(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          setState((prev) => ({ ...prev, isLoading: false }));
          dispatch(setToken(res?.data?.data?.accessToken))
          navigate("/dashboard", { replace: true });
          dispatchItems();
          getPushNotification();
          toast.success(en.success.success);
        } else {
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.error(res?.response?.data?.message);
        }
      });
    }
  };

  const getOtpValue = (data) => {
    userApi.getOtp(data).then((res) => {
      if (res && res?.data && res?.status === 200) {
        toast.success(res?.data?.message);
        setState((prev) => ({ ...prev, successOtp: true }));
      }
    });
  };

  const sendOtpOnMobileNumber = () => {
    if (phone_num && !isValidNumber(phone_num?.trim())) {
      toast.error(en.errors.validPhoneNum);
    } else if (phone_num?.trim().length < 10) {
      toast.error(en.errors.minimum_phoneNum);
    } else if (code && !isValidPostcode(code?.trim())) {
      toast.error(en.errors.validCode);
    } else {
      const data = {
        phone: phone_num,
        role: "business_user",
      };
      getOtpValue(data);
    }
  };

  const loginWithPhoneNumber = () => {
    if (isEmpty(phone_num) || isEmpty(code)) {
      toast.error(en.errors.empty_credential);
    } else {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = {
        phone: phone_num,
        otp: code,
        role: "business_user",
      };
      userApi.authLogin(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          setState((prev) => ({ ...prev, isLoading: false }));
          dispatch(setToken(res?.data?.data?.accessToken))
          navigate("/dashboard", { replace: true });
          dispatchItems();
          getPushNotification();
          toast.success(en.success.success);
        } else {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isLoading: false }));
        }
      });
    }
  };

  const handleClickShowPassword = () => {
    setState((prev) => ({ ...prev, showPassword: !showPassword }));
  };

  const clickHandle = () => {
    setState((prev) => ({ ...prev, isEmailVerificationModal: true }));
  };

  let isValidateEmail = email === "" ? true : isValidEmail(email);
  let isValidateMobileNumber =
    phone_num === "" ? true : isValidNumber(phone_num);

  return (
    <AuthContainer>
      <LoginContainer>
        <AppLogo src={logo} />
        <AuthHeader />
        <LoginContent>
          <div className="py-[25px]">
            <Input
              name={signInWithMobile ? "phone_num" : "email"}
              value={signInWithMobile ? phone_num : email}
              type={"text"}
              title={"E-mail or Mobile Number"}
              placeholder={
                signInWithMobile ? "+41 79 774 53 76" : "kunde@mapl.ch"
              }
              verifybuttontitle={
                signInWithMobile
                  ? phone_num && successOtp
                    ? "Sent"
                    : phone_num
                      ? "Send OTP"
                      : null
                  : null
              }
              verifybuttonclassname={`${phone_num && successOtp
                ? "text-black"
                : phone_num
                  ? "text-blue underline"
                  : null
                }`}
              verifybuttonclick={phone_num && sendOtpOnMobileNumber}
              verifybuttondisabled={phone_num?.length < 13 ? true : false}
              inputicon={signInWithMobile && switzerlandLogo}
              onChange={onHandleChange}
              maxLength={signInWithMobile ? 14 : ""}
              required
              errortext={
                signInWithMobile && !isValidateMobileNumber ? "Please enter valid mobile number" : null || !signInWithMobile && !isValidateEmail ? "Please enter valid email" : null}
            />
            <Input
              name={signInWithMobile ? "code" : "password"}
              value={signInWithMobile ? code : password}
              type={
                signInWithMobile ? null : showPassword ? "text" : "password"
              }
              title={signInWithMobile ? "Enter code" : "Password"}
              placeholder={signInWithMobile ? "Code" : "Password"}
              onChange={onHandleClick}
              maxLength={signInWithMobile ? 4 : ""}
              hideandshoweyeicon={
                signInWithMobile ? null : showPassword ? (
                  <EyeIcon className="w-3 h-3" />
                ) : (
                  <EyeOffIcon className="w-3 h-3" />
                )
              }
              hideandshowpasswordonclick={handleClickShowPassword}
              required
            // onKeyPress={loginWithEmail}
            />
          </div>
          <Button
            title={!isLoading ? "SIGN IN" : null}
            titleStyle={{ color: theme.colors.white }}
            onClick={signInWithMobile ? loginWithPhoneNumber : loginWithEmail}
            className={"self-center bg-black mt-[-10px] w-full"}
            loading={loginWithEmail ? isLoading : null}
          />
          <div className="flex flex-col text-center">
            <div className="py-[16px]">
              {!signInWithMobile ? (
                <div
                  className="cursor-pointer max-w-fit flex mx-auto"
                  onClick={clickHandle}
                >
                  <PrimaryText className="underline font-sans font-semibold text-[11px]">
                    {"I need help with my password!"}
                  </PrimaryText>
                </div>
              ) : (
                <div className="flex flex-row justify-center">
                  <PrimaryText className="font-sans font-normal text-color172 text-[11px]">
                    {"Didn’t receive the Code?"}
                  </PrimaryText>
                  <TextButton onClick={sendOtpOnMobileNumber}>
                    {"Resend"}
                  </TextButton>
                </div>
              )}
            </div>
          </div>
        </LoginContent>
      </LoginContainer>
      <ForgotPasswordModal
        isOpenModal={isEmailVerificationModal}
        closeModal={() =>
          isEmailVerificationModal &&
          setState((prev) => ({ ...prev, isEmailVerificationModal: false }))
        }
      />
    </AuthContainer>
  );
};

const LoginContainer = tw.div`
flex 
flex-col 
border
border-borderColor
rounded-[5px]
relative 
bg-white
lg:w-[480px]
md:w-[440px]
sm:w-[400px]
`;

const LoginContent = tw.div`
flex
flex-col
justify-between 
overflow-scroll 
mx-[25px] 
scrollbar-none
`;

const AppLogo = tw.img`
self-center
absolute
top-[-17px]
h-[34px]
lg:w-[110px]
lg:h-[34px]
sm:w-[90px]
sm:h-[28px]
`;

const TextButton = tw.button`
font-sans
text-[11px]
px-1
underline
`;

export default Login;