import tw from "tailwind-styled-components";
import React from "react";
import { SecondaryText } from "@components";

const Button = (props) => {
  const {
    className,
    title,
    titleStyle,
    titleclassname,
    onClick,
    disabled,
    style,
    loading,
    icon,
    iconStyle,
    iconclassname,
  } = props;

  const checkOpacity = disabled ? 0.5 : 1

  const styles = {
    ...style,
    opacity: checkOpacity
  }

  return (
    <CustomButton style={styles} onClick={onClick} className={`${className}`} disabled={disabled ? true : false}>
      {icon && <Img src={icon} style={{ ...iconStyle }} className={iconclassname}/>}
      {loading && <div className="w-4 h-4 border-4 border-white border-t-logoColor rounded-full animate-spin" />}
      <SecondaryText style={{ ...titleStyle }} className={`${titleclassname} font-semibold text-[12px]`}>{title}</SecondaryText>
    </CustomButton>
  )
}

const CustomButton = tw.button`
flex
w-[89%]
h-11
p-4
pl-4
justify-center
items-center
self-center
transition
delay-75
hover:shadow-lg
group
focus:outline-none
`;

const Img = tw.img`
flex
w-[28px]
h-full
`;

export default Button;
