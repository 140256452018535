import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Button, Input } from "@components";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import theme from "@theme";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";

const ConfirmPasswordModal = (props) => {
  const {
    isOpenModal,
    closeModal,
    onClick,
    newPasswordValue,
    confirmPasswordValue,
    onChangeNewPassword,
    onChangeConfirmPassword,
  } = props;

  const [state, setState] = useState({
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const { showNewPassword, showConfirmPassword } = state;

  const hidden = !isOpenModal ? "hidden" : "flex";

  const modalStyle = useSpring({
    scale: isOpenModal ? 1 : 0,
    opacity: isOpenModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOpenModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const CrossButton = () => {
    return (
      <div className="flex justify-end">
        <button onClick={closeModal} className="m-3">
          <XIcon className="w-[20px]" />
        </button>
      </div>
    );
  };

  const handleClickNewPasswordShow = () => {
    setState((prev) => ({ ...prev, showNewPassword: !showNewPassword }));
  };

  const handleClickConfirmPasswordShow = () => {
    setState((prev) => ({
      ...prev,
      showConfirmPassword: !showConfirmPassword,
    }));
  };

  return (
    <animated.div
      style={modalBackground}
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center bg-white items-center z-[1000]`}
    >
      <animated.div style={modalStyle}>
        <Container>
          <CrossButton />
          <Content>
            <Input
              value={newPasswordValue}
              title={"New Password"}
              type={showNewPassword ? "text" : "password"}
              placeholder={"Password"}
              onChange={onChangeNewPassword}
              hideandshoweyeicon={
                showNewPassword ? (
                  <EyeIcon className="w-3 h-3" />
                ) : (
                  <EyeOffIcon className="w-3 h-3" />
                )
              }
              hideandshowpasswordonclick={handleClickNewPasswordShow}
            />
            <Input
              value={confirmPasswordValue}
              title={"Confirm Password"}
              type={showConfirmPassword ? "text" : "password"}
              placeholder={"Confirm Password"}
              onChange={onChangeConfirmPassword}
              hideandshoweyeicon={
                showConfirmPassword ? (
                  <EyeIcon className="w-3 h-3" />
                ) : (
                  <EyeOffIcon className="w-3 h-3" />
                )
              }
              hideandshowpasswordonclick={handleClickConfirmPasswordShow}
            />
            <Button
              title={"SAVE"}
              titleStyle={{
                color: theme.colors.white,
                flex: 1,
                alignSelf: "center",
              }}
              className="bg-black w-[100%] sm:mr-[20px] mx-auto my-3 justify-center font-semibold flex rounded-sm"
              onClick={onClick}
            />
          </Content>
        </Container>
      </animated.div>
    </animated.div>
  );
};

const Container = tw.div`
bg-white
mx-auto
w-[90%]
h-[75%]
lg:w-[480px]
md:w-[440px]
sm:w-[400px]
lg:h-[300px]
md:h-[290px]
sm:h-[280px]
rounded-sm
`;

const Content = tw.div`
px-10 
relative 
h-full
mx-auto
flex-col
flex
`;

export default ConfirmPasswordModal;