import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SideBar,
  Footer,
  ValidationModal,
  AppLoader,
} from "@components";
import {
  getProductDetail,
  addAndRemoveWishList,
  getWishList,
  addToCartProduct,
  getCartList,
  notification,
  getNotifyProduct,
} from "@store";
import { useDispatch, useSelector } from "react-redux";
import ProductInfo from "./ProductInfo";
import moment from "moment";
import ProductRecommend from "./ProductRecommend";
import { toast } from "react-toastify";
import { scrollToTopWindow } from "@utils"

const ProductDetail = () => {
  const [state, setState] = useState({});
  const { isLoading, isValidateModal, moreProducts, recommendedProduct } = state;

  const productId = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productItemDetail = useSelector((state) => state.products.productItemDetail);
  const userData = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.token);
  const recommendationProduct = useSelector((state) => state.home.recommendationProduct);
  const allProductList = useSelector((state) => state.products.allProductList);
  const productItemRating = useSelector((state) => state.products.productItemRating);

  useEffect(() => {
    document.title = `[MAPL] - Product - Detail`;
    productDetail(true);
    additionalProducts();
  }, [productId]);

  const productDetail = (loading) => {
    let payload = {
      params: {
        user_id: userData && userData.id ? userData.id : "",
      },
    };
    setState((prev) => ({ ...prev, isLoading: loading ? true : false }))
    dispatch(getProductDetail(productId?.state, payload)).then((res) => {
      if (res?.payload?.data?.data?.status == 'active') {
        setState((prev) => ({ ...prev, isLoading: false }))
      } else {
        navigate(-1)
        setTimeout(() => {
          toast.error('This product is currently not available in our store.')
        }, 500)
      }
    })
  }

  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const handleWishList = (product_id) => {
    isShowValidModal();
    dispatch(addAndRemoveWishList(product_id))
      .then((res) => {
        if (res?.payload?.data) {
          productDetail();
          dispatch(getWishList());
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const addToCart = (id, cart_id) => {
    isShowValidModal();
    const data = {
      product_id: id,
      quantity: 1,
    };
    dispatch(addToCartProduct(data))
      .then((res) => {
        if (res?.payload?.data) {
          productDetail();
          dispatch(getCartList());
          toast.success(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getNotification = (id) => {
    isShowValidModal();
    dispatch(notification(id))
    dispatch(getNotifyProduct());
    productDetail();
  };

  const additionalProducts = () => {
    const filterMoreProductList = allProductList?.filter(
      (item) => item?.id !== productId?.state
    );
    const filterRecommendationProductList = recommendationProduct?.filter(
      (item) => item?.id !== productId?.state
    );
    setState((prev) => ({ ...prev, moreProducts: filterMoreProductList, recommendedProduct: filterRecommendationProductList }))
  }

  const handleAddPackInCart = (product) => {
    const id = product.id
    navigate(`/product/${id}`, { state: id });
  };

  const showProductDetail = (id) => {
    scrollToTopWindow();
    navigate(`/product/${id}`, { state: id });
  }

  return (
    <>
      <div className="flex">
        <ProductMenu>
          <SideBar />
        </ProductMenu>
        <ProductDes>
          {isLoading ? (
            <AppLoader
              loading={isLoading}
              childClassName={
                "w-12 h-12 my-28 border-4 border-white border-t-logoColor rounded-full animate-spin justify-center"
              }
            />
          ) : (
            <ProductInfo
              productId={productId?.state}
              productImg={productItemDetail?.image && productItemDetail?.image[0]?.image}
              productName={
                `${productItemDetail?.name?.toString()?.charAt(0)?.toUpperCase()}${productItemDetail?.name?.toString()?.slice(1)}`
              }
              inStock={productItemDetail?.in_stock}
              rating={productItemRating}
              validateRatingModal={isShowValidModal}
              productTitle={productItemDetail?.description && productItemDetail?.description}
              productPrice={productItemDetail?.price ? `CHF ${productItemDetail?.price}` : `CHF ${0}`}
              privatePrice={
                productItemDetail?.price ? `CHF ${productItemDetail?.price}` : `CHF ${0}`
              }
              gastronomyPrice={
                productItemDetail?.actual_price
                  ? `CHF ${productItemDetail?.actual_price}`
                  : `CHF ${0}`
              }
              showTempIcon={productItemDetail?.temperature ? true : false}
              tempIconTitle={`${productItemDetail?.temperature} F`}
              expiryDate={productItemDetail?.expire_date ? moment(productItemDetail?.expire_date).format("MM/DD/YYYY") : "N/A"}
              packSystem={productItemDetail?.pack?.b2b_product}
              packs_click={handleAddPackInCart}
              inStockContainer={productItemDetail?.in_stock === 0 && true}
              addToWishListProduct={() => handleWishList(productId?.state)}
              addToCartItem={
                productItemDetail?.in_stock === 0
                  ? () => getNotification(productId?.state)
                  : () => addToCart(productId?.state)
              }
            />
          )}
          <ProductRecommend
            extraInfo={productItemDetail?.extra_info}
            nutritionalValues={productItemDetail?.nutritional_values}
            description={productItemDetail?.description}
            brandName={productItemDetail?.brand_name}
            moreProductsList={moreProducts}
            recommendedProductList={recommendedProduct}
            showProductDetail={showProductDetail}
          />
          <Footer className="mx-5" />
        </ProductDes>
      </div>
      <ValidationModal
        isOpenModal={isValidateModal}
        close={() =>
          isValidateModal &&
          setState((prev) => ({ ...prev, isValidateModal: false }))
        }
        closeModal={() =>
          isValidateModal &&
          setState((prev) => ({ ...prev, isValidateModal: false }))
        }
      />
    </>
  );
};

const ProductMenu = tw.div`
xl:w-1/6 
h-full
sticky top-[2rem]
flex flex-col
z-30
`;

const ProductDes = tw.div`
xl:w-5/6 
w-full
h-full 
self-end
`;

export default ProductDetail;