import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { HeadingText, SecondaryText, AuthContainer } from "@components";
import logo from "@assets/images/mapl1.png";
import checkImage from "@assets/images/checkImg.png";
import { toast } from "react-toastify";
import { en } from "@i18n";
import { uploadDeviceToken } from "@store";
import { useDispatch } from "react-redux";
import { getTokens, userApi } from '@services';

const ConfirmBusinessRegistration = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `[MAPL - Business] - Welcome To MAPL Online Grocery Store`;
    confirmation();
  }, []);


  const getPushNotification = () => {
    getTokens((isTokenFound, token) => {
      if (isTokenFound) {
        let data = {
          type: 'web',
          token: token,
          status: 'active'
        };
        dispatch(uploadDeviceToken(data))
      }
    })
  }

  const confirmation = () => {
    setTimeout(() => {
      const data = {
        email: location?.state?.email,
        password: location?.state?.password,
        role: "business_user",
      }
      userApi.login(data).then((res) => {
        if (res && res?.data && res?.status === 200) {
          navigate("/dashboard");
          getPushNotification();
          toast.success(en.success.success);
        }
      })
    }, 6000);
  }

  return (
    <AuthContainer>
      <LoginContainer>
        <AppLogo src={logo} />
        <LoginContent>
          <div className="flex flex-col self-center text-center">
            <CheckImage src={checkImage} />
            <HeadingText className="my-6">{"THANK YOU!"}</HeadingText>
            <SecondaryText className="text-[11px] text-center px-20 font-semibold leading-4">
              {
                "We appreciate your trust on us and we look forward to a successful business relationship with you. Please be patient while we have checked your information. We will contact you with a confirmation email."
              }
            </SecondaryText>
            <SecondaryText className="text-[11px] mt-6 text-center px-16 font-bold">
              {"ESTIMATED PROCESSING TIME 1-2 DAYS"}
            </SecondaryText>
          </div>
        </LoginContent>
      </LoginContainer>
    </AuthContainer>
  );
};

const LoginContainer = tw.div` 
flex 
flex-col 
border
border-borderColor
rounded-[5px]
relative 
bg-white
w-[90%]
lg:w-[480px]
md:w-[440px]
sm:w-[400px]
`;

const AppLogo = tw.img`
self-center
absolute
top-[-17px]
lg:h-[34px]
lg:w-[110px]
h-[34px]
w-[100]
cursor-pointer
`;

const CheckImage = tw.img`
pt-5  
h-[205.8px]
self-center
`;

const LoginContent = tw.div`
flex
flex-col
my-[5%]
justify-around
`;

export default ConfirmBusinessRegistration;
