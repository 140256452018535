import React from "react";
import tw from "tailwind-styled-components";
import { TertiaryBoldText } from "@components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import productDefaultImage from "@assets/images/default.png"

const FilteredSearch = ({ closeModal }) => {
  const navigate = useNavigate();

  const allProductList = useSelector((state) => state.products.allProductList);

  const clickListItem = (id) => {
    closeModal();
    navigate(`/product/${id}`, { state: id });
  };

  return (
    <SearchContainer onClick={(e) => e.stopPropagation()}>
      <div className="p-6 pt-0">
        <TertiaryBoldText className="xl:text-[25px]">
          Filtered Search
        </TertiaryBoldText>
        {allProductList?.map((item, index) => (
          <div className="flex w-full" key={index}>
            <div className="flex w-1/3 border-r border-color207 flex-wrap">
              <SearchListText>{`${item?.category?.name} | ${item?.brand_name}`}</SearchListText>
            </div>
            <div className="items-center flex w-2/3 flex-wrap">
              <div
                key={index}
                className="w-full hover:bg-color239 pl-5 py-2"
                onClick={() => clickListItem(item?.id)}
              >
                <div className="h-[10%] w-[10%] bg-color249 float-left mr-4">
                  {item?.image[0]?.image ? <ItemImage src={item?.image[0]?.image} /> : <ItemImage src={productDefaultImage} className="max-h-10" />}
                </div>
                <div className="flex flex-col">
                  <div className="">
                    <SearchListText>{item?.name}</SearchListText>
                  </div>
                  <SearchListText className="">{`CHF ${item?.price}`}</SearchListText>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SearchContainer>
  );
};

const SearchContainer = tw.div`
bg-white  
lg:w-[60%] 
sm:w-[70%]
h-[450px]
overflow-scroll
top-auto 
scrollbar-none
4xl:ml-[20.6%] 
xl:ml-[25.6%] 
ml-[20.5%] 
`;

const SearchListText = tw.button`
leading-tight
tracking-wide
truncate
text-left
rounded-sm
bg-transparent
`;

const ItemImage = tw.img`
mr-4
w-full
h-full
object-contain
`;

export default FilteredSearch;