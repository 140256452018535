import * as types from "./Types";
import { get, set } from "@utils"

const initialState = {
  token: get('businessToken'),
  email: "",
  userData: {},
  userAddress: [],
  invoiceDetail: [],
  // paymentTransactionId: [],
  isInvoiceAvailable: {
    business: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOKEN:
      set("businessToken", action?.payload);
      return {
        ...state,
        token: action?.payload
      };
    case types.Login:
      return {
        ...state,
      };
    case types.USER_PROFILE:
      return {
        ...state,
        userData: action?.payload?.data?.data,
      }
    case types.GET_USER_ADDRESS:
      return {
        ...state,
        userAddress: action?.payload?.data?.data,
      };
    case types.GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: action?.payload?.data?.data,
        isInvoiceAvailable: {
          business: action?.payload?.data?.data?.some((item) => item?.type == "business")
        }
      }
    // case types.CREATE_PAYMENT_TRANSACTION_ID:
    //   return {
    //     ...state,
    //     paymentTransactionId: action?.payload?.data?.data?.transactionId
    //   }
    default:
      return state;
    //   case types.Login: {
    //     if (
    //       action.payload &&
    //       action.payload.data &&
    //       action.payload.status === 200
    //     ) {
    //       let user = action.payload.data;
    //       console.log('user data' ,  user);
    //       return {
    //         ...state,
    //         // token: user.token,
    //       };
    //     } else {
    //       return { ...state };
    //     }
    //   }
    // }
  }
};

export default reducer;