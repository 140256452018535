import React from "react";
import tw from "tailwind-styled-components";
import { Grid, Dna } from 'react-loader-spinner'

const AppLoader = ({ loading, parentClassName, childClassName }) => {
  return (
    <div className={`${parentClassName} flex justify-center items-center`}>
      {loading && (
        <div className={`${childClassName} border-4 border-white border-t-logoColor rounded-full animate-spin`} />
      )}
    </div>
  );
};

const Loader = ({ text, className }) => {
  return (
    <Container>
      <Grid
        height="50"
        width="50"
        color="rgb(255,0,0)"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Container>
  );
};

const DnaLoader = () => {
  return (
    <Container>
      <Dna
        visible={true}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </Container>
  )
}

const Skeleton = () => {
  return (
    <div role="status" className="space-y-72 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center mx-10 self-center">
      <div className="w-full">
        <div className="h-2.5 rounded-full bg-slate-200 w-48 mb-4"></div>
        <div className="h-2 rounded-full bg-slate-200 max-w-[480px] mb-2.5"></div>
        <div className="h-2 rounded-full bg-slate-200 mb-2.5"></div>
        <div className="h-2 rounded-full bg-slate-200 max-w-[440px] mb-2.5"></div>
      </div>
    </div>
  );
};

const Container = tw.div`
w-full 
h-full 
flex 
justify-center 
items-center
font-bold
text-black/30
text-2xl
`;

const AppsLoader = tw.div`
border-4 
border-white 
border-t-logoColor 
rounded-full 
animate-spin
w-20
h-20
`;

export { AppLoader, Loader, DnaLoader, Skeleton };