import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import tw from "tailwind-styled-components";
import countryFlag from "@assets/images/countryFlag.png";
import tempIcon from "@assets/images/tempIcon.png";
import {
  notification,
  getNotifyProduct
} from "@store";
import { useDispatch, useSelector } from "react-redux";
import productDefaultImage from "@assets/images/default.png"
import bellIcon from "@assets/images/bell.png";
import ringBell from "@assets/images/ringBell.png"
import { scrollToTopWindow } from "@utils";

const Card = (props) => {
  const {
    productItem,
    weight,
    productRate,
    trendtag,
    recommendtag,
    newtag,
    recommendtagColor,
    newtagColor,
    trendtagColor,
    salestagColor,
    outOfStock,
    salestag,
    id,
    addable,
    onClick,
    showValidateModal,
    ...rest
  } = props;

  // const dispatch = useDispatch();

  const getNotifyProductList = useSelector((state) => state.products.getNotifyProductList)
  const token = useSelector((state) => state.user.token)

  const handleError = (e) => {
    e.target.src = "https://cdn-icons-png.flaticon.com/512/2203/2203206.png";
  };

  const cardIcon = [
    { img: countryFlag, size: "max-w-[12%]" },
    { img: productItem?.temperature || productItem?.b2b_product?.temperature && tempIcon, size: "max-w-[6%]" },
  ];

  // const notifyProduct = (id) => {
  //   dispatch(notification(id))
  //     .then((res) => {
  //       if (res) {
  //         dispatch(getNotifyProduct())
  //       }
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // };

  function gotoProductDetail() {
    onClick()
    scrollToTopWindow();
  }

  return (
    <CardContainer {...rest}>
      <ButtonWrapper onClick={token ? gotoProductDetail : showValidateModal}>
        <AddButton>
          {productItem?.in_stock > 0 ? <AiOutlinePlus size={22} className="mr-1" /> :
            <img className="w-4 mr-1" alt="" src={getNotifyProductList?.some(
              (cart) => cart?.product_id === productItem?.id) ? ringBell : bellIcon} />
          }
          <Title>{productItem?.in_stock > 0 ? "Add" : "Notify"}</Title>
          <HoverText>{"Pack"}</HoverText>
        </AddButton>
      </ButtonWrapper>
      <div onClick={gotoProductDetail}>
        {trendtag || recommendtag || newtag || outOfStock || salestag ? (
          <CardTag
            className={`${(trendtag && trendtagColor) ||
              (newtag && newtagColor) ||
              (salestag && salestagColor) ||
              (recommendtag && recommendtagColor) ||
              (outOfStock && "bg-color165")
              }`}
          >
            <span>
              {trendtag || recommendtag || newtag || salestag || outOfStock}
            </span>
          </CardTag>
        ) : null}
        {((productItem?.b2b_product?.image ? productItem?.b2b_product?.image[0]?.image : null)
          || (productItem?.image ? productItem?.image[0]?.image : null)) ?
          <CardImg>
            <Img src={((productItem?.b2b_product?.image ? productItem?.b2b_product?.image[0]?.image : null)
              || (productItem?.image ? productItem?.image[0]?.image : null))} alt="" onError={(e) => handleError(e)} />
          </CardImg> :
          <Img src={productDefaultImage} />
        }
        <CardBody>
          <CardTitle>{(productItem?.name || productItem?.b2b_product?.name) || "N/A"}</CardTitle>
          <CardContent
            className={`${newtag || recommendtag || trendtag
              ? "justify-end"
              : "justify-between"
              }`}
          >
            <OurPrice>{productItem?.price || productItem?.b2b_product?.price || "N/A"}</OurPrice>
            {newtag || recommendtag || trendtag ? null : (
              <MarketPrice>instead {productItem?.actual_price || productItem?.b2b_product?.actual_price || "N/A"}</MarketPrice>
            )}
          </CardContent>
          <CardContent>
            <ProductWeight>{weight}</ProductWeight>
            <ProductRate>{productRate}</ProductRate>
          </CardContent>
          <div className="flex items-center justify-between mt-1">
            {cardIcon?.map((icon, index) => {
              return (
                <div className={`${icon.size}`} key={index}>
                  <img src={icon.img ? icon.img : undefined} alt="" />
                </div>
              );
            })}
          </div>
        </CardBody>
      </div>
    </CardContainer>
  );
};

const CardContainer = tw.div`
group
flex
flex-col
items-stretch
shadow-cartBtn
bg-white
rounded-[3px]
px-2
w-full
my-auto
relative
shadow-cartShadow
cursor-pointer
`;

const ButtonWrapper = tw.div`
right-2
mx-auto 
absolute
bg-white
shadow-cartShadow
rounded-[30px]
top-2
p-2
cursor-pointer
mx-auto
`;

const AddButton = tw.div`
flex
items-center

`;

const Title = tw.span`
text-color456
text-[14px]
font-bold
pr-1
`;

const HoverText = tw.span`
inline-flex
text-color456
text-[14px]
font-bold
overflow-hidden
ease-in-out duration-[0.8s]
tracking-[-2em]
group-hover:tracking-normal
`;


const CardTag = tw.div`
absolute
top-14
left-0
text-white
px-2 py-[2px]
rounded-tr-[5px]
uppercase
text-[13px]
`;

const CardBody = tw.div`
pb-1
`;

const CardTitle = tw.span`
flex
capitalize
truncate
font-bold
tracking-tight
text-gray-900
`;

const CardContent = tw.div`
flex
justify-between
items-center
mt-1
mb-2
`;

const OurPrice = tw.span`
2xl:text-lg
font-extrabold
font-openSans
text-gray-900
`;

const MarketPrice = tw.span`
font-semibold
text-gray-900
text-[12px]
capitalize
truncate
`;

const ProductWeight = tw.span`
font-normal
text-color139
`;

const ProductRate = tw.span`
font-normal
text-color139
`;

const Img = tw.img`
w-full
object-contain
rounded-t-lg
pt-8
pb-4
xl:h-[10vw]
lg:h-[18vw]
md:h-[20vw]
h-[32vw]
`;

const CardImg = tw.div`
w-full
px-2
cursor-pointer
`;

export default Card;