import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  AuthContainer,
  CheckBox,
  AuthHeader,
  CustomCalendar,
  GoogleMapAutoComplete,
  PrimaryText
} from "@components";
import theme from "@theme";
import logo from "@assets/images/mapl1.png";
import checkIcon from "@assets/images/openedGray.png";
import greenCheckIcon from "@assets/images/opened.png";
import switzerlandLogo from "@assets/images/flag-switzerland.png";
import OtpModal from "./widgets/OtpModal";
import { toast } from "react-toastify";
import { en } from "@i18n";
import {
  isValidNumber,
  isValidEmail,
  isValidUrl,
  isValidPostcode,
  isValidateSpecialChar,
  calcLatLongDelta,
  formatAddress
} from "@utils";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { userApi, getTokens } from "@services";
import moment from "moment";
import {
  setToken,
  verifyOtp,
  userProfile,
  getCartList,
  getWishList,
  getProducts,
  getNotifyProduct,
  uploadDeviceToken
} from "@store";
import { useDispatch } from "react-redux";

const BusinessRegistration = () => {
  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phone_num: "",
    password: "",
    confirmPassword: "",
    referralCode: "",
    newsletterChecked: false,
    showPassword: false,
    showConfirmPassword: false,
    dob: "",
    company: "",
    url: "",
    uid: "",
    street: "",
    building_num: "",
    postcode: "",
    city: "",
    country: "",
    isMobileVerificationModal: false,
    isEmailVerificationModal: false,
    isMobileModalValue: "",
    isEmailModalValue: "",
    isOtpModalValue: "",
    verifyMobileNum: false,
    verifyEmail: false,
  });

  const {
    fname,
    lname,
    email,
    phone_num,
    password,
    confirmPassword,
    referralCode,
    newsletterChecked,
    showPassword,
    showConfirmPassword,
    dob,
    company,
    url,
    uid,
    street,
    building_num,
    postcode,
    city,
    country,
    isMobileVerificationModal,
    isEmailVerificationModal,
    isMobileModalValue,
    isEmailModalValue,
    verifyMobileNum,
    verifyEmail,
  } = state;

  useEffect(() => {
    document.title = `[MAPL - Business] - Business To Business Registration`;
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dispatchItems = () => {
    Promise.all([
      dispatch(userProfile()),
      dispatch(getCartList()),
      dispatch(getWishList()),
      dispatch(getProducts()),
      dispatch(getNotifyProduct())
    ])
  }

  const handleAddress = (details) => {
    const coords = details?.geometry?.viewport

    const longObj = Object.values(coords)[0];
    const latObj = Object.values(coords)[1];

    const latitude = Object.values(longObj)[0];
    const longitude = Object.values(latObj)[0];

    // const latitude = coords.Ga.hi
    // const longitude = coords.Ua.hi
    const delta = calcLatLongDelta(latitude)
    const latitudeDelta = delta.latitudeDelta
    const longitudeDelta = delta.longitudeDelta

    let locationCords = {
      latitude: latitude,
      longitude: longitude,
      latitudeDelta: latitudeDelta,
      longitudeDelta: longitudeDelta,
    }

    const formatNewAddress = formatAddress(details.address_components, locationCords)

    let newInvoiceAddress = {
      ...state,
      city: formatNewAddress.city,
      building_num: formatNewAddress.building_num,
      country: formatNewAddress.country,
      street: formatNewAddress.address,
      postcode: formatNewAddress.postcode,
      lat: latitude,
      lng: longitude,
      lngDelta: longitudeDelta,
      latDelta: latitudeDelta,
    }
    setState((prev) => ({ ...prev, ...newInvoiceAddress }))
  }

  const getPushNotification = () => {
    getTokens((isTokenFound, token) => {
      if (isTokenFound) {
        let data = {
          type: 'web',
          token: token,
          status: 'active'
        };
        dispatch(uploadDeviceToken(data))
      }
    })
  }

  const onChangeAutoCompleteHandler = (event) => {
    if (event.target.value) {
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      setState({ ...state, street: '', building_num: '', postcode: '', city: '', country: '' })
    }
  }

  const onChangeHandler = (event) => {
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  }

  const handleChange = (value) => {
    setState((prev) => ({ ...prev, dob: moment(new Date(value)).format("YYYY-MM-DD") }));
  }

  const getOtpValue = (data) => {
    userApi.getOtp(data).then((res) => {
      if (res && res?.data && res?.status === 200) {
        toast.success(res?.data?.message);
      }
    });
  };

  const verifyEmailOtp = () => {
    let data = {
      email: email,
      role: "business_user",
      otp: isEmailModalValue
    }
    dispatch(verifyOtp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setState((prev) => ({
          ...prev,
          isEmailVerificationModal: false,
          verifyEmail: true,
          isEmailModalValue: null,
        }));
      } else {
        toast.error(en.errors.minimumOtpValue);
      }
    })
  }

  const verifyMobileOtp = () => {
    const phone = phone_num.replace(/ /g, "")
    let data = {
      phone: phone,
      role: "business_user",
      otp: isMobileModalValue
    }
    dispatch(verifyOtp(data)).then((res) => {
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setState((prev) => ({
          ...prev,
          isMobileVerificationModal: false,
          verifyMobileNum: true,
          isMobileModalValue: null,
        }));
      } else {
        toast.error(en.errors.minimumOtpValue);
      }
    })
  }

  const toggleEmailVerificationModal = () => {
    if (email && !isValidEmail(email?.trim())) {
      toast.error(en.errors.emailValidate);
    } else {
      let item = {
        email: email,
        role: "business_user",
      };
      userApi.verifyUser(item).then((res) => {
        if (res?.status === 200) {
          let data = {
            email: email,
            role: "business_user",
          };
          getOtpValue(data);
          setState((prev) => ({
            ...prev,
            isEmailVerificationModal: true,
          }));
        } else if (res?.response?.status !== 200) {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isEmailVerificationModal: false }));
        }
      });
    }
  };

  const toggleMobileVerificationModal = () => {
    const phone = phone_num.replace(/ /g, "")
    if (phone_num && !isValidNumber(phone?.trim())) {
      toast.error(en.errors.phoneNumValidate);
    } else {
      let item = {
        phone: phone,
        role: "business_user",
      };
      userApi.verifyUser(item).then((res) => {
        if (res?.status === 200) {
          let data = {
            phone: phone,
            role: "business_user",
          };
          getOtpValue(data);
          setState((prev) => ({
            ...prev,
            isMobileVerificationModal: true,
          }));
        } else if (res?.response?.status !== 200) {
          toast.error(res?.response?.data?.message);
          setState((prev) => ({ ...prev, isEmailVerificationModal: false }));
        }
      });
    }
  };

  const onChangeEmail = (event) => {
    setState((prev) => ({ ...prev, email: event.target.value }));
    if (email) {
      setState((prev) => ({ ...prev, verifyEmail: false }));
    }
  };

  const getEmailOtpModalValue = (value) => {
    setState({ ...state, isEmailModalValue: value });
  };

  const getMobileNumberOtpModalValue = (value) => {
    setState({ ...state, isMobileModalValue: value });
  };

  const onclickEmailOtpModal = () => {
    if (isEmailModalValue?.trim().length === 0) {
      toast.error(en.errors.otpValidate);
    } else if (isEmailModalValue?.trim().length < 4) {
      toast.error(en.errors.minimumOtpValue);
    } else {
      verifyEmailOtp();
    }
  };

  const handleClickShowPassword = () => {
    setState((prev) => ({ ...prev, showPassword: !showPassword }));
  };

  const handleClickShowConfirmPassword = () => {
    setState((prev) => ({
      ...prev,
      showConfirmPassword: !showConfirmPassword,
    }));
  };

  const onclickMobileOtpModal = () => {
    if (isMobileModalValue?.trim().length === 0) {
      toast.error(en.errors.otpValidate);
    } else if (isMobileModalValue?.trim().length < 4) {
      toast.error(en.errors.minimumOtpValue);
    } else {
      verifyMobileOtp();
    }
  };

  const checkvalue =
    (fname?.trim().length === 0 && en.errors.empty_credential) ||
    (lname?.trim().length === 0 && en.errors.lastName) ||
    (email?.trim().length === 0 && en.errors.email) ||
    (phone_num?.trim().length === 0 && en.errors.empty_mobileNum) ||
    (password?.trim().length === 0 && en.errors.password) ||
    (confirmPassword?.trim().length === 0 && en.errors.confirmPassword) ||
    (dob?.trim().length === 0 && en.errors.dob) ||
    (company?.trim().length === 0 && en.errors.company) ||
    (uid?.trim().length === 0 && en.errors.uid) ||
    (street?.trim().length === 0 && en.errors.street) ||
    (postcode?.trim().length === 0 && en.errors.postcode) ||
    (city?.trim().length === 0 && en.errors.city) ||
    (country?.trim().length === 0 && en.errors.country);

  const completeRegistration = () => {
    if (checkvalue) {
      toast.error(checkvalue);
    } else if (!verifyEmail) {
      toast.error(en.errors.verifyEmail);
    } else if (!verifyMobileNum) {
      toast.error(en.errors.verifyMobileNum);
    } else if (
      (fname && isValidateSpecialChar(fname?.trim())) ||
      (lname && isValidateSpecialChar(lname?.trim()))
    ) {
      toast.error(en.errors.nameValidate);
    } else if (password?.trim()?.length < 8) {
      toast.error(en.errors.minimumPasswordValue);
    } else if (password !== confirmPassword) {
      toast.error(en.errors.confirmPasswordValidate);
    } else if (company && isValidateSpecialChar(company?.trim())) {
      toast.error(en.errors.validCompanyName);
    } else if (url && !isValidUrl(url?.trim())) {
      toast.error(en.errors.validUrl);
    }
    // else if (uid && !isValidUserId(uid?.trim())) {
    //   toast.error(en.errors.validUid);
    // } 
    else if (street && isValidateSpecialChar(street?.trim())) {
      toast.error(en.errors.validStreet);
    } else if (!postcode && !isValidPostcode(postcode?.trim())) {
      toast.error(en.errors.validPostCode);
    } else {
      const data = {
        email: email,
        phone: phone_num,
        password: password,
        first_name: fname,
        last_name: lname,
        dob: dob,
        role: "business_user",
        name: fname + lname,
        company: company,
        referral_code: referralCode,
        web_url: url,
        vat_number: uid,
        format_add: street,
        building_num: building_num,
        zip_code: postcode,
        city: city,
        country: country
      }
      userApi.signUp(data).then((res) => {
        if (res?.data && res?.status === 200) {
          setState((prev) => ({ ...prev, isLoading: false }));
          dispatch(setToken(res?.data?.data?.accessToken))
          dispatchItems();
          getPushNotification();
          navigate("/dashboard");
          toast.success(en.success.success);
        } else {
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.error(res?.response?.data?.message);
        }
      });
    }
  };


  const handleChecked = (e) => {
    setState((prev) => ({ ...prev, newsletterChecked: e.target.value }))
  }

  const handleMobileNumber = (num, numLength) => {
    let newNum = 0
    let countryCode = "+41"
    const editTextLength = phone_num?.length
    if (!phone_num?.includes(countryCode) && phone_num?.length < 1 && num != '+') {
      newNum = countryCode + num
      setState((prev) => ({ ...prev, phone_num: newNum, verifyMobileNum: false }))
    }
    else if ((editTextLength == 4 || editTextLength == 8 || editTextLength == 11) && numLength > editTextLength) {
      newNum = num + ' '
      setState((prev) => ({ ...prev, phone_num: newNum, verifyMobileNum: false }))
    }
    else {
      setState((prev) => ({ ...prev, phone_num: num, verifyMobileNum: false }))
    }
  }

  return (
    <AuthContainer>
      <LoginContainer>
        <AppLogo src={logo} />
        <AuthHeader />
        <LoginContent className="overflow-scroll mx-[25px] scrollbar-none">
          <div className="mt-[25px] grid lg:gap-4 lg:grid-cols-2">
            <Input
              name="fname"
              value={fname}
              type="text"
              title={"First Name"}
              placeholder={"Max"}
              onChange={onChangeHandler}
            />
            <Input
              name="lname"
              value={lname}
              type="text"
              title={"Last Name"}
              placeholder={"Muster"}
              onChange={onChangeHandler}
            />
          </div>
          <div className="grid lg:gap-4 lg:grid-cols-2">
            <Input
              name="email"
              value={email}
              type="email"
              title={"E-mail"}
              headericon={
                email && verifyEmail ? greenCheckIcon : email ? checkIcon : null
              }
              verifybuttontitle={
                email && verifyEmail ? "Verified" : email ? "Verify" : null
              }
              verifybuttonclassname={`${email && verifyEmail
                ? "text-black"
                : email
                  ? "text-blue underline"
                  : null
                }`}
              verifybuttonclick={email && toggleEmailVerificationModal}
              placeholder={"kunde@mapl.ch"}
              onChange={onChangeEmail}
              required
              bottomlabel={
                email && verifyEmail
                  ? null
                  : email
                    ? "Didn’t receive the Code? "
                    : null
              }
              verifybuttondisabled={verifyEmail ? true : false}
              bottomclick={toggleEmailVerificationModal}
            />
            <Input
              name="phone_num"
              value={phone_num}
              type="tel"
              title={"Mobile Number"}
              headericon={
                phone_num && verifyMobileNum
                  ? greenCheckIcon
                  : phone_num
                    ? checkIcon
                    : null
              }
              verifybuttontitle={
                phone_num && verifyMobileNum
                  ? "Verified"
                  : phone_num
                    ? "Verify"
                    : null
              }
              verifybuttonclassname={`${phone_num && verifyMobileNum
                ? "text-black"
                : phone_num
                  ? "text-blue underline"
                  : null
                }`}
              verifybuttonclick={
                phone_num && toggleMobileVerificationModal
              }
              placeholder={"+41 79 774 53 76"}
              inputicon={switzerlandLogo}
              maxLength={17}
              onChange={(text) => handleMobileNumber(text.target.value, text.target.value.length)}
              bottomlabel={
                phone_num && verifyMobileNum
                  ? null
                  : phone_num
                    ? "Didn’t receive the Code? "
                    : null
              }
              verifybuttondisabled={verifyMobileNum ? true : false}
              bottomclick={toggleMobileVerificationModal}
            />
          </div>
          <Input
            name="password"
            value={password}
            type={showPassword ? "text" : "password"}
            title={"Password"}
            placeholder={"Password"}
            onChange={onChangeHandler}
            required
            hideandshoweyeicon={
              showPassword ? (
                <EyeIcon className="w-3 h-3" />
              ) : (
                <EyeOffIcon className="w-3 h-3" />
              )
            }
            hideandshowpasswordonclick={handleClickShowPassword}
          />
          <Input
            name="confirmPassword"
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            title={"Confirm Password"}
            placeholder={"Confirm Password"}
            onChange={onChangeHandler}
            hideandshoweyeicon={
              showConfirmPassword ? (
                <EyeIcon className="w-3 h-3" />
              ) : (
                <EyeOffIcon className="w-3 h-3" />
              )
            }
            hideandshowpasswordonclick={handleClickShowConfirmPassword}
          />
          <Input
            name="referralCode"
            value={referralCode}
            type="text"
            title={"Referral Code"}
            placeholder={"Referral Code"}
            onChange={onChangeHandler}
          />
          <div className="grid lg:gap-4 lg:grid-cols-2 ">
            <CustomCalendar
              title={"Date of Birth"}
              value={dob}
              name="dob"
              disableFuture={true}
              onChange={handleChange}
            />
            <Input
              name="company"
              value={company}
              type="text"
              title={"Company"}
              placeholder={"Muster GmbH"}
              onChange={onChangeHandler}
            />
          </div>
          <div className="grid lg:gap-4 lg:grid-cols-2 ">
            <Input
              name="url"
              value={url}
              type="url"
              title={"Web URL (optional)"}
              placeholder={"https://google.com"}
              onChange={onChangeHandler}
            />
            <Input
              name="uid"
              value={uid}
              type="text"
              title={"UID / VAT number"}
              placeholder={"CH-123.456.789"}
              onChange={onChangeHandler}
            />
          </div>
          <div className="grid lg:gap-4 lg:grid-cols-2 ">
            <div>
              <PrimaryText className={"text-[12px] font-sans font-semibold"}>{'Address'}</PrimaryText>
              <GoogleMapAutoComplete
                placeholder={"Soodstrasse"}
                value={street}
                name={"street"}
                className={'h-[2.75pc] mt-[0.3pc] px-4'}
                handleAddress={handleAddress}
                onChangeHandler={onChangeAutoCompleteHandler}
              />
            </div>
            <Input
              name="building_num"
              value={building_num}
              type="text"
              title={"Building No."}
              placeholder={"52"}
              onChange={onChangeHandler}
            />
          </div>
          <div className="grid lg:gap-4 lg:grid-cols-3 ">
            <Input
              name="postcode"
              value={postcode}
              type="text"
              title={"Postcode"}
              placeholder={"8134"}
              // maxLength={4}
              onChange={onChangeHandler}
            />
            <Input
              name="city"
              value={city}
              type="text"
              title={"City"}
              placeholder={"Adliswil"}
              onChange={onChangeHandler}
            />
            <Input
              name="country"
              value={country}
              type="text"
              title={"Country"}
              placeholder={"Switzerland"}
              onChange={onChangeHandler}
            />
          </div>
          <CheckBox title={"Subscribe to Newsletter"} value={newsletterChecked} onChange={(e) => handleChecked(e)} defaultChecked={true} />
          <Button
            title={"CREATE ACCOUNT"}
            titleStyle={{ color: theme.colors.white }}
            onClick={completeRegistration}
            className={"self-center bg-black my-[20px] mb-[30px] w-full"}
          />
        </LoginContent>
      </LoginContainer>
      <OtpModal
        value={isEmailModalValue}
        onChange={getEmailOtpModalValue}
        isOpenModal={isEmailVerificationModal}
        title={"Email Verification"}
        heading={"Please enter 4 digit code"}
        buttonTitle={"Verify"}
        closeModal={() =>
          isEmailVerificationModal &&
          setState((prev) => ({ ...prev, isEmailVerificationModal: false, isEmailModalValue: "" }))
        }
        onClick={onclickEmailOtpModal}
      />
      <OtpModal
        value={isMobileModalValue}
        onChange={getMobileNumberOtpModalValue}
        isOpenModal={isMobileVerificationModal}
        title={"Mobile number Verification"}
        heading={"Please enter 4 digit code"}
        buttonTitle={"Verify"}
        closeModal={() =>
          isMobileVerificationModal &&
          setState((prev) => ({ ...prev, isMobileVerificationModal: false, isMobileModalValue: "" }))
        }
        onClick={onclickMobileOtpModal}
      />
    </AuthContainer>
  );
};

const LoginContainer = tw.div`
flex 
flex-col 
border
border-borderColor
rounded-[5px]
relative 
bg-white
w-[90%]
h-[75%]
lg:h-[630px]
lg:w-[480px]
md:h-[590px]
md:w-[440px]
sm:h-[550px]
sm:w-[400px]
`;

const LoginContent = tw.div`
flex
flex-col
justify-between 
`;

const AppLogo = tw.img`
self-center
absolute
top-[-17px]
h-[34px]
lg:w-[110px]
lg:h-[34px]
sm:w-[90px]
sm:h-[28px]
`;

export default BusinessRegistration;