import * as types from "./Types";
import { categoryApi } from "@services";

export const getCategories = () => {
  return {
    type: types.GET_CATEGORIES,
    payload: categoryApi.getAllCategory(),
  };
};

export const getSubCategories = (id) => {
  return {
    type: types.GET_SUBCATEGORIES,
    payload: categoryApi.getAllSubcategory(id),
  };
};