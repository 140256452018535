import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { AiOutlineUser, AiOutlineHeart } from "react-icons/ai";
import { CgShoppingCart } from "react-icons/cg";
import SwitchOption from "./SwitchOption";
import { Profile } from "@containers";
import { ValidationModal } from "@components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { scrollToTopWindow } from "@utils";
import { userProfile } from "@store"
import { useDispatch } from "react-redux";

const UserData = (props) => {
  const { userclassname } = props;

  const [state, setState] = useState({
    openMenu: false,
    openProfile: false,
    isValidateModal: false,
  });
  const { openMenu, openProfile, isValidateModal } = state;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token)

  const getCartListItem = useSelector((state) => state.products.getCartListItem)
  const getWishListItem = useSelector((state) => state.products.getWishListItem)
  const userName = useSelector((state) => state?.user?.userData?.first_name);

  const handleMenu = () => {
    setState((prev) => ({ ...prev, openMenu: !openMenu }));
  };

  const handleUserProfile = () => {
    if (token) {
      dispatch(userProfile())
      setState((prev) => ({ ...prev, openProfile: !openProfile }));
    } else {
      navigate("/")
    }
  };

  const handleWishlist = () => {
    if (token) {
      navigate('/wishlist')
      scrollToTopWindow();
    } else {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const handleCart = () => {
    if (token) {
      navigate("/cart")
      scrollToTopWindow();
    } else {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  return (
    <Container className={userclassname}>
      <MenuBtn openMenu={openMenu} onClick={handleMenu}>
        <div />
        <div />
        <div />
      </MenuBtn>
      <MenuContent id="mobile-menu" $openMenu={openMenu}>
        <SwitchOption switchClass={"xl:block 2xl:mx-4 xl:mx-1"} />
        <MenuList>
          <UserLi>
            <CustomButton
              onClick={handleUserProfile}
              disabled={location.pathname.includes("/profile") ? true : false}
            >
              {<AiOutlineUser size={25} />}
              <Text>{userName ?? "Login"}</Text>
            </CustomButton>
          </UserLi>
          <UserLi>
            <CustomButton onClick={handleWishlist}>
              {<AiOutlineHeart size={25} />}
              {token && <BadgeVal
                className={
                  "xl:right-[2px] lg:right-[48px] top-[-3px] right-[58px]"
                }
              >
                {getWishListItem?.length ?? 0}
              </BadgeVal>}
              <Text>{"Wishlist"}</Text>
            </CustomButton>
          </UserLi>
          <UserLi>
            <CustomButton onClick={handleCart}>
              {<CgShoppingCart size={25} />}
              {token && <BadgeVal
                className={"xl:right-[-6px] lg:right-[28px] right-[34px]"}
              >
                {getCartListItem?.length ?? 0}
              </BadgeVal>}
              <Text>{"Cart"}</Text>
            </CustomButton>
          </UserLi>
        </MenuList>
      </MenuContent>
      <Profile
        openProfile={openProfile}
        closeModal={() => openProfile && setState((prev) => ({ ...prev, openProfile: false }))}
      />
      <ValidationModal
        isOpenModal={isValidateModal}
        close={() =>
          isValidateModal &&
          setState((prev) => ({ ...prev, isValidateModal: false }))
        }
        closeModal={() =>
          isValidateModal &&
          setState((prev) => ({ ...prev, isValidateModal: false }))
        }
      />
    </Container>
  );
};

const Container = tw.div`
xl:relative
lg:absolute
absolute
right-0
z-10
bg-transparent
`;

const MenuContent = tw.div`
${(p) => (p.$openMenu ? "hidden" : "flex")}
justify-between
lg:flex-col
flex-col
xl:flex-row
items-center
xl:relative
xl:w-auto
lg:absolute
right-0
lg:w-80
w-72
xl:bg-transparent
xl:shadow-none
bg-white
shadow-xl
pl-3
pr-1
z-10
`;

const MenuList = tw.ul`
flex 
lg:flex-col
flex-col
xl:flex-row
lg:bg-white
xl:bg-transparent xl:shadow-none
mt-4 
md:mt-0
justify-center
`;

const UserLi = tw.li`
2xl:px-4 
xl:px-2 
lg:px-0 
lg:first:pl-0 
2xl:first:pl-9 
xl:first:pl-7 
last:pr-0
`;

const Text = tw.span`
lg:text-[11px] 
text-[14px] 
flex 
xl:flex 
justify-center
xl:m-0 ml-4
`;

const CustomButton = tw.button`
relative
flex
items-center
xl:my-0
xl:flex-col 
lg:flex-row 
md:my-3 
my-3 
disabled:cursor-no-drop
hover:after:bg-color456
active:after:bg-color456
after:absolute
after:-bottom-1
after:w-full
after:h-1
`;

const BadgeVal = tw.span`
text-[10px]
text-white
rounded-full
bg-black
absolute
top-[-3px]
w-4
h-4
`;

const MenuBtn = styled('button')`
margin: 12px 15px;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 1rem;
height: 1rem;
background: transparent;
border: none;
cursor: pointer;
padding: 0;

@media(min-width: 992px){
  display: none;
}

  div {
width: 1.05rem;
height: 0.25rem;
background: rgb(46,56,66);
border-radius: 8px;
transition: all 0.3s linear;
transform-origin: 1px;

  :first-child{
    transform: ${({ openMenu }) => !openMenu ?
    'rotate(45deg)' : 'rotate(0)'};
  }
  :nth-child(2){
    opacity: ${({ openMenu }) => !openMenu ?
    '0' : '1'}; 
    transform: ${({ openMenu }) => !openMenu ?
    'translateX(-20px)' : 'translateX(0)'};
  }
  :nth-child(3){
    transform: ${({ openMenu }) => openMenu ?
    'rotate(0)' : 'rotate(-45deg)'};
  }
}
`;

export default UserData;