import React from 'react'
import tw from 'tailwind-styled-components';
import { useTranslation } from 'react-i18next';

function NutritionValues(props) {
  const { nutritionalValues } = props;

  const { t } = useTranslation();

  return (
    <div>
      <HeadingText>{t("nutrition.nutritionalValues")}</HeadingText>
      {nutritionalValues && nutritionalValues?.map((value, index) => {
        return (
          <NutritionContent key={index}>
            <span>{value.nutritionItemTitle}</span>
            <span>{value.nutritionItemValue}</span>
          </NutritionContent>
        );
      })}
    </div>
  )
}

const HeadingText = tw.span`
text-xl 
font-raleway 
font-bold
`

const NutritionContent = tw.div`
bg-color235 
even:bg-transparent 
flex 
items-center 
justify-between 
py-3 px-2
`

export default NutritionValues