import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Footer } from "@components";
import Options from "./Options";
import ProductContent from "./ProductContent";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSubCategories, getProductFilter } from "@store";

const Products = () => {
  const product = useLocation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    subCategoryData: [],
    filterListing: [],
    categoryName: product?.state?.item?.name,
  });
  const { subCategoryData, filterListing, categoryName } = state;

  useEffect(() => {
    document.title = `[MAPL - Business] - Category - ${categoryName}`;
    getData(product?.state?.item?.id);
    dispatch(getProductFilter()).then((res) => {
      if (res?.payload?.data?.data) {
        handleApiData(res?.payload?.data?.data);
      }
    })
  }, []);

  const categoryData = useSelector((state) => state.category.categoryData);
  const getProductFilterData = useSelector((state) => state.products.getProductFilter)

  const sortArray = (type, arrData) => {
    if (type) {
      let sortedArr = [...arrData]
      let filterData = sortedArr.filter(item => typeof item !== 'object')
      if (type == 'string') {
        filterData.sort()
        return filterData
      }
      else if (type == 'number') {
        filterData.sort((a, b) => b - a);
        return filterData
      }
    }
  }

  const handleFilterCategoryName = (name) => {
    let filterName = null;
    if (name) {
      filterName = name.slice(0, 1).toUpperCase() + name.slice(1, name.length).toLowerCase()
      filterName = filterName.replace(/_/g, ' ')
      return filterName
    }
  }

  const handleApiData = (apiData) => {
    let newData = []
    for (const key in apiData) {
      let sortedFilters = sortArray(typeof apiData[key][apiData[key].length - 1], apiData[key])
      let obj = {
        id: 1,
        key: key,
        title: handleFilterCategoryName(key),
        value: sortedFilters ? [...sortedFilters] : []
      }
      newData.push(obj)
    }
    setState((prev) => ({ ...prev, filterListing: newData }))
  }

  const getData = (id) => {
    return dispatch(getSubCategories(id))
      .then((res) => {
        if (res?.payload?.data)
          setState((prev) => ({
            ...prev,
            subCategoryData: res?.payload?.data?.data,
          }));
      })
      .catch((err) => {
        return err;
      });
  };

  const onClickCategory = (item) => {
    getData(item.id);
    setState((prev) => ({ ...prev, categoryName: item.name }));
  };

  return (
    <>
      <FilterContainer>
        <OptionContainer>
          <Options productFilterdData={filterListing} />
        </OptionContainer>
        <ProductsContainer>
          <ProductContent
            categoryData={categoryData}
            subCategoryData={subCategoryData}
            onClickCategory={onClickCategory}
            categoryName={categoryName}
            categoryId={product?.state?.item?.id}
          />
          <Footer className="mt-[10%]" />
        </ProductsContainer>
      </FilterContainer>
    </>
  );
};

const FilterContainer = tw.div`
flex
`;

const OptionContainer = tw.div`
xl:w-1/6
lg:w-full
w-full
h-screen
xl:sticky
xl:top-8
z-40
`;

const ProductsContainer = tw.div`
xl:w-5/6
lg:w-full
w-full
h-full
px-4
xl:py-1
lg:py-7
`;

export default Products;
