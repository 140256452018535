import axios from "axios";
import Config from "../config";
import { get, set } from "@utils";

let instance = axios.create({
  baseURL: Config.SERVER_HOST,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000000,
});

instance.interceptors.request.use(function (config) {
  const token = get("businessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const userApi = {
  signUp: (data) => {
    return instance
      .post(Config.USER_SIGNUP, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getOtp: (data) => {
    return instance
      .post(Config.SEND_OTP, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  forgetPassword: (data) => {
    return instance
      .post(Config.FORGET_PASSWORD, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  resetPassword: (data) => {
    return instance
      .post(Config.RESET_PASSWORD, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  verifyUser: (data) => {
    return instance
      .post(Config.VERIFY_USER, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  verifyOtp: (data) => {
    return instance
      .post(Config.VERIFY_OTP, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  login: (data) => {
    return instance
      .post(Config.USER_LOGIN, data)
      .then((res) => {
        if (res && res.data) {
          set("businessToken", res?.data?.data?.accessToken);
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  authLogin: (data) => {
    return instance
      .post(Config.AUTH_LOGIN, data)
      .then((res) => {
        if (res && res.data) {
          set("businessToken", res?.data?.data?.accessToken);
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  userProfile: () => {
    return instance
      .get(Config.USER_PROFILE)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateUserProfile: (data) => {
    return instance
      .patch(Config.UPDATE_USER_PROFILE, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  changeUserPassword: (data) => {
    return instance
      .post(Config.CHANGE_USER_PASSWORD, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getUserAddress: () => {
    return instance
      .get(Config.GET_USER_ADDRESS)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editUserAddress: (id, data) => {
    return instance
      .patch(`${Config.GET_USER_ADDRESS}/${id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addUserAddress: (data) => {
    return instance
      .post(Config.GET_USER_ADDRESS, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addSuggestLocation: (data) => {
    return instance
      .post(Config.ADD_SUGGEST_LOCATION, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteUserAddress: (id) => {
    return instance
      .delete(`${Config.GET_USER_ADDRESS}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addInvoiceDetail: (data) => {
    return instance
      .post(Config.ADD_INVOICE_DETAIL, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getInvoiceDetail: () => {
    return instance
      .get(Config.GET_INVOICE_DETAIL)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  uploadFiles: (data) => {
    return instance
      .post(Config.UPLOAD_FILES, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  createPaymentTransactionId: (data) => {
    return instance
      .post(Config.CREATE_PAYMENT_TRANSACTION_ID, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  uploadDeviceToken: (data) => {
    return instance
      .post(Config.UPLOAD_DEVICE_TOKEN, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  logout: () => {
    const data = {
      pramas: {
        type: "web"
      }
    }
    return instance
      .post(Config.LOGOUT, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
};

const categoryApi = {
  getAllCategory: () => {
    return instance
      .get(Config.GET_CATEGORIES)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAllSubcategory: (id) => {
    return instance
      .get(`${Config.GET_SUBCATEGORIES}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
};

const homeApi = {
  getRecommendedProduct: () => {
    return instance
      .get(`${Config.GET_RECOMMENDATION_TRENDING_PRODUCT}?slug=recommended`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getNewReleaseProduct: () => {
    return instance
      .get(Config.GET_NEWRELEASE_PRODUCT)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getTrendingProduct: () => {
    return instance
      .get(Config.GET_RECOMMENDATION_TRENDING_PRODUCT)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getSalesProduct: () => {
    return instance
      .get(Config.GET_SALES_PRODUCT)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getOfferPopup: () => {
    return instance
      .get(Config.GET_OFFER_POPUP)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getHomeBanner: () => {
    const data = {
      params: {
        type: "business"
      }
    }
    return instance
      .get(`${Config.GET_BANNER}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  popularSearch: () => {
    return instance
      .get(`${Config.POPULAR_SEARCH}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getStoreSchedule: () => {
    return instance
      .get(`${Config.GET_STORE_SCHEDULE}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getUserNotifications: () => {
    return instance
      .get(`${Config.USER_NOTIFICATIONS}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  }
};

const productApi = {
  getProduct: (data) => {
    return instance
      .post(Config.GET_PRODUCTS, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addToCartProduct: (data) => {
    return instance
      .post(Config.ADD_CART_PRODUCT, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getCartList: () => {
    return instance
      .get(Config.CART_LIST)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  removeCartProduct: (id) => {
    return instance
      .delete(`${Config.REMOVE_CART_PRODUCT}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  clearAllCartProduct: () => {
    return instance
      .delete(Config.CLEAR_ALL_CART_PRODUCT)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  checkCartAvailProduct: () => {
    return instance
      .get(Config.CHECK_CART_AVAIL_STOCK)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  },
  getProductDetail: (product_id, data) => {
    return instance
      .get(`${Config.GET_PRODUCT_DETAIL}/${product_id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getProductFilter: () => {
    return instance
      .get(Config.GET_PRODUCT_FILTER)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getWishList: () => {
    return instance
      .get(Config.GET_WISH_LIST)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addAndRemoveWishList: (product_id) => {
    return instance
      .post(`${Config.GET_WISH_LIST}/${product_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addWishListToCart: (data) => {
    return instance
      .post(`${Config.ADD_WISHLIST_TO_CART}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAllOrderDetails: (payload) => {
    return instance
      .get(`${Config.GET_ALL_ORDER_DETAILS}?${payload ? payload : ""}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  addOrderDetail: (data) => {
    return instance
      .post(Config.ADD_ORDER_DETAILS, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  getOrderDetail: (id) => {
    return instance
      .get(`${Config.GET_ORDER_DETAIL}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // When user place the order then navigate to order-place screen and after success payment patch the order with Txn id or Order id in useEffect
  updateOrder: (id, data) => {
    return instance
      .patch(`${Config.UPDATE_ORDER}/${id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  },

  getOffersVouchers: () => {
    let data = {
      params: {
        type: 'business'
      }
    }
    return instance
      .get(Config.GET_OFFER_VOUCHERS, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getVouchers: () => {
    return instance
      .get(Config.GET_VOUCHERS)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAvailableOffer: (offer_id) => {
    return instance
      .get(`${Config.GET_AVAILABLE_OFFERS}?offer_id=${offer_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  },
  getOffer: (id) => {
    return instance
      .get(`${Config.GET_OFFER}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addReviewRating: (data) => {
    return instance
      .post(Config.ADD_REVIEW_RATING, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getProductRating: (id) => {
    return instance
      .get(`${Config.GET_REVIEW_RATING}/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  notification: (product_id) => {
    return instance
      .post(`${Config.NOTIFICATION}/${product_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getNotifyProduct: () => {
    return instance
      .get(Config.GET_NOTIFY_PRODUCT)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  },
  getDeliveryFee: () => {
    return instance
      .get(Config.PRODUCT_DELIVERY_FEE)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      })
  },
};

const profileApi = {
  getFaq: (user_id) => {
    return instance
      .get(`${Config.FAQ}?status=active&user_id=${user_id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getPrivayPolicy: (type) => {
    return instance
      .get(`${Config.PRIVACY_POLICY}?type=${type}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
};

export { userApi, homeApi, categoryApi, productApi, profileApi };