import React from "react";
import tw from "tailwind-styled-components";
import PrimaryNavbar from "./PrimaryNavbar";
import SecondaryNavbar from "./SecondaryNavbar";

const TopNavbar = () => {
  return (
    <>
      <Primary>
        <PrimaryNavbar />
      </Primary>

      <Secondary>
        <SecondaryNavbar />
      </Secondary>
    </>
  );
}

const Primary = tw.div`
sticky
top-0
right-0
left-0
z-[99]
h-auto
`;

const Secondary = tw.div`
sticky
top-9
right-0
left-0
z-50
w-full
h-auto
`;

export default TopNavbar;