import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/ConfigureStore";
import { Loader } from "@components";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router basename={process.env.REACT_APP_BASE_NAME}>
      <Suspense
        fallback={
          <div className="h-screen w-screen">
            <Loader />
          </div>
        }
      >
        <App />
      </Suspense>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();