import React from "react";
import tw from "tailwind-styled-components";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import { ToggleModalHeader } from "./widgets";
import { useSelector } from "react-redux";

const ValidationModal = (props) => {
  const { isOpenModal, close, closeModal } = props;

  const hidden = !isOpenModal ? "hidden" : "flex";

  const token = useSelector((state) => state.user.token)

  const modalStyle = useSpring({
    scale: isOpenModal ? 1 : 0,
    opacity: isOpenModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOpenModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const CrossButton = () => {
    return (
      <div className="flex justify-end">
        <button onClick={closeModal} className="pt-2 pr-3">
          <XIcon className="w-[18px]" />
        </button>
      </div>
    );
  };

  return (
    (!token && <animated.div
      style={modalBackground}
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen overflow-scroll scrollbar-none flex justify-center bg-white items-center z-[1000]`}
      onClick={close}
    >
      <animated.div style={modalStyle} className="max-h-[40pc]" onClick={(e) => e.stopPropagation()}>
        <Container>
          <CrossButton />
          <ToggleModalHeader />
        </Container>
      </animated.div>
    </animated.div>)
  );
};

const Container = tw.div`
bg-white
mx-auto
lg:w-[480px]
md:w-[440px]
sm:w-[400px]
pb-6
rounded-[3px]
shadow-headerShadow
`;


export default ValidationModal;