import "./App.css";
import "swiper/css/bundle";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Flip, toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { userAuthenticated, userUnAuthenticated } from "./routes";
import { useDispatch } from "react-redux";
import {
  getCategories,
  getCartList,
  getWishList,
  getProducts,
  getStoreSchedule,
  getUserNotification,
  userProfile,
  getNotifyProduct,
  popularSearch,
  getRecommendedProduct,
  logout
} from "@store";
import { OfferModal, AppLoader, TopNavbar, PushNotification, ErrorPage } from "@components";
import { useSelector } from "react-redux";
import { set, remove } from "@utils"
import UserData from "@components/TopNavbar/widgets/UserData";

const App = () => {
  const [state, setState] = useState({
    isLoading: true,
  });
  const { isLoading } = state;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.token)

  useEffect(() => {
    if (userData?.status == 'inactive') {
      dispatch(logout())
        .then((res) => {
          remove("businessToken");
          window.location.reload();
          navigate("/");
          setState((prev) => ({ ...prev, isLoading: false }));
          toast.error('Your account has been deactived, Please contact admin')
        })
    }
  }, [userData?.status])

  useEffect(() => {
    // Show Mobile FAQ
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("token");
    if (accessToken) {
      set("token", accessToken);
    }
    if (token) {
      Promise.all([
        dispatch(getCartList()),
        dispatch(getStoreSchedule()),
        dispatch(getRecommendedProduct()),
        dispatch(getUserNotification()),
        dispatch(getNotifyProduct()),
        dispatch(popularSearch()),
        dispatch(getWishList()),
        dispatch(getProducts()),
        dispatch(getCategories()),
        dispatch(userProfile()),
      ])
        .then((res) => {
          if (res?.data?.payload) {
            setState((prev) => ({ ...prev, isLoading: false }));
          } else {
            setState((prev) => ({ ...prev, isLoading: false }));
          }
        })
        .catch((err) => {
          return err;
        });
    }
    else {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }, []);

  const isUserAuthenticated = () => {
    if (token) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <PushNotification />
      {isLoading ? (
        <AppLoader loading={isLoading} parentClassName={"w-screen h-screen"} childClassName={"w-20 h-20"} />
      ) : (
        <React.Fragment>
          <Routes>
            {isUserAuthenticated()
              ? userAuthenticated?.map((route, index) => {
                return (
                  <Route
                    key={"" + index}
                    path={route.path}
                    name={route.name}
                    exact={route.exact}
                    element={
                      <>
                        {/* {!route.isPublic && <TopNavbar /> || <UserData userclassname={"xl:hidden"} />} */}
                        <TopNavbar />
                        <UserData userclassname={"xl:hidden"} />
                        {route.element}
                      </>
                    }
                  />
                );
              })
              : userUnAuthenticated?.map((route, index) => {
                return (
                  <Route
                    key={"" + index}
                    path={route.path}
                    name={route.name}
                    exact={route.exact}
                    element={route.element}
                  />
                );
              })
            }
            <Route path="/error-page" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={true}
            transition={Flip}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </React.Fragment>
      )}
      <OfferModal />
    </div>
  );
};

export default App;