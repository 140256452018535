export const GET_PRODUCTS = "GET_PRODUCTS";
export const ADD_CART_PRODUCT = "ADD_CART_PRODUCT";
export const GET_CART_LIST = "GET_CART_LIST";
export const REMOVE_CART_PRODUCT = "REMOVE_CART_PRODUCT";
export const CLEAR_ALL_CART_PRODUCT = "CLEAR_ALL_CART_PRODUCT";
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_PRODUCT_FILTER = "GET_PRODUCT_FILTER";
export const GET_WISH_LIST = "GET_WISH_LIST";
export const ADD_WISHLIST_TO_CART = "ADD_WISHLIST_TO_CART";
export const ADD_WISH_LIST = "ADD_WISH_LIST";
export const GET_ALL_ORDER_DETAILS = "GET_ALL_ORDER_DETAILS";
export const GET_ORDER_DETAIL = "GET_ALL_ORDER_DETAIL";
export const ADD_ORDER_DETAILS = "ADD_ORDER_DETAILS";
export const GET_OFFER_VOUCHERS = "GET_OFFER_VOUCHERS";
export const GET_AVAILABLE_OFFERS = "GET_AVAILABLE_OFFERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const GET_VOUCHERS = "GET_VOUCHERS";
export const GET_OFFER = "GET_OFFER";
export const ADD_REVIEW_RATING = "ADD_REVIEW_RATING";
export const GET_REVIEW_RATING = "GET_REVIEW_RATING";
export const NOTIFICATION = "NOTIFICATION";
export const GET_NOTIFY_PRODUCT = "GET_NOTIFY_PRODUCT";
export const CHECK_CART_AVAIL_STOCK = "CHECK_CART_AVAIL_STOCK";
export const PRODUCT_DELIVERY_FEE = "PRODUCT_DELIVERY_FEE";
export const PACK_DISCOUNT = "PACK_DISCOUNT";
export const CLEAR_VOUCHERS = "CLEAR_VOUCHERS";
export const SET_VOUCHER = "SET_VOUCHER";