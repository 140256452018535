import React from "react";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import FilteredSearch from "./FilteredSearch";
import PopularSearch from "./PopularSearch";

const SearchPopup = (props) => {
  const { openSearch, openFilter, closeModal, closePopularSearchModal } = props;

  const popularSearchItem = useSelector((state) => state.home.popularSearchItem)

  return openFilter ? (
    <PopularSearchContainer $openSearch={openSearch}>
      <PopularSearch popularSearch={popularSearchItem} closePopularSearchModal={closePopularSearchModal} />
    </PopularSearchContainer>
  ) : (
    <FilteredSearchContainer $openSearch={openSearch}>
      <FilteredSearch closeModal={closeModal} />
    </FilteredSearchContainer>
  );
};

const PopularSearchContainer = tw.div`
${(p) =>
    p.$openSearch
      ? "absolute z-10 top-[0] right-0 left-0 bottom-0 w-full h-screen"
      : "hidden"}
`;

const FilteredSearchContainer = tw.div`
${(p) =>
    !p.$openSearch
      ? "hidden "
      : "absolute z-10 top-[0] right-0 left-0 bottom-0 w-full h-screen"}
`;

export default SearchPopup;
