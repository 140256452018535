import { combineReducers } from "@reduxjs/toolkit";
import user from "./User/Reducer";
import category from "./Category/Reducer";
import home from "./Home/Reducer";
import products from "./Products/Reducer";
import profile from "./Profile/Reducer";

const rootReducer = combineReducers({
  user,
  category,
  home,
  products,
  profile,
});

export default rootReducer;