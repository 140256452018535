import React from "react";
import tw from "tailwind-styled-components";
import { TertiaryBoldText } from "@components";
import { useNavigate } from "react-router-dom";

const PopularSearch = ({ popularSearch, closePopularSearchModal }) => {

  const navigate = useNavigate();

  const navigateViewMore = (text) => {
    closePopularSearchModal();
    navigate(`/view-more?type=search&keyword=${text}`)
  }

  return (
    <SearchContainer onClick={(e) => e.stopPropagation()}>
      <SearchContent>
        <SearchTitle>
          <div className="flex justify-between">
            <TertiaryBoldText className="xl:text-[25px]">
              {"Popular Search"}
            </TertiaryBoldText>
          </div>
        </SearchTitle>
        <SearchList>
          {popularSearch?.map((item, index) => {
            return (
              <div className="mr-4 my-4 ml-0" key={index}>
                <button onClick={() => navigateViewMore(item.text)}
                  className="px-10 py-3 rounded-lg bg-color239"
                >
                  {item.text}
                </button>
              </div>
            );
          })}
        </SearchList>
      </SearchContent>
    </SearchContainer>
  );
};

const SearchContainer = tw.div`
xl:w-[50%] 
lg:w-[60%] 
w-[72%] 
h-[465px] 
bg-white 
mr-[24%]
ml-[20.5%]
xl:ml-[25.6%]
4xl:ml-[20.6%]
`;

const SearchContent = tw.div`
w-full 
h-full 
flex 
flex-col
`;

const SearchTitle = tw.div`
px-6 
pt-5
`;

const SearchList = tw.div`
flex 
flex-wrap
px-6 
py-5
`;

export default PopularSearch;
