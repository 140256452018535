import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { SideBar, Footer } from "@components";
import { Banner, NewRelease, Recommendation, Sales, Trending } from "./widgets";
import { useDispatch } from "react-redux";
import { getHomeBanner } from "@store";

const Home = () => {
  const [state, setState] = useState({
    isLoading: false
  })
  const { isLoading } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `[MAPL - Business] - Online Grocery Store`;
    banner();
  }, []);

  const banner = () => {
    dispatch(getHomeBanner()).then((res) => {
      if (res?.payload?.status === 200) {
        setState((prev) => ({ ...prev, isLoading: false }))
      } else {
        setState((prev) => ({ ...prev, isLoading: true }))
      }
    })
  }

  return (
    <>
      <Container>
        <SideMenuContent>
          <SideBar />
        </SideMenuContent>
        <HomeScreenContent>
          <Banner isLoad={!isLoading} />
          <Recommendation isLoad={!isLoading} />
          <NewRelease isLoad={!isLoading} />
          <Trending isLoad={!isLoading} />
          <Sales isLoad={!isLoading} />
          <Footer />
        </HomeScreenContent>
      </Container>
    </>
  );
};

const Container = tw.div`
flex
`;

const SideMenuContent = tw.div`
xl:w-[15.5%] 
lg:sticky
lg:top-0
sticky
top-0
md:top-0
h-screen
flex flex-col
z-30
`;

const HomeScreenContent = tw.div`
xl:w-[85.5%]
w-full
h-full 
flex
flex-col
static
px-4
`;

export default Home;