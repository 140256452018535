const SERVER_HOST = process.env.REACT_APP_API_URL;

// Authentication
const USER_SIGNUP = `${SERVER_HOST}api/users/signup`;
const SOCIAL_USER_SIGNUP = `${SERVER_HOST}api/createSocialUser`;
const SEND_OTP = `${SERVER_HOST}api/users/send-otp`;
const FORGET_PASSWORD = `${SERVER_HOST}api/users/forget-password`;
const RESET_PASSWORD = `${SERVER_HOST}api/users/reset-password`;
const VERIFY_USER = `${SERVER_HOST}api/users/verify`;
const VERIFY_OTP = `${SERVER_HOST}api/verify-otp`;
const USER_LOGIN = `${SERVER_HOST}api/users/login`;
const AUTH_LOGIN = `${SERVER_HOST}api/users/auth-login`;
const LOGOUT = `${SERVER_HOST}api/users/logout`
const UPLOAD_DEVICE_TOKEN = `${SERVER_HOST}api/users/upsert-token`;

// Home
const GET_BANNER = `${SERVER_HOST}api/home/web-banner`;
const GET_RECOMMENDATION_TRENDING_PRODUCT = `${SERVER_HOST}api/B2B/home/products`;
const GET_NEWRELEASE_PRODUCT = `${SERVER_HOST}api/B2B/home/new-products`;
const GET_SALES_PRODUCT = `${SERVER_HOST}api/B2B/home/sale-products`;
const GET_STORE_SCHEDULE = `${SERVER_HOST}api/schedual`;
const POPULAR_SEARCH = `${SERVER_HOST}api/B2B/popular-search`;
const NOTIFICATION = `${SERVER_HOST}api/B2B/notifyMe`;
const UPLOAD_FILES = `${SERVER_HOST}api/files`;
const USER_NOTIFICATIONS = `${SERVER_HOST}api/notifications`;
const GET_NOTIFY_PRODUCT = `${SERVER_HOST}api/B2B/allNotify`;

// Product
const GET_CATEGORIES = `${SERVER_HOST}api/categories`;
const GET_SUBCATEGORIES = `${SERVER_HOST}api/sub-category`;
const GET_PRODUCTS = `${SERVER_HOST}api/B2B/products`;
const GET_PRODUCT_DETAIL = `${SERVER_HOST}api/B2B/product`;
const GET_PRODUCT_FILTER = `${SERVER_HOST}api/B2B/products-filter`;
const PRODUCT_DELIVERY_FEE = `${SERVER_HOST}api/delivery-fee`;
const PACK_DISCOUNT = `${SERVER_HOST}api/pack-discount`;

// Cart
const ADD_CART_PRODUCT = `${SERVER_HOST}api/B2B/cart/add-to-cart`;
const CART_LIST = `${SERVER_HOST}api/B2B/cart/list`;
const REMOVE_CART_PRODUCT = `${SERVER_HOST}api/B2B/cart/remove-from-cart`;
const CLEAR_ALL_CART_PRODUCT = `${SERVER_HOST}api/B2B/cart/clear-cart`;
const CHECK_CART_AVAIL_STOCK = `${SERVER_HOST}api/B2B/stock-check`;

// Wishlist
const GET_WISH_LIST = `${SERVER_HOST}api/B2B/wishlist`;
const ADD_WISHLIST_TO_CART = `${SERVER_HOST}api/B2B/cart-wishlist`;

// Profile
const USER_PROFILE = `${SERVER_HOST}api/users/me`;
const UPDATE_USER_PROFILE = `${SERVER_HOST}api/user`;
const CHANGE_USER_PASSWORD = `${SERVER_HOST}api/users/change-password`;
const FAQ = `${SERVER_HOST}api/faqs`;
const PRIVACY_POLICY = `${SERVER_HOST}api/legal_Help`;
const GET_USER_ADDRESS = `${SERVER_HOST}api/users/address`;
const ADD_SUGGEST_LOCATION = `${SERVER_HOST}api/users/suggest-location`;

// Product Rating
const ADD_REVIEW_RATING = `${SERVER_HOST}api/B2B/review-rating`;
const GET_REVIEW_RATING = `${SERVER_HOST}api/B2B/product-rating`;

// Offer
const GET_OFFER_POPUP = `${SERVER_HOST}api/B2B/offer-popup`;
const GET_AVAILABLE_OFFERS = `${SERVER_HOST}api/B2B/offer-avail`;
const GET_OFFER = `${SERVER_HOST}api/offer`;

// Place Order
const GET_ORDER_DETAIL = `${SERVER_HOST}api/B2B/order`;
const ADD_ORDER_DETAILS = `${SERVER_HOST}api/B2B/order`;
const UPDATE_ORDER = `${SERVER_HOST}api/B2B/edit-order`;
const GET_ALL_ORDER_DETAILS = `${SERVER_HOST}api/B2B/orders`;
const CREATE_PAYMENT_TRANSACTION_ID = `${SERVER_HOST}api/createTransactionId`;

// Vouchers
const GET_OFFER_VOUCHERS = `${SERVER_HOST}api/offers`;
const GET_VOUCHERS = `${SERVER_HOST}api/B2B/vouchers`;

// Invoice
const ADD_INVOICE_DETAIL = `${SERVER_HOST}api/B2B/invoice`;
const GET_INVOICE_DETAIL = `${SERVER_HOST}api/B2B/invoices`;
const DOWNLOAD_INVOICE = `${SERVER_HOST}B2B/invoice`;

const Config = {
  SERVER_HOST,
  USER_SIGNUP,
  SOCIAL_USER_SIGNUP,
  SEND_OTP,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  VERIFY_USER,
  VERIFY_OTP,
  USER_LOGIN,
  AUTH_LOGIN,
  LOGOUT,
  UPLOAD_DEVICE_TOKEN,
  GET_CATEGORIES,
  GET_SUBCATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCT_DETAIL,
  GET_STORE_SCHEDULE,
  GET_PRODUCT_FILTER,
  POPULAR_SEARCH,
  GET_BANNER,
  GET_RECOMMENDATION_TRENDING_PRODUCT,
  GET_NEWRELEASE_PRODUCT,
  GET_SALES_PRODUCT,
  GET_OFFER_POPUP,
  ADD_CART_PRODUCT,
  CART_LIST,
  REMOVE_CART_PRODUCT,
  CLEAR_ALL_CART_PRODUCT,
  GET_WISH_LIST,
  ADD_WISHLIST_TO_CART,
  CHECK_CART_AVAIL_STOCK,
  FAQ,
  PRIVACY_POLICY,
  USER_PROFILE,
  UPDATE_USER_PROFILE,
  CHANGE_USER_PASSWORD,
  GET_USER_ADDRESS,
  ADD_SUGGEST_LOCATION,
  GET_ALL_ORDER_DETAILS,
  GET_ORDER_DETAIL,
  ADD_ORDER_DETAILS,
  UPDATE_ORDER,
  GET_OFFER_VOUCHERS,
  GET_VOUCHERS,
  GET_AVAILABLE_OFFERS,
  GET_OFFER,
  ADD_REVIEW_RATING,
  GET_REVIEW_RATING,
  GET_INVOICE_DETAIL,
  DOWNLOAD_INVOICE,
  ADD_INVOICE_DETAIL,
  UPLOAD_FILES,
  NOTIFICATION,
  GET_NOTIFY_PRODUCT,
  USER_NOTIFICATIONS,
  CREATE_PAYMENT_TRANSACTION_ID,
  PRODUCT_DELIVERY_FEE,
  PACK_DISCOUNT
};

export default Config;