import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import { Card, Carousel } from "@components";
import { SwiperSlide } from "swiper/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import styled from "styled-components";
import { ExtraValues, NutritionValues, Specification } from "./widgets";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProducts } from "@store";
import { useTranslation } from "react-i18next";

const ProductRecommend = (props) => {
  const { extraInfo, nutritionalValues, description, brandName, moreProductsList, showProductDetail, recommendedProductList } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation()

  useEffect(() => {
    getProductList(brandName);
  }, [brandName]);

  const getProductList = (brand_name) => {
    let payload;
    if (brand_name) {
      payload = `search=${brand_name}`;
      dispatch(getProducts(payload));
    }
  };

  const cardClick = (id) => {
    navigate(`/product/${id}`, { state: id });
  };

  const productInfo = [
    { element: <Specification description={description} /> },
    { element: <NutritionValues nutritionalValues={nutritionalValues} /> },
    { element: <ExtraValues extraInfo={extraInfo} /> },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <Btn className="slick-arrow slick-next"></Btn>,
    prevArrow: <Btn className="slick-arrow slick-prev"></Btn>,
    slidesToShow: 2.45,
    slidesToScroll: 1,
    vertical: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          // centerMode: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          // centerMode: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          vertical: true,
          verticalSwiping: true,
          adaptiveHeight: true,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          // centerMode: true,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <Container>
      <ProductContent>
        <ProductTitle>
          <span className="text-2xl font-bold font-raleway">
            {t('moreFromThisBrand')}
          </span>
        </ProductTitle>
        <Carousel
          autoplay={false}
          slidesPerGroup={1}
          slidesPerView={6}
          slidesperview1200={6}
          spacebetween1200={6}
          slidesperview1024={6}
          spacebetween1024={6}
          slidesperview768={4}
          spacebetween768={5}
          slidesperview567={2}
          spacebetween567={5}
          slidesperview480={2}
          spacebetween480={5}
          slidesperview280={2}
          spacebetween280={5}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
            disabledClass: ".swiper-dis",
          }}
        >
          {moreProductsList && moreProductsList?.map((item, index) => {
            return (
              <SwiperSlide
                className="mb-1 first:ml-1 ml-0 last:mr-1"
                key={index}
              >
                <Card
                  productItem={item}
                  onClick={() => cardClick(item?.id)}
                  id={index}
                  className="4xl:w-auto 2xl:w-auto xl:w-auto w-auto"
                  weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  addable={item?.in_stock === 0 ? false : true}
                  showValidateModal={() => showProductDetail(item?.id)}
                />
              </SwiperSlide>
            );
          })}
          <NxtBtn className={"swiper-next"}>
            <MdChevronRight size={22} />
          </NxtBtn>
          <PrvBtn className={"swiper-prev"}>
            <MdChevronLeft size={22} />
          </PrvBtn>
        </Carousel>
      </ProductContent>
      <ProductContent>
        <Slider {...settings}>
          {productInfo?.map((info, index) => {
            return <SliderContent key={index}>{info.element}</SliderContent>;
          })}
        </Slider>
      </ProductContent>

      <OtherProduct>
        <ProductTitle>
          <span className="text-2xl font-bold font-raleway">
            {t('otherRecommendations')}
          </span>
        </ProductTitle>
        <Carousel
          autoplay={false}
          slidesPerGroup={1}
          slidesPerView={6}
          slidesperview1200={6}
          spacebetween1200={6}
          slidesperview1024={6}
          spacebetween1024={6}
          slidesperview768={4}
          spacebetween768={5}
          slidesperview567={2}
          spacebetween567={5}
          slidesperview480={2}
          spacebetween480={5}
          slidesperview280={2}
          spacebetween280={5}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
            disabledClass: ".swiper-dis",
          }}
        >
          {recommendedProductList && recommendedProductList?.map((item, index) => {
            return (
              <SwiperSlide
                className="mb-1 first:ml-0.5 ml-0 last:mr-1"
                key={item.id}
                style={{ width: "auto" }}
              >
                <Card
                  productItem={item}
                  onClick={() => cardClick(item?.id)}
                  id={index}
                  className="4xl:w-auto 2xl:w-auto xl:w-auto w-auto"
                  weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  addable={item?.in_stock === 0 ? false : true}
                  recommendtag={item?.in_stock === 0 ? t('outOfStock') : t('recommended')}
                  recommendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-yellow-500'}
                  showValidateModal={() => showProductDetail(item?.id)}
                />
              </SwiperSlide>
            );
          })}
          <NxtBtn className="swiper-next">
            <MdChevronRight size={25} />
          </NxtBtn>
          <PrvBtn className="swiper-prev">
            <MdChevronLeft size={25} />
          </PrvBtn>
        </Carousel>
      </OtherProduct>
    </Container>
  );
};

const Container = tw.div`
`;

const ProductContent = tw.div`
px-4
py-10
border-y
border-color235
`;

const ProductTitle = tw.div`
pb-6
text-color456
`;

const SliderContent = styled("div")`
   {
    width: 90% !important;
  }
`;

const OtherProduct = tw.div`
px-4
py-10
`;

const Btn = styled("button")`
   {
    top: 45%;
    right: 0;
    position: absolute;
    border-radius: 100%;
    background: rgba(255, 255, 255) !important;
    box-shadow: 1px 2px 6px 1.5px rgb(0, 0, 0, 0.1);
    z-index: 1;
    width: 45px;
    height: 45px;
    outline: none;
    border: none;
    &.slick-disabled {
      display: none !important;
    }
    &::before {
      font-size: 25px;
      color: #000;
      font-family: "Font Awesome 6 Free" !important;
      font-weight: 900;
    }
    &.slick-next:before {
      content: "\f054" !important;
    }
    &.slick-prev {
      left: 97.1%;
    }
    &.slick-prev:before {
      content: "\f053" !important;
    }
    @media (max-width: 768px) {
      top: 50%;
      width: 30px;
      height: 30px;
      &::before {
        font-size: 20px;
      }
      &.slick-next:before {
        content: "\f078" !important;
      }
      &.slick-prev {
        left: 91.5%;
        top: 40%;
      }
      &.slick-prev:before {
        content: "\f077" !important;
      }
    }
  }
`;

const NxtBtn = styled("button")`
   {
    top: 45%;
    right: 0;
    position: absolute;
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border: none;
    &:disabled {
      display: none;
    }
  }
`;

const PrvBtn = styled("button")`
   {
    top: 45%;
    left: 0;
    position: absolute;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border: none;
    &:disabled {
      display: none;
    }
  }
`;

export default ProductRecommend;
