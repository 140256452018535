import React from "react";
import tw from "tailwind-styled-components";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function Specification(props) {
  const { description } = props

  const { t } = useTranslation()

  return (
    <Container>
      <HeadingText>{t('description')}</HeadingText>
      <ProductContent>
        {description}
      </ProductContent>
      <CustomBtn>
        <AiOutlineFilePdf color="rgb(8 82 178)" />
        <Text>{t('downloadFactSheet')}</Text>
      </CustomBtn>
    </Container>
  );
}

const Container = tw.div`
flex 
flex-col
`;
const HeadingText = tw.span`
text-xl 
font-raleway 
font-bold
`;
const ProductContent = tw.span`
text-sm 
text-left
py-3
`;
const CustomBtn = tw.button`
flex items-center 
py-4
`;
const Text = tw.span`
text-linkColor
`;

export default Specification;
