import * as types from "./Types";

const initialState = {
  categoryData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATEGORIES:
      return {
        ...state,
        categoryData: action?.payload?.data?.data,
      };

    default:
      return state;
  }
};

export default reducer;
