import React from "react";
import { Swiper } from "swiper/react";
import { Pagination, Navigation, Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const Carousel = (props) => {
  const {
    children,
    slidesPerGroup,
    slidesPerView,
    autoplay,
    navigation,
    spacebetween1200,
    slidesperview1200,
    spacebetween1024,
    slidesperview1024,
    slidesperview768,
    spacebetween768,
    slidesperview567,
    spacebetween567,
    slidesperview480,
    spacebetween480,
    slidesperview280,
    spacebetween280,
    carouselclass,
    cssMode,
    freeMode,
    // handleEnd,
    // handleSlide,
    initialSlide,
    // slideToSlide,
  } = props;

  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={5}
      slidesPerGroup={slidesPerGroup}
      loop={false}
      cssMode={cssMode}
      freeMode={freeMode}
      pagination={false}
      navigation={navigation}
      autoplay={autoplay}
      modules={[Pagination, Navigation, Autoplay, FreeMode]}
      className={` max-h-fit ${carouselclass}`}
      // onReachEnd={handleEnd}
      initialSlide={initialSlide}
      // onSlideChange={handleSlide}
      // slideToClickedSlide={slideToSlide}
      breakpoints={{
        280: {
          slidesPerView: slidesperview280,
          spaceBetween: spacebetween280,
        },
        480: {
          slidesPerView: slidesperview480,
          spaceBetween: spacebetween480,
        },
        567: {
          slidesPerView: slidesperview567,
          spaceBetween: spacebetween567,
        },
        768: {
          slidesPerView: slidesperview768,
          spaceBetween: spacebetween768,
        },
        1024: {
          slidesPerView: slidesperview1024,
          spaceBetween: spacebetween1024,
        },
        1200: {
          slidesPerView: slidesperview1200,
          spaceBetween: spacebetween1200,
        },
      }}
      {...props}
    >
      {children}
    </Swiper>
  );
}

export default Carousel;
