import React from "react";
import tw from "tailwind-styled-components";
import { Button, HeadingText, Ratings, Basic, BasicBold } from "@components";
import tempIcon from "@assets/images/tempIcon.png";
import productDefaultImage from "@assets/images/default.png"
import { useSelector } from "react-redux";
import pack3CostIcon from "@assets/images/pack3Cost.png";
import pack6CostIcon from "@assets/images/pack6Cost.png";
import pack12CostIcon from "@assets/images/pack12Cost.png";
import plusIcon from "@assets/images/plusIcon.png";
import bellIcon from "@assets/images/bell.png";
import ringBell from "@assets/images/ringBell.png";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

const ProductInfo = (props) => {
  const {
    productId,
    productImg,
    productName,
    inStock,
    rating,
    productTitle,
    productPrice,
    privatePrice,
    gastronomyPrice,
    expiryDate,
    addToWishListProduct,
    addToCartItem,
    tempIconTitle,
    showTempIcon,
    packSystem,
    packs_click,
    inStockContainer
  } = props;

  const productItemDetail = useSelector((state) => state.products.productItemDetail);

  return (
    <Container>
      <ProductImage>
        <ImgContainer>
          {productImg ? <img
            src={productImg}
            alt=""
            className="w-full max-h-60 object-contain pointer-events-none"
          /> : <img src={productDefaultImage} className="max-h-60" />}
          {productItemDetail?.pack_value !== 1 &&
            <WishList onClick={addToWishListProduct}>
              {productItemDetail?.wish_list?.length > 0 ?
                <AiFillHeart size={30} color={"red"} />
                :
                <AiOutlineHeart size={30} />
              }
            </WishList>}
          {showTempIcon && <Storage>
            <abbr title={tempIconTitle}>
              <img
                src={tempIcon}
                alt=""
                className="max-w-[35%] mx-auto pointer-events-none"
              />
            </abbr>
            <BasicBold>Cooled</BasicBold>
          </Storage>}
        </ImgContainer>
      </ProductImage>
      <ProductDetail>
        <ProductBox>
          <ProductContent>
            <HeadingText>{productName}</HeadingText>
            <ProductRating>
              <div className="flex">
                <div className="border rounded-2 py-[4px] px-[6px]">
                  <Basic>{`${inStock} Stk An Larger`}</Basic>
                </div>
                {inStockContainer && <div className="border border-gray-300 rounded-2 py-[4px] px-[6px] mx-4 bg-gray-100 ">
                  <Basic className="font-extrabold">{'Not In Stock'}</Basic>
                </div>}
              </div>
              <Ratings
                totalStars={5}
                valueRating={rating == null ? -1 : rating}
                label={"Rating"}
                productName={productName}
                productImg={productImg}
                productId={productId}
              />
            </ProductRating>
          </ProductContent>
          <Basic>{productTitle}</Basic>
          <PackContainer>
            {packSystem?.map((item, index) => {
              const packImage = [pack3CostIcon, pack6CostIcon, pack12CostIcon];
              var randomIcon = packImage[Math.floor(Math.random() * packImage.length)];
              return item?.id != productItemDetail.id && item?.pack_value !== 1 && item?.status !== "inactive" &&
                <Button
                  icon={randomIcon}
                  key={index}
                  iconStyle={{
                    width: "auto",
                    height: "17px",
                    marginLeft: item.margin,
                  }}
                  className={`w-full justify-between my-1 border border-b2bButton rounded hover:border-black `}
                  title={`${item?.pack_value}-Pack CHF ${item.price ? item.price : 'N/A'}`}
                  titleStyle={{
                    fontWeight: "600",
                    fontFamily: "Segoe UI, sans-serif",
                    fontSize: "16px",
                  }}
                  onClick={() => packs_click(item)}
                />
            })}
          </PackContainer>
          <ProductPrice>
            <div>
              <div className="flex flex-col">
                <BasicBold style={{ fontSize: "18px" }}>{productPrice}</BasicBold>
                <Basic className="font-normal">
                  excl. Vat incl. delivery cost
                </Basic>
              </div>
              <div className="flex my-2 flex-wrap">
                <div className="flex items-center">
                  <Basic className="font-bold">
                    Private
                  </Basic>
                  {privatePrice && <BasicBold className="px-2" style={{ fontSize: "18px" }}>{privatePrice}</BasicBold>}
                </div>
                <div className="flex items-center">
                  <Basic className="font-bold">
                    Gastronomy
                  </Basic>
                  {gastronomyPrice && <BasicBold className="px-2" style={{ fontSize: "18px" }}>{gastronomyPrice}</BasicBold>}
                </div>
              </div>
            </div>
            {productItemDetail?.in_stock > 0 ?
              <>
                {productItemDetail?.pack_value !== 1
                  &&
                  <Button
                    icon={plusIcon}
                    iconStyle={{ width: "auto", height: "17px" }}
                    title={productItemDetail?.cart?.length > 0 ? "Added in cart" : "Add to cart"}
                    disabled={productItemDetail?.cart?.length > 0 ? true : false}
                    className={
                      "bg-white shadow-cartBtn items-center rounded-[30px] w-auto"
                    }
                    titleStyle={{
                      fontWeight: "600",
                      fontFamily: "Segoe UI, sans-serif",
                      fontSize: "16px",
                      padding: "0 0 0 6px",
                    }}
                    onClick={addToCartItem}
                  />}
              </>
              :
              <Button
                icon={productItemDetail?.isNotified ? ringBell : bellIcon}
                iconStyle={{ width: "auto", height: "17px" }}
                title={productItemDetail?.isNotified ? "Notified" : "Notify"}
                className={
                  "bg-white shadow-cartBtn items-center rounded-[30px] w-auto"
                }
                titleStyle={{
                  fontWeight: "600",
                  fontFamily: "Segoe UI, sans-serif",
                  fontSize: "16px",
                  padding: "0 0 0 6px",
                }}
                onClick={addToCartItem}
              />}
          </ProductPrice>
          <ProductExpiry>
            <span className="pr-5">Best Before Date:</span>
            <span>{expiryDate}</span>
          </ProductExpiry>
        </ProductBox>
      </ProductDetail>
    </Container>
  );
};

const Container = tw.div`
flex
lg:flex-row
flex-col
items-center
py-[2%]
sm:px-4
lg:px-0
`;

const ImgContainer = tw.div`
lg:w-[440px]
lg:h-[440px]
flex
justify-center
items-center
`;

const ProductImage = tw.div`
flex
lg:w-1/2
sm:w-full
justify-center
relative
z-0
lg:my-0
sm:my-6
my-6
`;

const ProductDetail = tw.div`
lg:w-1/2
md:w-full
sm:w-full
py-0
2xl:pr-16
xl:pr-12
`;

const ProductBox = tw.div`
flex
flex-col
`;

const ProductContent = tw.div`
flex
flex-col
justify-between
pb-4
`;

const ProductRating = tw.div`
flex
flex-row
justify-between
py-2
`;

const ProductPrice = tw.div`
flex
flex-row
justify-between
items-center
py-3
`;

const PackContainer = tw.div`
flex 
flex-col
items-center
justify-between
py-4
`;

const WishList = tw.div`
absolute 
top-0 
lg:right-7
sm:right-6
right-0
cursor-pointer
`;

const Storage = tw.div`
absolute 
bottom-0 
lg:right-7
sm:right-6
right-0
`;

const ProductExpiry = tw.div`
flex
`;

export default ProductInfo;