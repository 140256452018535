import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Card, ValidationModal, SideBar, Footer } from "@components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProducts } from "@store";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

const ViewMore = () => {
  const [state, setState] = useState({
    isValidateModal: false,
    isValue: []
  });
  const { isValidateModal, isValue } = state;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search)
  const type = params.get('type')
  const keyword = params.get('keyword')

  const recommendationProduct = useSelector((state) => state.home.recommendationProduct);
  const token = useSelector((state) => state.user.token);
  const salesProduct = useSelector((state) => state.home.salesProduct);
  const newReleaseProduct = useSelector((state) => state.home.newReleaseProduct);
  const trendingProduct = useSelector((state) => state.home.trendingProduct);

  useEffect(() => {
    if (type == 'sales-products') {
      setState((prev) => ({ ...prev, isValue: salesProduct }))
    } else if (type == 'recommendation-products') {
      setState((prev) => ({ ...prev, isValue: recommendationProduct }))
    } else if (type == 'new-release-products') {
      setState((prev) => ({ ...prev, isValue: newReleaseProduct }))
    } else if (type == 'trending-products') {
      setState((prev) => ({ ...prev, isValue: trendingProduct }))
    } else if (type == 'search') {
      let payload = `search=${keyword}`;
      dispatch(getProducts(payload)).then((res) => {
        setState((prev) => ({ ...prev, isValue: res?.payload?.data?.data }))
      })
    }
  }, [keyword])

  const cardClick = (id) => {
    navigate(`/product/${id}`, { state: id });
  };


  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const { t } = useTranslation();

  return (
    <div className="flex">
      <SideMenuContent>
        <SideBar />
      </SideMenuContent>
      <ProductDes>
        {isValue && isValue?.length === 0 ?
          <div className="flex flex-col justify-center items-center mt-20">
            <MdOutlineProductionQuantityLimits
              size={100}
            />
            <TitleText>{"We couldn't find any items that match your search."}</TitleText>
          </div>
          :
          <ViewMoreProduct>
            <div
              className="m-6 grid gap-4 xl:grid-cols-6 lg:grid-cols-4 md:grid-cols-3 grid-cols-2">
              {isValue && isValue?.map((item, index) => {
                return (
                  <Card
                    productItem={item}
                    key={index}
                    onClick={() => cardClick(item.id)}
                    id={index}
                    className="w-auto first:left-0"
                    weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                    productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                    addable={item?.in_stock === 0 ? false : true}
                    recommendtag={type == 'recommendation-products' && t('recommended')}
                    recommendtagColor={type == 'recommendation-products' && 'bg-yellow-500'}
                    newtag={type == 'new-release-products' && t('new')}
                    newtagColor={type == 'new-release-products' && 'bg-red-500'}
                    trendtag={type == 'trending-products' && t('trend')}
                    trendtagColor={type == 'trending-products' && 'bg-black'}
                    salestag={type == 'sales-products' && t('sale')}
                    salestagColor={type == 'sales-products' && 'bg-red-500'}
                    showValidateModal={isShowValidModal}
                  />
                );
              })}
            </div>
            <ValidationModal
              isOpenModal={isValidateModal}
              close={() =>
                isValidateModal &&
                setState((prev) => ({ ...prev, isValidateModal: false }))
              }
              closeModal={() =>
                isValidateModal &&
                setState((prev) => ({ ...prev, isValidateModal: false }))
              }
            />
          </ViewMoreProduct>}
        <Footer className="mt-[20%] mx-5" />
      </ProductDes>
    </div>
  );
};

const SideMenuContent = tw.div`
xl:w-[15.5%] 
lg:sticky
lg:top-0
sticky
top-0
md:top-0
h-screen
flex flex-col
z-30
`;

const ViewMoreProduct = tw.div`
w-full
relative
`;

const TitleText = tw.h1`
font-bold 
text-3xl 
py-6
text-center
`;

const ProductDes = tw.div`
xl:w-6/6 
w-full
h-full 
`;

export default ViewMore;