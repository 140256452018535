import React from "react";
import tw from "tailwind-styled-components";
import { useSelector } from "react-redux";
import { Skeleton } from "@components";

const Banner = ({ isLoad }) => {
  const homeBanner = useSelector((state) => (state?.home?.homeBanner?.top))

  return (
    <BgContainer>
      {!homeBanner ? (
        <div className="self-center w-screen">
          <Skeleton loading={isLoad} />
        </div>
      ) : (
        <Img src={homeBanner} alt="" />
      )}
    </BgContainer>
  );
};

const BgContainer = tw.button`
flex
mt-8
mb-6
`;

const Img = tw.img`
w-full 
max-h-[25pc]
object-cover
object-center
overflow-hidden
`;

export default Banner;