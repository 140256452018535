import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBBbcqsDfquzdS05gb7UFtm9mpIxQs4rO0",
  authDomain: "mapl-9d510.firebaseapp.com",
  projectId: "mapl-9d510",
  storageBucket: "mapl-9d510.appspot.com",
  messagingSenderId: "1039558203045",
  appId: "1:1039558203045:web:ff02d5554f9b296de31f4c",
  measurementId: "G-CCLTX3YZEZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp)

export const getTokens = (setTokenFound) => {
  return getToken(messaging, { vapidKey: "BNQx4g1P-l-CnVmdm7Ev3GmOv7uZ-ndxYEw8OEXCKMVt8R-VCA0khqMfaAcw9MQJaKBH6nbmme8XIVss1d_qmdE" }).then((currentToken) => {
    if (currentToken) {
      setTokenFound(true, currentToken);
    } else {
      setTokenFound(false, 'Error');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
  })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });