import tw from "tailwind-styled-components";
import React from "react";
import { PrimaryText } from "@components";

const Input = (props) => {
  const {
    containerStyle,
    title,
    style,
    requiredTitle,
    titlestyle,
    headericon,
    headericonHoverText,
    editbutton,
    editButtonClick,
    editButtonDisabled,
    editButtonClassName,
    verifybuttontitle,
    verifybuttonclassname,
    verifybuttonclick,
    verifybuttondisabled,
    inputicon,
    inputStyle,
    disabled,
    hideandshowpasswordonclick,
    hideandshoweyeicon,
    EyeIconClassName,
    errortext,
    required,
    bottomlabel,
    bottomclick,
  } = props;

  const checkOpacity = verifybuttondisabled ? 0.5 : 1;

  const styles = {
    ...style,
    opacity: checkOpacity,
  };

  return (
    <div className={`${containerStyle} py-1`}>
      <div className="flex justify-between">
        <div className="flex">
          {title && (
            <PrimaryText
              style={titlestyle}
              className={"text-[12px] font-sans font-semibold"}
            >
              {title}
              {requiredTitle && (
                <span className={`${requiredTitle} mx-[1px]`}>{"*"}</span>
              )}
            </PrimaryText>
          )}
          {headericon && (
            <div className="flex items-center">
              <abbr title={headericonHoverText} className="text-logoColor">
                <VerifyIcon src={headericon} className="px-[6px]" />
              </abbr>
            </div>
          )}
        </div>
        <div>
          {verifybuttontitle && (
            <VerifyButton
              className={`${verifybuttonclassname} disabled:cursor-no-drop`}
              style={styles}
              onClick={verifybuttonclick}
              disabled={verifybuttondisabled ? true : false}
            >
              {verifybuttontitle}
            </VerifyButton>
          )}
          {editbutton && (
            <>
              <VerifyButton
                className={`${editButtonClassName} disabled:cursor-no-drop pl-1 text-blue`}
                onClick={editButtonClick}
                disabled={editButtonDisabled ? true : false}
              >
                {`/ Edit`}
              </VerifyButton>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row relative">
        {inputicon && (
          <InputIcon
            src={inputicon}
            className="top-[1.2pc] absolute z-10 ml-3"
          />
        )}
        <CustomInput
          style={inputStyle}
          required={required ? true : false}
          disabled={disabled}
          className={`${inputicon ? "pl-11" : null} relative flex items-center`}
          autoComplete="off"
          {...props}
        />
        <div
          onClick={hideandshowpasswordonclick}
          className={`${EyeIconClassName} absolute right-0 items-center top-[1.8pc] mr-1 justify-center focus:outline-0`}
        >
          {hideandshoweyeicon && (
            <div className="w-5 h-5">{hideandshoweyeicon}</div>
          )}
        </div>
      </div>
      <ErrorText>{errortext}</ErrorText>
      {bottomlabel ? (
        <div className={`justify-end mt-[-4px] flex`}>
          <PrimaryText
            className={`text-[9px] font-sans text-color102`}
          >
            {bottomlabel}
          </PrimaryText>
            <ResendButton
              className={"text-[9px]"}
              onClick={bottomclick}
            >
              {"Resend"}
            </ResendButton>
        </div>
      ) : null}
    </div>
  );
};

const CustomInput = tw.input`
w-full
p-3
pl-4
my-3
text-[12px]
border
focus:outline-0
drop-shadow-md
disabled:cursor-no-drop
`;

const VerifyButton = tw.button`
text-[11px]
font-sans
font-semibold
`;

const InputIcon = tw.img`
w-[30px]
h-[30px]
`;

const VerifyIcon = tw.img`
h-[10px]
self-center
`;

const ResendButton = tw.button`
flex
cursor-pointer
focus:outline-0
px-1
underline
font-sans
self-center
`;

const ErrorText = tw.p`
text-logoColor
text-[10px]
mt-[-5px]
font-semibold
font-sans
`;

export default Input;