import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Basic } from "@components/Text";
import { IoClose } from "react-icons/io5";
import categoriesIcon from "@assets/images/categoriesIcon.png";
import { useDispatch } from "react-redux";
import { getProducts } from "@store";

const Options = (props) => {
  const { productFilterdData } = props;

  const [Open, setOpen] = useState(false);

  const [state, setState] = useState({
    checked: [],
  });
  const { checked } = state;

  const dispatch = useDispatch();

  const handleMenu = () => {
    setOpen(!Open);
  };

  const resetFilter = () => {
    setState((prev) => ({ ...prev, checked: [] }));
  };

  const inputCheckboxClickHandler = (value, item) => {
    let obj = {
      [item.key]: [value]
    }
    let newChecked = [...checked]
    if (newChecked.length < 1) {
      newChecked?.push(obj)
    }
    else {
      const index = checked.findIndex((data) => {
        const keyName = Object.keys(data)
        return keyName[0] == item?.key
      })
      if (index !== -1) {
        const newArr = newChecked[index][item.key]
        const itemIndex = newArr.findIndex((itemData) => itemData == value)
        if (itemIndex !== -1) {
          let newValueData = [...newChecked[index][item.key]]
          newValueData.splice(itemIndex, 1)
          newChecked[index][item.key] = newValueData
        } else {
          newChecked[index][item.key] = [...newChecked[index][item.key], value]
        }
      }
      else {
        newChecked?.push(obj)
      }
    }
    setState((prev) => ({ ...prev, checked: newChecked }));
    let filterObj = {}
    newChecked.map((item) => {
      filterObj = { ...filterObj, ...item }
    })
    dispatch(getProducts(filterObj))
  };
  
  return (
    <div>
      <CustomButton onClick={handleMenu}>
        <img src={categoriesIcon} alt="" className="w-6 h-6 mx-auto mt-2" />
      </CustomButton>
      <OptionContainer $openMenu={Open}>
        <IoClose
          size={30}
          onClick={handleMenu}
          className={
            "absolute z-50 right-2 top-4 hover:bg-logoColor hover:text-white xl:hidden lg:flex flex"
          }
        />
        <Basic className="lg:text-2xl pt-[8%]" style={{ fontWeight: "700" }}>
          Filters
        </Basic>
        <ResetButton onClick={resetFilter}>{"Reset all filter"}</ResetButton>
        {productFilterdData?.map((item, inx) =>
          item?.value?.length > 0 &&
          <OptionContent key={inx}>
            <Basic
              className={"lg:text-[16px] md:text-[14px] sm:text-[12px] py-3"}
              style={{ fontWeight: "700" }}
            >
              {item?.title}
            </Basic>
            {item?.value?.map((filterItem, index) => {
              return (
                <Checkbox key={index}>
                  <div>
                    <input
                      type="checkbox"
                      className={`accent-black`}
                      id="filterCheck"
                      onChange={(event) => inputCheckboxClickHandler(filterItem, item)}
                    // checked={checked.includes(item)}
                    />
                    <Basic className="ml-3">{filterItem}</Basic>
                  </div>
                </Checkbox>
              );
            })}
          </OptionContent>
        )}
      </OptionContainer>
    </div>
  );
};

const OptionContainer = tw.div`
${(p) => (p.$openMenu ? "left-0" : "-left-[500px]")}
flex
flex-col
xl:w-auto
pl-3
overflow-y-scroll
h-screen
bg-asideBarBgColor
`;

const ResetButton = tw.button`
text-right 
text-color139 
hover:underline 
cursor-pointer 
pr-6
border-0
bg-transparent
`;

const OptionContent = tw.div`
flex
flex-col
py-2
`;

const Checkbox = tw.div`
flex
flex-row
justify-between
mx-0
py-1
`;

const CustomButton = tw.button`
absolute
xl:hidden
peer 
top-28
left-0
w-10
h-10
hover:bg-logoColor
`;

export default Options;