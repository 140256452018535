import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { IoClose } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import categoryDefault from "@assets/images/categoriesIcon/catergoryDefault.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCategories } from "@store";

const SideBarMenu = () => {
  const [state, setState] = useState({
    open: false,
  });
  const { open } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories())
  }, [])

  const handleMenu = () => {
    setState((prev) => ({ ...prev, open: !open }));
  };

  const handleImg = (e) => {
    e.target.src = categoryDefault
  }

  const { t } = useTranslation();

  const categoryData = useSelector((state) => state.category.categoryData);

  return (
    <Container className={'h-screen lg:overflow-y-scroll'}>
      <CustomButton onClick={handleMenu}>
        <img
          src={require("@assets/images/categoriesIcon.png")}
          alt=""
          className="w-4 h-4 mx-auto"
        />
      </CustomButton>
      <SideBarContent $openMenu={open}>
        <SideBarBox>
          <BasicText style={{ fontWeight: "700" }}>{t('content.all_category')}</BasicText>
          <IoClose
            size={30}
            onClick={handleMenu}
            className={
              "absolute z-50 right-2 top-4 hover:bg-logoColor hover:text-white xl:hidden lg:flex flex"
            }
          />
          <SideBarUl>
            {categoryData?.map((item, index) => {
              return (
                <Link
                  to={`/products/${item.id}`}
                  state={{ item: item }}
                  className={`flex items-center py-2 px-4 xl:px-4 xl:justify-start lg:px-1 lg:justify-start hover:bg-color235 hover:font-bold cursor-pointer z-40`}
                  key={index}
                >
                  <SideBarIcon>
                    {item.icon ?
                      <img src={item.icon} alt=" " onError={(e) => { handleImg(e) }} /> :
                      <img src={categoryDefault} alt="" />
                    }
                  </SideBarIcon>
                  <p className="pl-3 xl:flex lg:flex flex">{item.name}</p>
                </Link>
              );
            })}
          </SideBarUl>
        </SideBarBox>
      </SideBarContent>
    </Container>
  );
};

const Container = tw.div`
flex
flex-col
lg:items-end
justify-end
items-start
`;

const SideBarContent = tw.div`
${(p) =>
    p.$openMenu
      ? "left-0 before:w-screen before:absolute"
      : "-left-[500px] before:w-0"}
flex
flex-col
xl:h-full
scrollbar-none
border-r
xl:w-full
xl:relative
xl:top-0
xl:left-0
peer-transition
ease-out
delay-150
duration-200
fixed top-28
bottom-0
min-h-0
before:bg-black
before:z-0
before:opacity-50
before:h-full
xl:before:h-0
before:top-0
`;

const SideBarBox = tw.div`
flex-1
sticky
top-0
z-40
h-full
`;

const CustomButton = tw.button`
absolute
xl:hidden
peer 
top-0
left-0
w-10
h-10
`;

const BasicText = tw.span`
text-[22px] 
flex 
pl-5
pt-4
sticky
`;

const SideBarUl = tw.ul`
xl:flex
flex-col
`;

const SideBarIcon = tw.div`
flex 
self-center 
xl:max-w-[20px]
lg:max-w-[30px] 
sm:max-w-[25px] 
max-w-[25px]
`;

export default SideBarMenu;
