import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { get, set } from "@utils";
import en from "./en.json";
import de from "./de.json";
import fr from "./fr.json";

const languageDetector = {
  type: "languageDetector",
  async: true,
  init: (_services, _detectorOptions, _i18nextOptions) => {
    /* use services and options */
  },
  detect: async (callback) => {
    let reggaefyLanguage = await get("MAPL_LANGUAGE");
    callback(reggaefyLanguage || "en");
    return;
  },
  cacheUserLanguage: (language) => {
    set("MAPL_LANGUAGE", language);
  },
};

i18n
  .use(LanguageDetector)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
  });

i18n.changeLanguage("en");
export { en };