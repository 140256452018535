export const TOKEN = "TOKEN";
export const Login = "LOGIN";
export const SOCIAL_USER_SIGNUP = "SOCIAL_USER_SIGNUP";
export const REGISTER_USER = "REGISTER_USER";
export const USER_PROFILE = "USER_PROFILE";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_OTP = "VERIFY_OTP";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const ADD_INVOICE_DETAIL = "ADD_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const ADD_SUGGEST_LOCATION = "ADD_SUGGEST_LOCATION";
export const CREATE_PAYMENT_TRANSACTION_ID = "CREATE_PAYMENT_TRANSACTION_ID";
export const UPLOAD_DEVICE_TOKEN = "UPLOAD_DEVICE_TOKEN";
export const LOGOUT = "LOGOUT";