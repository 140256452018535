import * as types from "./Types";
import { homeApi } from "@services";

export const getRecommendedProduct = () => {
  return {
    type: types.GET_RECOMMENDATION_PRODUCT,
    payload: homeApi.getRecommendedProduct(),
  };
};

export const getNewReleaseProduct = () => {
  return {
    type: types.GET_NEWRELEASE_PRODUCT,
    payload: homeApi.getNewReleaseProduct(),
  };
};

export const getTrendingProduct = () => {
  return {
    type: types.GET_TRENDING_PRODUCT,
    payload: homeApi.getTrendingProduct(),
  };
};

export const getSalesProduct = () => {
  return {
    type: types.GET_SALES_PRODUCT,
    payload: homeApi.getSalesProduct(),
  };
};

export const getHomeBanner = () => {
  return {
    type: types.GET_HOME_BANNER,
    payload: homeApi.getHomeBanner(),
  };
};

export const getOfferPopup = () => {
  return {
    type: types.GET_OFFER_POPUP,
    payload: homeApi.getOfferPopup(),
  };
};

export const popularSearch = () => {
  return {
    type: types.POPULAR_SEARCH,
    payload: homeApi.popularSearch(),
  };
};

export const getStoreSchedule = () => {
  return {
    type: types.STORE_SCHEDULE,
    payload: homeApi.getStoreSchedule(),
  };
};

export const getUserNotification = () => {
  return {
    type: types.USER_NOTIFICATION,
    payload: homeApi.getUserNotifications(),
  };
};