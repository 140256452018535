import React from 'react';
import tw from "tailwind-styled-components";
import { useSelector } from 'react-redux';
import { animated } from "@react-spring/web";
import emptyNotification from "@assets/images/notifications.png";
import { formatedDateTime } from "@utils"
import { FcAlarmClock } from 'react-icons/fc'
import clientImage from '@assets/images/swissan.jpeg'
import { useNavigate } from 'react-router-dom';
// import Pusher from "pusher-js";

const NotificationModal = (props) => {
  const { closeModal, isOpenModal } = props;

  // useEffect(() => {
  //   const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  //     cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  //     encrypted: false
  //   })

  //   const channel = pusher.subscribe('notification')

  //   channel.bind('new', function (item) {
  //     console.log('itemss', item);
  //     // setState((prev) => ({ ...prev, notificationData: [item.data, ...prev.notificationData] }))
  //   })

  //   return (() => {
  //     pusher.unsubscribe('notification')
  //   })
  //   // eslint-disable-next-line
  // }, [])

  const hidden = !isOpenModal ? "hidden" : "flex";

  const userNotification = useSelector((state) => state.home.userNotification)

  const navigate = useNavigate();

  const notificationClick = () => {
    navigate('/profile/help-and-feedback/order-history')
  }

  const gotoDetailPage = (item) => {
    let orderData = JSON.parse(item?.meta)
    if (orderData?.product_id) {
      navigate(`/product/${orderData.product_id}`, { productId: orderData.product_id })
    }
    // else if (orderData?.order_id) {
    //   navigate('OrderDetails',
    //     {
    //       productDetailId: orderData.order_id,
    //       orderStatusData: GlobalProviders.orderStatusData
    //     })
    // }
  }

  return (
    <animated.div
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 z-[1000]`}
      onClick={closeModal}
    >
      <animated.div onClick={(e) => e.stopPropagation()}>
        <Container>
          <div className='flex justify-between'>
            <h1 className='text-[20px] font-bold'>Notifications</h1>
            <h1 className='text-[11px] self-center underline font-semibold cursor-pointer' onClick={notificationClick}>Mark all as read</h1>
          </div>
          <div>
            {userNotification?.length === 0 ?
              <div className="flex flex-col self-center justify-center items-center my-10">
                <img
                  src={emptyNotification}
                  alt=""
                  className="w-[100px] h-[100px] mx-auto"
                />
                <TitleText>{"There is nothing in your notification!"}</TitleText>
              </div>
              : userNotification && userNotification?.map((item, index) => (
                <div key={index} onClick={() => gotoDetailPage(item)}>
                  <div className="flex flex-col my-4 mb-0 hover:bg-color249 cursor-pointer p-2">
                    <div className='flex'>
                      <img src={clientImage} alt="" className="w-[2pc] h-[2pc] rounded-full self-center mr-2" />
                      <h1 className='font-normal'>{item?.message}</h1>
                    </div>
                    <div className='flex py-2'>
                      <FcAlarmClock className='self-center mr-2' />
                      <p>{formatedDateTime(item?.created_at)}</p>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
          </div>
        </Container>
      </animated.div>
    </animated.div>
  )
}

const Container = tw.div`
bg-white
shadow-cartShadow
flex
flex-col
border
overflow-scroll
scrollbar-none
max-h-[30pc]
md:w-[27pc]
top-[2.5pc]
sm:w-[70%]
p-5
absolute
right-16
rounded-sm
`;

const TitleText = tw.h1`
font-bold 
text-xl 
py-6
text-center
`;

export default NotificationModal