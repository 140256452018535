import React, { useState } from 'react'
import tw from "tailwind-styled-components";
import { get } from "@utils";
import i18next from "i18next";
import { animated } from "@react-spring/web";
import { Input, PrimaryText } from "@components"
import swissFlag from "@assets/images/flag-switzerland.png";

const LanguageModal = (props) => {
  const { closeModal, isOpenModal } = props;

  const languages = [
    { lang: "Deutsche", i18n: "de" },
    { lang: "English", i18n: "en" },
    { lang: "Français", i18n: "fr" },
  ];

  const [state, setState] = useState({
    lang: (() => languages.find((item) => item.i18n === get("MAPL_LANGUAGE") ?? "en").lang)(),
  })
  const { lang } = state;

  const hidden = !isOpenModal ? "hidden" : "flex";

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  const onChangeHandler = (event) => {
    const selectLng = languages.find((item) => item.lang === event.target.value);
    setState({ ...state, lang: selectLng.lang });
    changeLanguage(selectLng.i18n);
  };

  return (
    <animated.div
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 z-[1000]`}
      onClick={closeModal}
    >
      <animated.div onClick={(e) => e.stopPropagation()}>
        <LanguageContainer>
          <Input
            title={"Delivery to"}
            inputicon={swissFlag}
            disabled
            defaultValue={"Switzerland"}
          />
          <div>
            <PrimaryText className={"text-[12px] font-sans font-semibold"}>
              {"Language"}
            </PrimaryText>
            <SelectOption
              name="lang"
              value={lang}
              className="p-1"
              onChange={onChangeHandler}
            >
              {languages?.map((item, index) => (
                <option
                  key={index}
                  value={item.lang}
                  disabled={item.lang ? false : true}
                >
                  {item.lang}
                </option>
              ))}
            </SelectOption>
          </div>
        </LanguageContainer>
      </animated.div>
    </animated.div>
  )
}

const LanguageContainer = tw.div`
flex
flex-col
bg-white
shadow-cartShadow
top-[2.5pc]
absolute
right-4
p-5
rounded-sm
`;

const SelectOption = tw.select`
w-full
my-3
h-[2.48pc]
items-center
self-center
p-2
text-[12px]
border
focus:outline-none
drop-shadow-md
`;

export default LanguageModal