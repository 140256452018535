import React from "react";
import tw from "tailwind-styled-components";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import googlePlayButton from "@assets/images/google-play-button.png";
import appsStoreButton from "@assets/images/apps-store-button.png";
import { PrimaryText } from "@components";
import theme from "@theme";
import paymentImg from "./PaymentImg";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { className } = props

  const { t } = useTranslation();

  const gotoReturn = () => {
    window.open("/return", "_self");
  };

  const gotoTermsAndCondition = () => {
    window.open("/terms-and-conditions", "_self");
  };

  const gotoPrivacyPolicy = () => {
    window.open("/privacy-policy", "_self");
  };

  const gotoShipppingMethod = () => {
    window.open("/shipping-method", "_self");
  };

  const gotoImpressum = () => {
    window.open("/impressum", "_self");
  };

  return (
    <Container className={className} >
      <footer>
        <hr className="mb-8 sm:mx-auto" />
        <div
          className={"grid sm:grid-cols-2 lg:grid-cols-3 gap-[0vw]"}
        >
          <div className="flex flex-col text-center sm:text-start">
            <Heading id={"contact"}>{t("header.contact")}</Heading>
            <PrimaryText>MAPL GmbH</PrimaryText>
            <PrimaryText>Soodstrasse 52</PrimaryText>
            <PrimaryText>8134 Adliswil</PrimaryText>
            <PrimaryText className="mb-4">Switzerland</PrimaryText>
            <PrimaryText>Customer service</PrimaryText>
            <PrimaryText>+41 00 000 00 00</PrimaryText>
            <PrimaryText>info@mapl.ch</PrimaryText>
          </div>
          <div className="flex flex-col  text-center sm:text-start">
            <Heading id={"help"}>{t("header.help")}</Heading>
            <ButtonText onClick={gotoReturn}>{t("footer.return")}</ButtonText>
            <ButtonText onClick={gotoTermsAndCondition}>
              {t("footer.terms_and_condition")}
            </ButtonText>
            <ButtonText onClick={gotoPrivacyPolicy}>
              {t("footer.privacy")}
            </ButtonText>
            <ButtonText onClick={gotoShipppingMethod}>
              {t("footer.shipping_methods")}
            </ButtonText>
            <ButtonText onClick={gotoImpressum}>
              {t("footer.impressum")}
            </ButtonText>
          </div>
          <div className="flex flex-col text-center sm:text-start relative sm:pt-10 md:pt-0">
            <Heading id={"about"}>{t("footer.about_us")}</Heading>
            <ButtonText>{t("footer.about_us")}</ButtonText>
            <ButtonText>{t("footer.story")}</ButtonText>
            <ButtonText>{t("footer.sustainability")}</ButtonText>
            <ButtonText>{t("footer.private_customers")}</ButtonText>
            <ButtonText>{t("footer.media_services")}</ButtonText>
            <div className="flex lg:absolute bottom-0 lg:justify-start lg:mt-0  mt-4 justify-end ">
              {/* <div className="md:flex md:mt-16 sm:mt-4"> */}
              <Links className="mr-4" onClick={() => alert("This is alert")}>
                <LinksLogo src={googlePlayButton} alt="" />
              </Links>
              <Links onClick={() => alert("This is alert")}>
                <LinksLogo src={appsStoreButton} alt="" />
              </Links>
            </div>
          </div>
        </div>
        <hr className="mt-8 sm:mx-auto" />
        <div
          className={"grid sm:grid-cols-1 lg:grid-cols-3 lg:gap-[14vw] gap-[6vw] py-6"}
        >
          <div className="flex justify-center">
            {paymentImg?.map((item, index) => (
              <PaymentContainer key={index}>
                <Img src={item.img} />
              </PaymentContainer>
            ))}
          </div>
          <div className="flex justify-center items-center md:w-[150%]">
            <p className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2022
            </p>
            <p className="text-sm text-maplsite mx-1 sm:text-center">MAPL.CH</p>
            <p className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              All Rights Reserved.
            </p>
          </div>
          <div className="flex md:justify-end justify-center self-center">
            <ul className="flex">
              <li>
                <FaFacebookF size={20} color={theme.colors.fbColor} />
              </li>
              <li>
                <FaInstagram size={20} color={theme.colors.instaColor} />
              </li>
              <li>
                <FaTwitter size={20} color={theme.colors.twitterColor} />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </Container>
  );
};

const Container = tw.div`
items-end
`;

const Links = tw.button`
flex
focus:outline-none
`;

const LinksLogo = tw.img`
w-[7pc]
h-[4vh]
`;

const Heading = tw.p`
mb-4 
text-[16px]
font-semibold   
font-['Raleway']
`;

const ButtonText = tw.button`
hover:underline
flex
justify-start
self-center 
sm:self-start
`;

const PaymentContainer = tw.div`
w-full
h-full
mr-1
`;

const Img = tw.img`
w-full
h-full
object-contain
`;

export default Footer;