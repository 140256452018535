import React, { useState } from "react";
import tw from "tailwind-styled-components";
import {
  Footer,
  SideBar,
  Container,
  Carousel,
  Card,
  HeadingText,
  ValidationModal
} from "@components";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import offerBanner from "@assets/images/offerBanner.jpg"

const OfferDetail = () => {

  const [state, setState] = useState({
    isValidateModal: false
  })
  const { isValidateModal } = state;

  const navigate = useNavigate();
  const { t } = useTranslation()

  const allProductList = useSelector((state) => state.products.allProductList);
  const offerPopup = useSelector((state) => state.home.offerPopup);
  const allProductListItem = allProductList?.filter((item) => item?.offer_id);
  const token = useSelector((state) => state.user.token);

  const cardClick = (id) => {
    navigate(`/product/${id}`, { state: id });
  };

  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const navigateViewMore = () => {
    navigate('/view-more?offer-products')
  }

  return (
    <>
      <Container>
        <SideMenuContent>
          <SideBar />
        </SideMenuContent>
        <HomeScreenContent>
          {offerPopup?.map((item, index) => {
            let img = Math.floor(Math.random() * item?.image?.length);
            return (
              <div className="py-4" key={index}>
                {item?.image[img]?.image ? (
                  <Banner src={item?.image[img]?.image} />
                ) : (
                  <img src={offerBanner} className="pt-2 pb-4" />
                )}
                <OfferDescription>{`Love ${item?.offer_name} - ${item?.description}`}</OfferDescription>
              </div>
            );
          })}
          <OfferTitle>
            <HeadingText className="font-bold">{t("moreOffers")}</HeadingText>
            <div className="flex items-center">
              <div className="cursor-pointer hover:underline flex mx-3">
                <p
                  className="font-semibold"
                  onClick={navigateViewMore}
                >
                  {t("content.view_more")}
                </p>
              </div>
              <PrvBtn className={"swiper-pr hover:bg-stone-400"}>
                <MdChevronLeft size={26} />
              </PrvBtn>
              <NxtBtn className={"swiper-nx hover:bg-stone-400"}>
                <MdChevronRight size={26} />
              </NxtBtn>
            </div>
          </OfferTitle>
          <OfferProduct>
            <Carousel
              slidesPerGroup={1}
              autoplay={false}
              freeMode={true}
              spacebetween1200={14}
              slidesperview1200={6}
              spacebetween1024={14}
              slidesperview1024={4}
              slidesperview768={4}
              spacebetween768={6}
              slidesperview567={3}
              spacebetween567={5}
              slidesperview480={3}
              spacebetween480={5}
              slidesperview280={2}
              spacebetween280={16}
              carouselclass={"w-full"}
              navigation={{
                prevEl: ".swiper-pr",
                nextEl: ".swiper-nx",
              }}
            >
              {allProductListItem?.map((item, index) => {
                return (
                  <SwiperSlide
                    className="mb-1 first:ml-0.5 ml-0 last:mr-1"
                    key={index}
                    style={{ width: "auto" }}
                  >
                    <Card
                      productItem={item}
                      onClick={() => cardClick(item.id, index)}
                      id={index}
                      className="w-auto first:left-0"
                      weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                      productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                      addable={item?.in_stock === 0 ? false : true}
                      recommendtag={item?.recommended === 1 && "Recommended"}
                      trendtag={item?.trending === 1 && "Trending"}
                      newtag={item?.mapl_now === 1 && "New"}
                      outOfStock={item?.in_stock === 0 && "Sale"}
                      recommendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-yellow-500'}
                      newtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-red-500'}
                      trendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-black'}
                      salestagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-red-500'}
                      showValidateModal={isShowValidModal}
                    />
                  </SwiperSlide>
                );
              })}
            </Carousel>
          </OfferProduct>
          <div className="py-4">
            <OfferDescription
              className={"text-start pb-4"}
            >{t('promotions.promotion')}</OfferDescription>
            <p>{t('promotions.promotion1')}</p>
            <p>{t('promotions.promotion2')}</p>
            <p>{t('promotions.promotion3')}</p>
            <p>{t('promotions.promotion4')}</p>
            <p>{t('promotions.promotion5')}</p>
            <p>{t('promotions.promotion6')}</p>
            <p>{t('promotions.promotion7')}</p>
            <p>{t('promotions.promotion8')}</p>
            <p>{t('promotions.promotion9')}</p>
            <p>{t('promotions.promotion10')}</p>
            <p>{t('promotions.promotion11')}</p>
            <p>{t('promotions.promotion12')}</p>
          </div>
          <Footer />
        </HomeScreenContent>
        <ValidationModal
          isOpenModal={isValidateModal}
          close={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
          closeModal={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
        />
      </Container>
    </>
  );
};

const TrendingLink = tw.div`
flex
ml-3
4xl:[210px]
xl:w-[205px]
lg:w-[205px]
sm:w-[27%]
max-h-100
lg:flex
hidden
relative
`;

const Img = tw.img`
w-full
h-full
rounded
object-cover
object-center
`;

const SideMenuContent = tw.div`
xl:w-[15.5%] 
lg:sticky
lg:top-0
sticky
top-0
md:top-0
h-screen
flex flex-col
z-30
`;

const OfferProduct = tw.div`
flex
`;

const OfferTitle = tw.div`
pb-6
w-full
flex
justify-between
items-center
`;

const Banner = tw.img`
max-h-[25pc]
w-full
py-3
`;

const OfferDescription = tw.h1`
text-center
font-bold
text-[20px]
`;

const HomeScreenContent = tw.div`
xl:w-[85.5%]
w-full
h-full 
flex
flex-col
static
px-4
`;

const NxtBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

const PrvBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
  }
`;

export default OfferDetail;