import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "tailwind-styled-components";
import { SwiperSlide } from "swiper/react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Card, Container, HeadingText, Carousel, Skeleton, ValidationModal } from "@components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { scrollToTopWindow } from "@utils";
import { getSalesProduct } from "@store";

const Sales = ({ isLoad }) => {
  const [state, setState] = useState({
    isValidateModal: false
  });
  const { isValidateModal } = state;

  useEffect(() => {
    dispatch(getSalesProduct())
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const salesProduct = useSelector((state) => state.home.salesProduct);
  const token = useSelector((state) => state.user.token);
  const deliveryBanner = useSelector((state) => (state?.home?.homeBanner?.trendingFour));

  const cardClick = (id) => {
    navigate(`/product/${id}`, { state: id });
  };


  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const { t } = useTranslation();

  const navigateViewMore = () => {
    navigate('/view-more?type=sales-products')
    scrollToTopWindow();
  }

  return (
    <Container className="md:flex-col flex-col">
      <div className="flex justify-between items-center">
        <HeadingText>{t('content.sales')}</HeadingText>
        <div className="flex items-center">
          <div className="cursor-pointer hover:underline flex mx-3">
            <p
              className="font-semibold"
              onClick={navigateViewMore}
            >
              {t('content.view_more')}
            </p>
          </div>
          <PrvBtn className={"swiper-prv hover:bg-stone-400"}>
            <MdChevronLeft size={26} />
          </PrvBtn>
          <NxtBtn className={"swiper-nxt hover:bg-stone-400"}>
            <MdChevronRight size={26} />
          </NxtBtn>
        </div>
      </div>
      <SalesProduct>
        <Carousel
          slidesPerGroup={1}
          autoplay={false}
          spacebetween1200={14}
          slidesperview1200={6}
          spacebetween1024={14}
          slidesperview1024={4}
          slidesperview768={4}
          spacebetween768={6}
          slidesperview567={3}
          spacebetween567={5}
          slidesperview480={3}
          spacebetween480={5}
          slidesperview280={2}
          spacebetween280={16}
          carouselclass={"w-full"}
          navigation={{
            prevEl: ".swiper-prv",
            nextEl: ".swiper-nxt",
            disabledClass: ".swiper-dis",
          }}
        >
          {salesProduct?.map((item, index) => {
            return (
              <SwiperSlide
                className="mb-1 first:ml-0.5 ml-0 last:mr-1"
                key={index}
                style={{ width: "auto" }}
              >
                <Card
                  productItem={item}
                  onClick={() => cardClick(item.id, index)}
                  id={index}
                  className="w-auto first:left-0"
                  weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  addable={item?.in_stock === 0 ? false : true}
                  salestag={item?.in_stock === 0 ? t('outOfStock') : t('sale')}
                  salestagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-red-500'}
                  showValidateModal={isShowValidModal}
                />
              </SwiperSlide>
            );
          })}
          <NxtBtn className={"swiper-next"}>
            <MdChevronRight size={22} />
          </NxtBtn>
          <PrvBtn className={"swiper-prev"}>
            <MdChevronLeft size={22} />
          </PrvBtn>
        </Carousel>
        {!deliveryBanner ? (
          <div className="self-center">
            <Skeleton loading={isLoad} />
          </div>
        ) : (
          <SalesLink>
            <Img src={deliveryBanner} alt="" />
          </SalesLink>
        )}
        <ValidationModal
          isOpenModal={isValidateModal}
          close={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
          closeModal={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
        />
      </SalesProduct>
    </Container>
  );
};

const SalesLink = tw.div`
flex
ml-3
4xl:[210px]
xl:w-[205px]
lg:w-[205px]
sm:w-[27%]
max-h-100
lg:flex
sm:max-h-80
hidden
relative
`;

const SalesProduct = tw.div`
flex
my-6
`;

const Img = tw.img`
w-full
max-h-[19.7pc]
object-cover
object-center
rounded
`;

const NxtBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

const PrvBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
  }
`;

export default Sales;