export const formatAddress = (address) => {
  let i = 0
  let newAddress = {}
  let streetAddress = ''
  let oldAddress = null

  for (i = 0; i < address.length; i++) {
    const addressType = address[i]
    if (addressType.types[0] == 'postal_code') {
      newAddress = { ...newAddress, zip_code: addressType.long_name }
    }
    else if (addressType.types[0] == 'country') {
      oldAddress = addressType.long_name
      newAddress = { ...newAddress, country: addressType.long_name }
    }
    else if (addressType.types[0] == 'street_number') {
      oldAddress = addressType.long_name
      newAddress = { ...newAddress, building_num: addressType.long_name }
    }
    else if (addressType.types[0] == 'administrative_area_level_1') {
      newAddress = { ...newAddress, city: addressType.long_name }
    }
    else if (addressType.types[0] == 'landmark') {
      // newAddress = { ...newAddress, city: addressType.long_name }
    }
    else {
      if (oldAddress != addressType.long_name) {
        oldAddress = addressType.long_name
        streetAddress = streetAddress + addressType.long_name + ', '
      }
      newAddress = { ...newAddress, format_add: streetAddress }
    }
  }

  let cityName = `${newAddress.city}`
  let findCityName = new RegExp(cityName, 'g');
  let formatAdd = newAddress.format_add?.replace(findCityName, '')
  let newFormatAddress = formatAdd?.slice(0, formatAdd.length - 2)

  const addressFormat = {
    building_num: newAddress.building_num,
    postcode: newAddress.zip_code,
    address: newFormatAddress,
    city: newAddress.city,
    country: newAddress.country
  }
  return addressFormat

}

export const calcLatLongDelta = (latitude) => {
  const accuracy = 35
  const oneDegreeOfLatitudeInMeters = 111.32 * 50;
  const latitudeDelta = accuracy / oneDegreeOfLatitudeInMeters;
  const longitudeDelta = accuracy / (oneDegreeOfLatitudeInMeters * Math.cos(latitude * (Math.PI / 180)));

  const delta = {
    latitudeDelta,
    longitudeDelta
  }

  return delta
}

export const roundOffAmount = (total) => {
  let newTotal = parseFloat(total * 20)
  newTotal = newTotal.toFixed()
  newTotal = parseFloat((newTotal / 20).toFixed(2))
  return newTotal
}

export const scrollToTopWindow = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export const textFormat = (text) => {
  if (text && typeof text === 'string') {
    return text.slice(0, 1).toUpperCase() + text.slice(1, text.length).toLowerCase();
  }
}