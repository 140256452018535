import * as types from "./Types";
import { profileApi } from "@services";

export const getFaq = (status) => {
  return {
    type: types.GET_FAQ,
    payload: profileApi.getFaq(status),
  };
};

export const getPrivayPolicy = (type) => {
  return {
    type: types.GET_PRIVACY_POLICY,
    payload: profileApi.getPrivayPolicy(type),
  };
};
