import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Card, Container, HeadingText, Carousel, Skeleton, ValidationModal } from "@components";
import { SwiperSlide } from "swiper/react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { scrollToTopWindow } from "@utils";
import { getRecommendedProduct } from "@store"

const Recommendation = ({ isLoad }) => {
  const [state, setState] = useState({
    isValidateModal: false
  });
  const { isValidateModal } = state;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecommendedProduct())
  }, [])

  const recommendationProduct = useSelector((state) => state.home.recommendationProduct);
  const token = useSelector((state) => state.user.token);
  const rightBanner = useSelector((state) => (state.home.homeBanner.right));

  const cardClick = (id) => {
    navigate(`/product/${id}`, { state: id });
  };
  const isShowValidModal = () => {
    if (!token) {
      setState((prev) => ({ ...prev, isValidateModal: true }))
    }
  }

  const { t } = useTranslation();

  const navigateViewMore = () => {
    navigate('/view-more?type=recommendation-products')
    scrollToTopWindow();
  }

  return (
    <Container className="md:flex-col lg:flex-row flex-col">
      <RecommendProduct>
        <RecommendTitle>
          <HeadingText className="font-bold">
            {t('content.our_recommendations')}
          </HeadingText>
          <div className="flex items-center">
            <div className="cursor-pointer hover:underline flex mx-3">
              <p
                className="font-semibold"
                onClick={navigateViewMore}
              >
                {t("content.view_more")}
              </p>
            </div>
            <PrvBtn className={"swiper-prev hover:bg-stone-400"}>
              <MdChevronLeft size={26} />
            </PrvBtn>
            <NxtBtn className={"swiper-next hover:bg-stone-400"}>
              <MdChevronRight size={26} />
            </NxtBtn>
          </div>
        </RecommendTitle>
        <Carousel
          slidesPerGroup={1}
          autoplay={false}
          spacebetween1200={14}
          slidesperview1200={4}
          spacebetween1024={14}
          slidesperview1024={3}
          slidesperview768={3}
          spacebetween768={6}
          slidesperview567={4}
          spacebetween567={5}
          slidesperview480={3}
          spacebetween480={5}
          slidesperview280={2}
          spacebetween280={16}
          carouselclass={"w-full"}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
        >
          {recommendationProduct && recommendationProduct?.map((item, index) => {
            return (
              <SwiperSlide
                className="mb-1 first:ml-0.5 ml-0 last:mr-1"
                key={index}
                style={{ width: "auto" }}
              >
                <Card
                  productItem={item}
                  onClick={() => cardClick(item.id)}
                  id={index}
                  className="w-auto first:left-0"
                  weight={item.weight ? `${item.weight}${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  productRate={item.price ? `${item.price}/${item.weight_unit ? item.weight_unit : "-"}` : "N/A"}
                  addable={item?.in_stock === 0 ? false : true}
                  recommendtag={item?.in_stock === 0 ? t('outOfStock') : t('recommended')}
                  recommendtagColor={item?.in_stock === 0 ? "bg-color165" : 'bg-yellow-500'}
                  showValidateModal={isShowValidModal}
                />
              </SwiperSlide>
            );
          })}
        </Carousel>
        <ValidationModal
          isOpenModal={isValidateModal}
          close={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
          closeModal={() =>
            isValidateModal &&
            setState((prev) => ({ ...prev, isValidateModal: false }))
          }
        />
      </RecommendProduct>
      {!rightBanner ? (
        <div className="w-[50pc] self-center">
          <Skeleton loading={isLoad} />
        </div>
      ) : (
        <RecommendBanner>
          <BannerContent>
            <Img src={rightBanner} alt="Image not found" />
          </BannerContent>
        </RecommendBanner>
      )}
    </Container>
  );
};

const RecommendProduct = tw.div`
2xl:w-7/12
xl:w-7/12 
lg:w-6/12 
md:w-full
w-full
flex-col
relative
`;

const RecommendBanner = tw.button`
flex
lg:w-2/4
sm:w-full
`;

const RecommendTitle = tw.div`
pb-6
w-full
flex
justify-between
items-center
`;

const BannerContent = tw.div`
w-full 
h-full 
relative 
sm:ml-0
md:ml-4
md:my-0
sm:my-4
`;

const Img = tw.img`
w-full 
h-full 
object-cover 
object-center
lg:static 
xl:absolute 
pointer-events-none
`;

const NxtBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

const PrvBtn = styled("button")`
   {
    background: rgba(211, 206, 210, 0.5);
    box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.16);
    z-index: 1;
    outline: none;
    border-radius: 50%;
  }
`;

export default Recommendation;