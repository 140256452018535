import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import { PrimaryText, Button } from "@components";
import theme from "@theme";
import { useSelector, useDispatch } from "react-redux";
import { getOfferPopup } from "@store";
import offerBanner from "@assets/images/offerBanner.jpg"

const OfferModal = () => {
  const [isOfferModal, setIsOfferModal] = useState(false)

  useEffect(() => {
    let timeOutId
    let showOfferModal = !sessionStorage.getItem("isOfferModalShown")
    if (showOfferModal) {
      timeOutId = setTimeout(() => {
        setIsOfferModal(showOfferModal)
      }, 10000);
    }
    dispatch(getOfferPopup())
      .then((res) => {
        if (!res?.payload?.data.length) {
          clearTimeout(timeOutId)
          closeModal()
        }
      })
      .catch((err) => {
        return err;
      });
  }, [])
  
  const hidden = !isOfferModal ? "hidden" : "flex";

  const modalStyle = useSpring({
    scale: isOfferModal ? 1 : 0,
    opacity: isOfferModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOfferModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    isOfferModal && setIsOfferModal(false)
    sessionStorage.setItem("isOfferModalShown", true);
  }

  const offerPopup = useSelector((state) => state.home.offerPopup);

  const CrossButton = () => {
    return (
      <div className="flex justify-end">
        <button onClick={closeModal} className="p-1">
          <XIcon className="w-[15px] m-1" />
        </button>
      </div>
    );
  };

  const gotoOfferDetail = (id) => {
    navigate("/offer-detail", { state: id });
    closeModal()
  };

  return (
    <>
      {offerPopup?.length === 0 ? null : <animated.div
        style={modalBackground}
        className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center bg-white items-center z-[1000]`}
        onClick={closeModal}
      >
        <animated.div style={modalStyle} onClick={(e) => e.stopPropagation()}>
          <Container>
            <CrossButton />
            {offerPopup && offerPopup?.map((item, index) => {
              let img = Math.floor(Math.random() * item?.image?.length);
              return (
                <div className="px-4" key={index}>
                  {item?.image[img]?.image ? (
                    <Banner src={item?.image[img]?.image} />
                  ) : (
                    <img alt="" src={offerBanner} className="pb-4 h-full" />
                  )}
                  <Heading>{`Love ${item?.offer_name} ?`}</Heading>
                  <PrimaryText className="flex justify-center px-2 text-[18px]">
                    {item?.description}
                  </PrimaryText>
                  <div className="grid lg:gap-2 lg:grid-cols-2 pb-4">
                    <Button
                      title={"OK"}
                      className="w-full bg-button border mt-4 rounded-md"
                      titleStyle={{ color: theme.colors.black }}
                      onClick={closeModal}
                    />
                    <Button
                      title={"Details"}
                      className="w-full bg-black mt-4 rounded-md"
                      titleStyle={{ color: theme.colors.white }}
                      onClick={() => gotoOfferDetail(item.id)}
                    />
                  </div>
                </div>
              );
            })}
          </Container>
        </animated.div>
      </animated.div>}
    </>
  );
};

const Container = tw.div`
w-[90%]
h-[50%]
lg:w-[380px]
md:w-[340px]
sm:w-[300px]
bg-white
rounded-lg
`;

const Heading = tw.h1`
whitespace-nowrap
overflow-hidden
overflow-ellipsis
font-semibold
text-[20px]
self-center
text-center
my-6
`;

const Banner = tw.img`
w-full
max-h-80
rounded-sm
`;

export default React.memo(OfferModal);