import React from "react";
import tw from "tailwind-styled-components";
import { animated, useSpring } from "@react-spring/web";
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

const LearnMoreModal = (props) => {
  const { isOpenModal, closeModal, close } = props;

  const hidden = !isOpenModal ? "hidden" : "flex";

  const modalStyle = useSpring({
    scale: isOpenModal ? 1 : 0,
    opacity: isOpenModal ? 1 : 0,
  });

  const modalBackground = useSpring({
    backgroundColor: isOpenModal ? "rgba(0,0,0,0.5)" : "transparent",
  });

  const CrossButton = () => {
    return (
      <div className="flex justify-end">
        <button onClick={closeModal} className="p-1">
          <XIcon className="w-[15px] m-1" />
        </button>
      </div>
    );
  };

  const { t } = useTranslation();

  return (
    <animated.div
      style={modalBackground}
      className={`${hidden} fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center bg-white items-center z-[1000]`}
      onClick={close}
    >
      <animated.div style={modalStyle} onClick={(e) => e.stopPropagation()}>
        <Container>
          <CrossButton />
          <Content>{t('learnMore')}</Content>
        </Container>
      </animated.div>
    </animated.div>
  );
};

const Container = tw.div`
w-[90%]
h-[75%]
lg:h-[240px]
lg:w-[600px]
md:h-[370px]
md:w-[340px]
sm:h-[330px]
sm:w-[300px]
bg-white
mx-auto
overflow-scroll
scrollbar-none
rounded-lg
`;

const Content = tw.p`
text-[14px]
flex
px-4
`;

export default LearnMoreModal;